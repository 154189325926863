const FieldsObject = {
    ElectroTechnical: {
        "uuc_value_1":"number",
        "std_value_1":"number",
        "least_count":"number",
        "least_count":"number",
        "temp1":"number",
        "temp3":"number",
        "rh1":"number",
        "rh3":"number",
        "least_count_unit":"select",
        "instu_used":"select",
        "std_value_unit":"select",
        "uuc_value_unit":"select",
        
    }
}

export function CheckRequiredField(fieldName, material_type){
    const reqFieldsArray = FieldsObject[material_type]
    if(!reqFieldsArray[fieldName]){
        return {'is_required':false, "type": null}
    }
    else{
        return {'is_required':true, "type":reqFieldsArray[fieldName]}
    }
}

export function checkHaveMaster(data){
    const obsData = data['observation_data']
    for (const[index, value] of Object.entries(obsData)){
        if(!value['master_tbl'] ||!value['master_tbl']['instrument_master'] || !value['master_tbl']['instrument_master'][0]){
            return {"index":index, "status":false}
        }
    }
    return {"status":true}
}
