import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import { useUucData } from '../Utils/UucDataContext';

function Header() {
  const { uucData, setUucData } = useUucData();
  const token = localStorage.getItem("accessToke")
  const refresh = localStorage.getItem("refresh")
  const homeUrl = `/?id=${uucData.srfId}&userId=${uucData.userId}&hash_schema=${uucData.hash_schema}&login=${token}&refresh=${refresh}`;
  const crudFormEditUrl = `edit-crud-json/?id=${uucData.id}&userId=${uucData.userId}&hash_schema=${uucData.hash_schema}&login=${token}&refresh${refresh}`;
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.clear();
    window.location.href = homeUrl;
  };

  return (
    <Navbar expand="lg" className="sticky-top myBoxes" style={{ zIndex: '9999' }}>
      <Container style={{ textAlign: 'center' }}>
        <Navbar.Brand href="#home">Calibra AI</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to={homeUrl} className="nav-link" activeClassName="active">DUC Details</Nav.Link>
            <Nav.Link as={NavLink} to="/calibration-procedure" className="nav-link" activeClassName="active">Calibration Procedure</Nav.Link>
            <Nav.Link as={NavLink} to="/calibration-readings" className="nav-link" activeClassName="active">Readings</Nav.Link>
            <Nav.Link as={NavLink} to="/calibration-sheet" className="nav-link" activeClassName="active">Observation Sheet</Nav.Link>
            <Nav.Link as={NavLink} to="/calibration-certifications" className="nav-link" activeClassName="active">Certification</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Nav.Link as={NavLink} to={crudFormEditUrl} className="nav-link" activeClassName="active">Edit Forms</Nav.Link>
        <button
          type="button"
          onClick={clearCacheData}
          style={{ marginLeft: "10px" }}
          className="btn btn-sm btn-dark"
        >
          Clear Cache
        </button>
      </Container>
    </Navbar>
  );
}

export default Header;
