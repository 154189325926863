
export const findCertificate = (data, givenId) => {  //Data is the masters data
    let ourDict = null;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === givenId) {
        ourDict = data[i];
        break;
      }
    }
    
    if (ourDict && ourDict.last_child_grandchildren) {
      return ourDict.last_child_grandchildren.grandchildren;
    }

    return null; // Return null or any other fallback value if not found
  };


export const initialCertificatesOptionsDict = (data) =>{ //This function will get all certificateItems
  const allCertificates = []
  for (let i=0; i<data.length; i++){
    const ourDict = data[i]
    if (ourDict && ourDict.last_child_grandchildren){
      for(let j=0; j<ourDict.last_child_grandchildren.grandchildren.length; j++){
        allCertificates.push(ourDict.last_child_grandchildren.grandchildren[j])

      }
    }
  }
  return allCertificates
  
}