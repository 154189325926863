export const url= "http://adcon.localhost:8000/api"
export const domain = "calibra.live/api"

export function ConstructURL(schema){
    return `https://${schema}.calibra.live/api`
    // return `http://${schema}.localhost:8000/api`
}
export function StartObservationUrl(schema, parentSRF){
    return `https://${schema}.calibra.live/srfsitemforobservation/${parentSRF}`
    // return `http://${schema}.localhost:8000/srfsitemforobservation/${parentSRF}`
}
export function Domain(schema){
    return `https://${schema}.calibra.live`
    // return `http://${schema}.localhost:8000`
}
