
import { url, StartObservationUrl } from "../Url/BaseUrl";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useUucData } from "../Utils/UucDataContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ObservationSheet() {
    const navigate = useNavigate();
    const token = localStorage.getItem("accessToke")
    const refresh = localStorage.getItem("refresh")
    const { uucData } = useUucData();
    const [obsUrl, setObsUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [errorDetails, setErrorDetails] = useState(null);
    const [errorHtml, setErrorHtml] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [loadingCertficate, setloadingCertficate] = useState(false);
    const [formData, setFormData] = useState({
        leastcount: '',
        material_type: '',
        condition_of_uuc: '',
        uuc_location: '',
        calibrated_on: '',
        valid_upto: '',
        certificate_no: '',
        created_on: '',
        procedure_no: '',
        ref_std: '',
        temperature: '',
        operating_range: '',
        humidity: '',
        weight_density: '',
        accuracy: '',
        total_pages_on_obs: '',
        error_digits: '',
        uuc_received: '',
        received_on: '',
        cert_issue_date: '',
        range: '',
        make_model: '',
        serial_no: '',
        party_id: '',
        location: '',
        bar_code: '',
        uncertaintiy_digits: '',
        after_conversion_digits: '',
        calibration_engineer_sign: '',
        calibration_technical_manager: '',
        approved_by: '',
        uuc_image: null,
        all_users: '',
        certObj: null
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleViewCertificate = async (e) => {
        e.preventDefault();
        setloadingCertficate(true)
        try {
            const response = await axios.get(`${url}/ViewCertificate/${id}`, {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const url_blob = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            setObsUrl(url_blob);
        } catch (error) {
            toast.error('Something went wrong !');
        } finally {
            setloadingCertficate(false)

        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitType = e.nativeEvent.submitter.name;
        setSubmitting(true); // Set submitting to true when form is submitted
        const form = new FormData();
        for (const [key, value] of Object.entries(formData)) {
            form.append(key, value);
        }
        form.append('submitType', submitType);
        try {
            const response = await axios.post(`${url}/GenerateObservation/${id}`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                }
            });

            toast.success('Success!');
            navigate("/calibration-certifications");
        } catch (error) {
            const response = error.response
            if (response.status === 308) {
                toast.info(response.data)
                setTimeout(() => {
                    window.location.href = StartObservationUrl(uucData.schema, uucData.parentSRF)
                }, 3000);
                // toast.info("You don't have permissions for certification")

            }
            else {
                toast.error('Something went wrong!');
            }
        } finally {
            setSubmitting(false);

        }
    };

    const id = uucData.obsId;
    const url = uucData.url;

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await axios.post(`${url}/draft_api/${id}`, {
                    responseType: 'blob',
                    data: uucData
                },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }

                    });
                const url_blob = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                setObsUrl(url_blob);
                setError(null); // Clear any previous errors
                setErrorDetails(null); // Clear any previous error details
                setErrorHtml(null); // Clear any previous error HTML
            } catch (error) {
                setError('Error fetching PDF from draft_api');

                if (error.response) {
                    const details = {
                        status: error.response.status,
                        statusText: error.response.statusText,
                        headers: error.response.headers,
                        data: error.response.data
                    };
                    setErrorDetails(details);

                    if (typeof details.data === 'string' && details.data.startsWith('<')) {
                        setErrorHtml(details.data); // Assume it's HTML if it starts with a '<'
                    }
                } else {
                    console.error('Error fetching PDF:', error);
                    setErrorDetails('An unknown error occurred.');
                }
            }
            try {
                const response = await axios.get(`${url}/GenerateObservation/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }

                });
                setFormData(response.data)
            } catch (error) {
                console.log(error)
            }
            setLoading(false);
        };

        fetchPdf();
    }, [id, uucData]);
    const usersOptions = formData.all_users
        ? formData.all_users.map((user) => ({
            value: user.id,
            label: `${user.name_on_print_docs} `,
        }))
        : [];

    return (
        <div className="col-sm-12 row ">
            <div className="pdf-container col-sm-6 card">
                {loading || loadingCertficate ? (
                    <div className="spinner-container">
                        <div className="spinner-border"></div>
                        <div> &nbsp; loading sheet..</div>

                    </div>
                ) : error ? (
                    <div className=" col-sm-12" style={{'maxHeight':'100vh'}}>
                        {errorDetails && (
                            <div style={{ whiteSpace: 'pre-wrap', backgroundColor: '#f0f0f0', padding: '10px', marginTop: '10px' }}>
                                <h4>Error Details:</h4>
                                <p><strong>Status:</strong> {errorDetails.status} {errorDetails.statusText}</p>
                                <p><strong>Headers:</strong> {JSON.stringify(errorDetails.headers, null, 2)}</p>
                            </div>
                        )}
                        {errorHtml && (
                            <div style={{ border: '1px solid #ccc', marginTop: '10px', padding: '10px', maxHeight: "720px" }}>
                                <h4>Error Preview:</h4>
                                <div dangerouslySetInnerHTML={{ __html: errorHtml }} />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="col-sm-12">
                        {obsUrl ? (
                            <div className="col-sm-12">
                                <iframe src={obsUrl} width="100%" height="620px" title="Observation PDF Preview" />
                            </div>

                        ) : (
                            <p>No Observation PDF available</p>
                        )}
                    </div>
                )}


            </div>
            <div className="col-sm-6 card">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border"></div>
                        <div> &nbsp; loading form..</div>

                    </div>
                ) : (
                    <div className="col-sm-12">
                        <h3 className="mt-3 ml-2"><strong>Generate Observation</strong></h3>
                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="row col-sm-12">
                                <div className="form-group col-sm-6">
                                    <label htmlFor="leastcount">Least Count</label>
                                    <input type="text" className="form-control" id="leastcount" onChange={handleChange} value={formData?.leastcount} name="leastcount" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="procedure_no">Procedure No</label>
                                    <input type="text" className="form-control" id="procedure_no" onChange={handleChange} value={formData?.procedure_no} name="procedure_no" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="ref_std">Ref Std.</label>
                                    <input type="text" className="form-control" id="ref_std" onChange={handleChange} value={formData?.ref_std} name="ref_std" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="temperature">Temperature</label>
                                    <input type="text" className="form-control" id="temperature" onChange={handleChange} value={formData?.temperature} name="temperature" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="humidity">Humidity</label>
                                    <input type="text" className="form-control" id="humidity" onChange={handleChange} value={formData?.humidity} name="humidity" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="range">Range</label>
                                    <input type="text" className="form-control" id="range" onChange={handleChange} value={formData?.range} name="range" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="operating_range">Operating Range</label>
                                    <input type="text" className="form-control" id="operating_range" onChange={handleChange} value={formData?.operating_range} name="operating_range" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="serial_no">Serial No</label>
                                    <input type="text" className="form-control" id="serial_no" onChange={handleChange} value={formData?.serial_no} name="serial_no" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="calibrated_on">Calibrated on</label>
                                    <input type="date" className="form-control" id="calibrated_on" onChange={handleChange} value={formData?.calibrated_on} name="calibrated_on" placeholder="Calibrated on" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="valid_upto">Valid up to</label>
                                    <input type="date" required className="form-control" id="valid_upto" onChange={handleChange} value={formData?.valid_upto} name="valid_upto" placeholder="Valid up to" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="cert_issue_date">Certificate issue Date</label>
                                    <input type="date" required className="form-control" id="cert_issue_date" onChange={handleChange} value={formData?.cert_issue_date} name="cert_issue_date" placeholder="Certificate Issue Date" />
                                </div>
                                <div className="form-group col-sm-6">
                                    <label htmlFor="calibration_engineer_sign">Calibration Engineer Sign</label>
                                    <select required className="form-control" name="calibration_engineer_sign" id="calibration_engineer_sign" onChange={handleChange} value={formData?.calibration_engineer_sign}>
                                        <option value="">--select--</option>
                                        {usersOptions.map((option, i) => (
                                            <option key={i} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    {formData?.calibration_engineer_sign ? ("") : (
                                        <span style={{ "color": "red" }}>*Required value</span>
                                    )}
                                </div>
                                <div className="col-12 mt-3 text-right">

                                    {submitting ? (
                                        <button type="button" className="btn btn-success" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Submitting...
                                        </button>
                                    ) : (
                                        formData.calibration_engineer_sign && formData.valid_upto && formData.cert_issue_date ? (
                                            <div className="col-sm-12 text-right">
                                                {formData.certObj === null || !formData.certObj || formData.certObj === '' ? (
                                                    <button type="submit" name="saveBtn" className="btn btn-outline-dark me-2">
                                                        Generate Certificate
                                                    </button>
                                                ) : (<button type="button" onClick={handleViewCertificate} disabled={loadingCertficate ? (true) : false} className="btn btn-outline-dark me-2">
                                                    {loadingCertficate ? ("Loading Certificate") : ("View Certificate")}
                                                </button>)}

                                                <button type="submit" data-toggle="tooltip" data-placement="top" title="Submit To Technical Manager" name="submitBtn" className="btn btn-dark">
                                                    Submit
                                                </button>
                                            </div>

                                        ) : (
                                            <button type="button" className="btn btn-dark" disabled>Please Fill Required Vlaue</button>
                                        )
                                    )}
                                </div>

                            </form>
                        </div>
                    </div>

                )}
            </div>
            <ToastContainer />
        </div>
    );
}

export default ObservationSheet;
