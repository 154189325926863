import React, { createContext, useState, useEffect, useContext } from 'react';

// Create Context
const UucDataContext = createContext();

// Create Provider component
export const UucDataProvider = ({ children }) => {
    const [uucData, setUucData] = useState(() => {
        const savedUucData = localStorage.getItem('uucData');
        return savedUucData ? JSON.parse(savedUucData) : {};
    });

    useEffect(() => {
        // Save uucData to localStorage whenever it changes
        localStorage.setItem('uucData', JSON.stringify(uucData));
    }, [uucData]);

    return (
        <UucDataContext.Provider value={{ uucData, setUucData }}>
            {children}
        </UucDataContext.Provider>
    );
};

// Custom hook to use the UucDataContext
export const useUucData = () => {
    return useContext(UucDataContext);
};
