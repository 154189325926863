import { useState, useEffect } from 'react';
import Select from 'react-select';
import { useUucData } from '../Utils/UucDataContext';
import { useNavigate, useParams } from 'react-router-dom';

function CalibrationProcedure() {
    const navigate = useNavigate();
    const { id, userId } = useParams();
    const { uucData, setUucData } = useUucData();
    const [formData, setFormData] = useState({
        masters_used: [],
        procedures_used: []
    });
    const cacheKey = `uucData_${uucData.srfId}_${uucData.userId}_${uucData.schema}`;
    useEffect(() => {
        const cachedData = localStorage.getItem(cacheKey);

        if (cachedData) {
            const data = JSON.parse(cachedData);
            setUucData(data);
        }
    }, [id, userId, setUucData]);

    useEffect(() => {
        if (uucData.masters_used) {
            setFormData(prevFormData => ({
                ...prevFormData,
                masters_used: uucData.masters_used.map(master => ({
                    value: master.id,
                    label: master.display_name
                }))
            }));
        }

        if (uucData.procedures_used) {
            setFormData(prevFormData => ({
                ...prevFormData,
                procedures_used: uucData.procedures_used.map(procedure => ({
                    value: procedure.id,
                    label: `${procedure.procedure_no} - ${procedure.referance_guide}`
                }))
            }));
        }
    }, [uucData]);

    const handleChange = (selectedOptions, { name }) => {
        const updatedFormData = {
            ...formData,
            [name]: selectedOptions || []
        };
        setFormData(updatedFormData);

        // Update cache
        const updatedUucData = {
            ...uucData,
            masters_used: name === 'masters_used' ? updatedFormData.masters_used.map(option => ({
                id: option.value,
                display_name: option.label
            })) : uucData.masters_used,
            procedures_used: name === 'procedures_used' ? updatedFormData.procedures_used.map(option => ({
                id: option.value,
                procedure_no: option.label.split(' - ')[0],
                referance_guide: option.label.split(' - ')[1]
            })) : uucData.procedures_used
        };
        setUucData(updatedUucData);
        localStorage.setItem(cacheKey, JSON.stringify(updatedUucData));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            // Update the uucData with selected masters and procedures
            const updatedUucData = {
                ...uucData,
                masters_used: formData.masters_used,
                procedures_used: formData.procedures_used
            };
            setUucData(updatedUucData);  // Update the context with new data
            // localStorage.setItem(cacheKey, JSON.stringify(updatedUucData));
            navigate('/calibration-readings');
        } catch (error) {
            console.error('Error saving form data:', error);
        }
    };

    const masterOptions = uucData.master_instruments ? uucData.master_instruments.map((master) => ({
        value: master.id,
        label: master.display_name
    })) : [];

    const procedureOptions = uucData.procedures_attached ? uucData.procedures_attached.map((procedure) => ({
        value: procedure.id,
        label: `${procedure.procedure_no} - ${procedure.referance_guide}`
    })) : [];

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="card">
                    <div className="card-header">
                        <div>
                            <h4>Calibration Procedure</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-sm-12 row">
                            <div className="col-sm-4" style={{ "display": "none" }}>
                                <label style={{ "display": "none" }} htmlFor="masters_used">Select Masters</label>
                                <Select
                                    style={{ "display": "none" }}
                                    name="masters_used"
                                    isMulti
                                    options={masterOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(selectedOptions) => handleChange(selectedOptions, { name: 'masters_used' })}
                                    value={masterOptions.filter(option => formData.masters_used.map(opt => opt.value).includes(option.value))}
                                />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="procedures_used">Select Procedures</label>
                                <Select
                                    name="procedures_used"
                                    isMulti
                                    options={procedureOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(selectedOptions) => handleChange(selectedOptions, { name: 'procedures_used' })}
                                    value={procedureOptions.filter(option => formData.procedures_used.map(opt => opt.value).includes(option.value))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 text-right mt-3">
                    <button type="submit" className="btn btn-success btn-lg">Next</button>
                </div>
            </form>
        </div>
    );
}

export default CalibrationProcedure;
