import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { schemaDecodeKey } from "../Common/hashingKeys";
import { ConstructURL, url } from "../Url/BaseUrl";
import CryptoJS from "crypto-js";
import { Spinner, SubmittingOverlay } from "../Common/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OtherDimensionFields, getDefaultForm } from '../Common/defaultForm';

function FieldVisibilityForm() {
    const token = localStorage.getItem("accessToke")
    const refresh = localStorage.getItem("refresh")
    const [haveResponse, setHaveResponse] = useState(false);
    const [schema, setSchema] = useState("");
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const uucid = queryParams.get('id');
    const userId = queryParams.get('userId');
    const hash_schema = queryParams.get('hash_schema');
    const [url, setUrl] = useState("");
    const [jsonStructure, setJsonStructure] = useState({
        material_type: '',
        formFields: [],
        ReadingStruct: {
            OtherValues: [],
            TableStructure: {
                headings: [],
                rows: [],
            },
        },
    });
    const [materialType, setMaterialType] = useState('Thermal');

    function decodeValue(encryptedText) {
        const key = schemaDecodeKey
        const paddedBase64 = encryptedText.replace(/0+$/, '');
        const base64Decoded = atob(paddedBase64);
        let decryptedText = '';
        for (let i = 0; i < base64Decoded.length; i++) {
            const keyC = key.charCodeAt(i % key.length);
            const decryptedC = String.fromCharCode(base64Decoded.charCodeAt(i) ^ keyC);
            decryptedText += decryptedC;
        }
        return decryptedText;
    }

    useEffect(() => {
        const encryptedValue = hash_schema;
        if (encryptedValue) {
            const decodedSchema = decodeValue(encryptedValue);
            setSchema(decodedSchema);
            setUrl(ConstructURL(decodedSchema));
        }
    }, [hash_schema]);

    useEffect(() => {
        const fetchCrudFields = async () => {
            if (uucid && url) {
                try {
                    const response = await axios.get(`${url}/UucCrudFields/${uucid}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            }

                        }
                    );
                    if (response.status === 200) {
                        const data = response.data;
                        try {
                            const getResponse = await axios.get(`${url}/save_form_structure?material_type=${data.material_type}`,
                                {
                                    headers: {
                                        'Authorization': `Bearer ${token}`,
                                        'Content-Type': 'application/json'
                                    }

                                }
                            );
                            const defaultFormData = getResponse.data
                            setMaterialType(response.data.material_type);
                            defaultFormData.formFields = defaultFormData.formFields.map(field => ({ ...field, is_visible: field.is_visible ?? true }));
                            defaultFormData.formFields = defaultFormData.formFields.map(field => ({ ...field, is_required: field?.is_required ? (true) : (false) }));
                            defaultFormData.ReadingStruct.OtherValues = defaultFormData.ReadingStruct.OtherValues.map(field => ({ ...field, is_visible: field.is_visible ?? true }));
                            defaultFormData.ReadingStruct.OtherValues = defaultFormData.ReadingStruct.OtherValues.map(field => ({ ...field, is_required: field?.is_required ? (true) : (false) }));
                            setJsonStructure(defaultFormData);
                            setHaveResponse(true);
                        }
                        catch (error) {
                            const defaultFormData = getDefaultForm(data.material_type).jsonStruct
                            setMaterialType(response.data.material_type);
                            defaultFormData.formFields = defaultFormData?.formFields?.map(field => ({ ...field, is_visible: field.is_visible ?? true }));
                            defaultFormData.formFields = defaultFormData?.formFields?.map(field => ({ ...field, is_required: field?.is_required ? (true) : (false) }));
                            defaultFormData.ReadingStruct.OtherValues = defaultFormData.ReadingStruct.OtherValues.map(field => ({ ...field, is_visible: field.is_visible ?? true }));
                            defaultFormData.ReadingStruct.OtherValues = defaultFormData.ReadingStruct.OtherValues.map(field => ({ ...field, is_required: field?.is_required ? (true) : (false) }));
                            setJsonStructure(defaultFormData);
                            setHaveResponse(true);
                        }

                    }
                } catch (error) {
                    toast.error(`Error fetching CRUD fields, ${error}`);
                    console.log('Error fetching CRUD fields', error);
                }
            }
        };
        fetchCrudFields();
    }, [uucid, url]);

    const handleToggleVisibility = (name, fieldType, fieldIndex) => {
        const updatedFields = [...jsonStructure[fieldType]];
        updatedFields[fieldIndex].is_visible = !updatedFields[fieldIndex].is_visible;
        setJsonStructure({ ...jsonStructure, [fieldType]: updatedFields });
    };

    const handleFieldRename = (e, fieldIndex, fieldType) => {
        const { value } = e.target;
        const updatedFields = [...jsonStructure[fieldType]];
        const oldName = updatedFields[fieldIndex].name;
        updatedFields[fieldIndex].name = value;
        setJsonStructure({ ...jsonStructure, [fieldType]: updatedFields });
        updateJsonKeys(fieldType, oldName, value);
    };

    const updateJsonKeys = (fieldType, oldName, newName) => {
        const updatedStructure = { ...jsonStructure };

        if (fieldType === 'formFields') {
            updatedStructure.formFields = updatedStructure.formFields.map(field => {
                if (field.name === oldName) {
                    field.name = newName;
                }
                return field;
            });
        }

        if (fieldType === 'ReadingStruct.OtherValues') {
            updatedStructure.ReadingStruct.OtherValues = updatedStructure.ReadingStruct.OtherValues.map(field => {
                if (field.name === oldName) {
                    field.name = newName;
                }
                return field;
            });
        }

        setJsonStructure(updatedStructure);
    };

    const handleCheckAll = () => {
        setJsonStructure({
            ...jsonStructure,
            formFields: jsonStructure?.formFields?.map(field => ({ ...field, is_visible: true })),
            ReadingStruct: {
                ...jsonStructure.ReadingStruct,
                OtherValues: jsonStructure.ReadingStruct.OtherValues.map(field => ({ ...field, is_visible: true })),
                TableStructure: {
                    ...jsonStructure.ReadingStruct.TableStructure,
                    rows: jsonStructure.ReadingStruct.TableStructure.rows.map(row => row.map(field => ({ ...field, is_visible: true })))
                }
            }
        });
    };

    const handleSubmit = async (event) => {
        setHaveResponse(false);
        event.preventDefault();
        let allFields = [...jsonStructure.formFields];
        jsonStructure.ReadingStruct.OtherValues.forEach(field => allFields.push(field));
        jsonStructure.ReadingStruct.TableStructure.rows.forEach(row => row.forEach(field => allFields.push(field)));
        const visibleFields = allFields.filter(field => !field.is_visible).map(field => field.name);
        try {
            await axios.post(`${url}/UucCrudFields/${uucid}`, { crud_data: visibleFields },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }

                }
            );
            toast.success(`Success!`);
        } catch (error) {
            toast.error(`Error while submitting data, ${error}`);
            console.log('Error', error);
        } finally {
            setHaveResponse(true);
        }
        try {
            await axios.post(`${url}/save_form_structure`, { "jsonStructure": jsonStructure, material_type: materialType },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }

                }
            );
            // toast.success('JSON saved successfully!');
        } catch (error) {
            console.error(error);
            // toast.error(`${error}`);
        }
    };

    const handleInputChange = (e, fieldIndex, fieldType) => {
        const { name, value } = e.target;
        if (name === 'material_type') {
            setMaterialType(value);
        } else {
            const updatedFields = [...jsonStructure[fieldType]];
            updatedFields[fieldIndex] = { ...updatedFields[fieldIndex], [name]: value };
            setJsonStructure({ ...jsonStructure, [fieldType]: updatedFields });
        }
    };

    const renderFormFields = (formFields) => {
        return formFields.map((field, index) => (
            <div key={index} className="form-row mb-3">
                <div className="form-group col-md-2">
                    <label>Label</label>
                    <input
                        type="text"
                        className="form-control"
                        name="label"
                        value={field.label}
                        onChange={(e) => handleInputChange(e, index, 'formFields')}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label>Type</label>
                    <select
                        disabled
                        className="form-control"
                        name="type"
                        value={field.type}
                        onChange={(e) => handleInputChange(e, index, 'formFields')}
                    >
                        <option value="text">Text</option>
                        <option value="select">Select</option>
                        {/* Add more types as needed */}
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <label>Name</label>

                    <input
                        disabled
                        type="text"
                        className="form-control"
                        value={field.name}
                        onChange={(e) => handleFieldRename(e, index, 'formFields')}
                    />
                </div>
                <div className="form-check form-switch col-md-2">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={field.is_visible}
                        onChange={() => {
                            const updatedRows = [...jsonStructure.formFields];
                            if (!updatedRows[index]) {
                                updatedRows[index] = {};
                            }
                            updatedRows[index].is_visible = !updatedRows[index].is_visible;
                            setJsonStructure({
                                ...jsonStructure,
                                formFields: updatedRows

                            });
                        }}
                    />
                    <label className='form-check-label'>Visible</label>
                </div>
                <div className="form-check form-switch col-md-2">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={jsonStructure.formFields[index]?.is_required}
                        onChange={() => {
                            const updatedRows = [...jsonStructure.formFields];
                            if (!updatedRows[index]) {
                                updatedRows[index] = {};
                            }
                            updatedRows[index].is_required = !updatedRows[index].is_required;
                            setJsonStructure({
                                ...jsonStructure,
                                formFields: updatedRows

                            });
                        }}
                    />
                    <label className='form-check-label'>Required</label>
                </div>
                <div className="form-group col-md-4">
                    {field.type === 'select' && (
                        <div>
                            <label>Options (comma-separated)</label>
                            <input
                                type="text"
                                disabled
                                className="form-control"
                                name="options"
                                value={field.options.join(',')}
                                onChange={(e) => {
                                    const options = e.target.value.split(',');
                                    const updatedFields = [...jsonStructure.formFields];
                                    updatedFields[index].options = options;
                                    setJsonStructure({ ...jsonStructure, formFields: updatedFields });
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        ));
    };
    const renderDimensionOtherFields = (formFields) => {
        return formFields.map((field, index) => (
            <div key={index} className="form-row mb-3">
                <div className="form-group col-md-2">
                    <label>Label</label>
                    <input
                        type="text"
                        className="form-control"
                        name="label"
                        value={field.label}
                        onChange={(e) => handleInputChange(e, index, 'formFields')}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label>Type</label>
                    <select
                        disabled
                        className="form-control"
                        name="type"
                        value={field.type}
                        onChange={(e) => handleInputChange(e, index, 'formFields')}
                    >
                        <option value="text">Text</option>
                        <option value="select">Select</option>
                        {/* Add more types as needed */}
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <label>Name</label>

                    <input
                        disabled
                        type="text"
                        className="form-control"
                        value={field.name}
                        onChange={(e) => handleFieldRename(e, index, 'formFields')}
                    />
                </div>
                <div className="form-check form-switch col-md-2">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={field.is_visible}
                        onChange={() => handleToggleVisibility(field.name, 'formFields', index)}
                    />
                    <label className='form-check-label'>Visible</label>
                </div>
                <div className="form-group col-md-4">
                    {field.type === 'select' && (
                        <div>
                            <label>Options (comma-separated)</label>
                            <input
                                type="text"
                                disabled
                                className="form-control"
                                name="options"
                                value={field.options.join(',')}
                                onChange={(e) => {
                                    const options = e.target.value.split(',');
                                    const updatedFields = [...jsonStructure.formFields];
                                    updatedFields[index].options = options;
                                    setJsonStructure({ ...jsonStructure, formFields: updatedFields });
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        ));
    };


    const renderOtherValues = (otherValues) => {
        return otherValues.map((field, index) => (
            <div key={index} className="form-row mb-3">
                <div className="form-group col-md-2">
                    <label>Label</label>
                    <input
                        type="text"
                        className="form-control"
                        name="label"
                        value={field.label}
                        onChange={(e) => {
                            const updatedFields = [...jsonStructure.ReadingStruct.OtherValues];
                            updatedFields[index].label = e.target.value;
                            setJsonStructure({
                                ...jsonStructure,
                                ReadingStruct: { ...jsonStructure.ReadingStruct, OtherValues: updatedFields },
                            });
                        }}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label>Type</label>
                    <select
                        className="form-control"
                        disabled
                        name="type"
                        value={field.type}
                        onChange={(e) => handleInputChange(e, index, 'ReadingStruct.OtherValues')}
                    >
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                        <option value="select">Select</option>
                        {/* Add more types as needed */}
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <label>Name</label>
                    <input
                        disabled
                        type="text"
                        className="form-control"
                        value={field.name}
                        onChange={(e) => handleFieldRename(e, index, 'ReadingStruct.OtherValues')}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label>Value</label>
                    <input
                        type="text"
                        className="form-control"
                        name="value"
                        value={field.value}
                        onChange={(e) => {
                            const updatedFields = [...jsonStructure.ReadingStruct.OtherValues];
                            updatedFields[index].value = e.target.value;
                            setJsonStructure({
                                ...jsonStructure,
                                ReadingStruct: { ...jsonStructure.ReadingStruct, OtherValues: updatedFields },
                            });
                        }}
                    />
                </div>
                <div className="form-check form-switch col-md-2">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={field.is_visible}
                        onChange={() => {
                            const updatedRows = [...jsonStructure.ReadingStruct.OtherValues];
                            if (!updatedRows[index]) {
                                updatedRows[index] = {};
                            }
                            updatedRows[index].is_visible = !updatedRows[index].is_visible;
                            setJsonStructure({
                                ...jsonStructure,
                                ReadingStruct: {
                                    ...jsonStructure.ReadingStruct,
                                    OtherValues: updatedRows
                                }

                            });
                        }}
                    />
                    <label className='form-check-label'>Visible</label>
                </div>
                <div className="form-check form-switch col-md-2">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={jsonStructure.ReadingStruct.OtherValues[index]?.is_required}
                        onChange={() => {
                            const updatedRows = [...jsonStructure.ReadingStruct.OtherValues];
                            if (!updatedRows[index]) {
                                updatedRows[index] = {};
                            }
                            updatedRows[index].is_required = !updatedRows[index].is_required;
                            setJsonStructure({
                                ...jsonStructure,
                                ReadingStruct: {
                                    ...jsonStructure.ReadingStruct,
                                    OtherValues: updatedRows
                                }

                            });
                        }}
                    />
                    <label className='form-check-label'>Required</label>
                </div>
                <div className="form-group col-md-4">
                    {field.type === 'select' && (
                        <div>
                            <label>Options (comma-separated)</label>
                            <input
                                disabled
                                type="text"
                                className="form-control"
                                name="options"
                                value={field.options ? field.options.join(',') : ''}
                                onChange={(e) => {
                                    const options = e.target.value.split(',');
                                    const updatedFields = [...jsonStructure.ReadingStruct.OtherValues];
                                    updatedFields[index].options = options;
                                    setJsonStructure({
                                        ...jsonStructure,
                                        ReadingStruct: { ...jsonStructure.ReadingStruct, OtherValues: updatedFields },
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        ));
    };

    const renderReadingFields = (readingFields) => {
        return readingFields.map((array_of_fields, rowIndex) => (
            array_of_fields.map((field, index) => (
                <div key={`${rowIndex}-${index}`} className="form-row mb-3">
                    <div className="form-group col-md-2">
                        <label>Label</label>
                        <input
                            type="text"
                            className="form-control"
                            name="label"
                            value={field.label}
                            onChange={(e) => {
                                const updatedRows = [...jsonStructure.ReadingStruct.TableStructure.rows];
                                updatedRows[rowIndex][index].label = e.target.value;
                                setJsonStructure({
                                    ...jsonStructure,
                                    ReadingStruct: {
                                        ...jsonStructure.ReadingStruct,
                                        TableStructure: { ...jsonStructure.ReadingStruct.TableStructure, rows: updatedRows }
                                    }
                                });
                            }}
                        />
                    </div>
                    <div className="form-group col-md-2">
                        <label>Type</label>
                        <select
                            disabled
                            className="form-control"
                            name="type"
                            value={field.type}
                            onChange={(e) => {
                                const updatedRows = [...jsonStructure.ReadingStruct.TableStructure.rows];
                                updatedRows[rowIndex][index].type = e.target.value;
                                setJsonStructure({
                                    ...jsonStructure,
                                    ReadingStruct: {
                                        ...jsonStructure.ReadingStruct,
                                        TableStructure: { ...jsonStructure.ReadingStruct.TableStructure, rows: updatedRows }
                                    }
                                });
                            }}
                        >
                            <option value="text">Text</option>
                            <option value="number">Number</option>
                            <option value="select">Select</option>
                            {/* Add more types as needed */}
                        </select>
                    </div>
                    <div className="form-group col-md-2">
                        <label>Name</label>
                        <input
                            type="text"
                            disabled
                            className="form-control"
                            value={field.name}
                            onChange={(e) => {
                                const updatedRows = [...jsonStructure.ReadingStruct.TableStructure.rows];
                                updatedRows[rowIndex][index].name = e.target.value;
                                setJsonStructure({
                                    ...jsonStructure,
                                    ReadingStruct: {
                                        ...jsonStructure.ReadingStruct,
                                        TableStructure: { ...jsonStructure.ReadingStruct.TableStructure, rows: updatedRows }
                                    }
                                });
                            }}
                        />
                    </div>
                    <div className="form-check form-switch col-md-2">
                        <input

                            type="checkbox"
                            className="form-check-input"
                            checked={field.is_visible}
                            onChange={() => {
                                const updatedRows = [...jsonStructure.ReadingStruct.TableStructure.rows];
                                updatedRows[rowIndex][index].is_visible = !updatedRows[rowIndex][index].is_visible;
                                setJsonStructure({
                                    ...jsonStructure,
                                    ReadingStruct: {
                                        ...jsonStructure.ReadingStruct,
                                        TableStructure: { ...jsonStructure.ReadingStruct.TableStructure, rows: updatedRows }
                                    }
                                });
                            }}
                        />
                        <label className='form-check-label'>Visible</label>
                    </div>
                    <div className="form-check form-switch col-md-2">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={field?.is_required}
                            onChange={() => {
                                const updatedRows = [...jsonStructure.ReadingStruct.TableStructure.rows];
                                if (!updatedRows[rowIndex][index]["is_required"]) {
                                    updatedRows[rowIndex][index]["is_required"] = false;
                                }
                                updatedRows[rowIndex][index].is_required = !updatedRows[rowIndex][index].is_required;
                                setJsonStructure({
                                    ...jsonStructure,
                                    ReadingStruct: {
                                        ...jsonStructure.ReadingStruct,
                                        TableStructure: { ...jsonStructure.ReadingStruct.TableStructure, rows: updatedRows }
                                    }
                                });
                            }}
                        />
                        <label className='form-check-label'>Required</label>
                    </div>
                    <div className="form-group col-md-4">
                        {field.type === 'select' && (
                            <div>
                                <label>Options (comma-separated)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="options"
                                    disabled
                                    value={field.options ? field.options.join(',') : ''}
                                    onChange={(e) => {
                                        const options = e.target.value.split(',');
                                        const updatedRows = [...jsonStructure.ReadingStruct.TableStructure.rows];
                                        updatedRows[rowIndex][index].options = options;
                                        setJsonStructure({
                                            ...jsonStructure,
                                            ReadingStruct: {
                                                ...jsonStructure.ReadingStruct,
                                                TableStructure: { ...jsonStructure.ReadingStruct.TableStructure, rows: updatedRows }
                                            }
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ))
        ));
    };

    return (
        <div>
            {haveResponse ? (
                <form onSubmit={handleSubmit}>
                    <div className="card" style={{ width: '90%', margin: 'Auto' }}>
                        <div className="card-body">
                            <div className="scrollable-div-sidebar" >
                                <div className="form-group">
                                    <label htmlFor="material_type">Material Type</label>
                                    <select onChange={handleInputChange} value={jsonStructure.material_type} className="form-control" id="material_type" name="material_type">
                                        <option value="Thermal">Thermal</option>
                                        <option value="ElectroTechnical">ElectroTechnical</option>
                                        <option value="Speed">Speed</option>
                                    </select>
                                </div>
                                <h3>Form Fields</h3>
                                {jsonStructure.formFields && renderFormFields(jsonStructure.formFields)}
                                <br />
                                <h3>Other Values</h3>
                                {jsonStructure.ReadingStruct.OtherValues && renderOtherValues(jsonStructure.ReadingStruct.OtherValues)}
                                <br />
                                <h3>Reading Form Fields</h3>
                                {jsonStructure.ReadingStruct.TableStructure.rows && renderReadingFields(jsonStructure.ReadingStruct.TableStructure.rows)}
                                <br />
                                {/* {materialType === 'Dimension' ?(
                                    <h3>(Dimension) Other Fields </h3>
                                ):null}
                                {materialType === 'Dimension' ? ( 
                                    <div>
                                        {OtherDimensionFields && renderDimensionOtherFields(OtherDimensionFields)}

                                    </div>
                                ) : null}
                                <br /> */}

                                <div className="text-center">
                                    <button className="btn btn-warning" type="button" onClick={handleCheckAll}>Check All</button>
                                    <button className="btn btn-info" type="submit">Submit</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </form>
            ) : (<Spinner />)}
            <ToastContainer />
        </div>
    );
}

export default FieldVisibilityForm;
