export const VBlockForm = [
  {
    "heading": "Squareness of Big V Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "C1",
          "label": "C1",
          "type": "text",
          "value": ""
        },
        {
          "name": "C2",
          "label": "C2",
          "type": "text",
          "value": ""
        },
        {
          "name": "C3",
          "label": "C3",
          "type": "text",
          "value": ""
        },
        {
          "name": "C4",
          "label": "C4",
          "type": "text",
          "value": ""
        },
        {
          "name": "C5",
          "label": "C5",
          "type": "text",
          "value": ""
        },
        {
          "name": "Squareness_max_error",
          "label": "Squareness (Max Error Value)",
          "type": "text",
          "value": ""
        }
      ],
      [
        {
          "name": "R2",
          "label": "R2",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "C1",
          "label": "C1",
          "type": "text",
          "value": ""
        },
        {
          "name": "C2",
          "label": "C2",
          "type": "text",
          "value": ""
        },
        {
          "name": "C3",
          "label": "C3",
          "type": "text",
          "value": ""
        },
        {
          "name": "C4",
          "label": "C4",
          "type": "text",
          "value": ""
        },
        {
          "name": "C5",
          "label": "C5",
          "type": "text",
          "value": ""
        },
        {
          "name": "Squareness_max_error",
          "label": "Squareness (Max Error Value)",
          "type": "text",
          "value": ""
        }
      ]
    ]
  },
  {
    "heading": "Squareness of Small V Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "C1",
          "label": "C1",
          "type": "text",
          "value": ""
        },
        {
          "name": "C2",
          "label": "C2",
          "type": "text",
          "value": ""
        },
        {
          "name": "C3",
          "label": "C3",
          "type": "text",
          "value": ""
        },
        {
          "name": "C4",
          "label": "C4",
          "type": "text",
          "value": ""
        },
        {
          "name": "C5",
          "label": "C5",
          "type": "text",
          "value": ""
        },
        {
          "name": "Squareness_max_error",
          "label": "Squareness (Max Error Value)",
          "type": "text",
          "value": ""
        }
      ],
      [
        {
          "name": "R2",
          "label": "R2",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "C1",
          "label": "C1",
          "type": "text",
          "value": ""
        },
        {
          "name": "C2",
          "label": "C2",
          "type": "text",
          "value": ""
        },
        {
          "name": "C3",
          "label": "C3",
          "type": "text",
          "value": ""
        },
        {
          "name": "C4",
          "label": "C4",
          "type": "text",
          "value": ""
        },
        {
          "name": "C5",
          "label": "C5",
          "type": "text",
          "value": ""
        },
        {
          "name": "Squareness_max_error",
          "label": "Squareness (Max Error Value)",
          "type": "text",
          "value": ""
        }
      ]
    ]
  },
  {
    "heading": "Squareness of Side I Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "C1",
          "label": "C1",
          "type": "text",
          "value": ""
        },
        {
          "name": "C2",
          "label": "C2",
          "type": "text",
          "value": ""
        },
        {
          "name": "C3",
          "label": "C3",
          "type": "text",
          "value": ""
        },
        {
          "name": "C4",
          "label": "C4",
          "type": "text",
          "value": ""
        },
        {
          "name": "C5",
          "label": "C5",
          "type": "text",
          "value": ""
        },
        {
          "name": "Squareness_max_error",
          "label": "Squareness (Max Error Value)",
          "type": "text",
          "value": ""
        }
      ],
      [
        {
          "name": "R2",
          "label": "R2",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "C1",
          "label": "C1",
          "type": "text",
          "value": ""
        },
        {
          "name": "C2",
          "label": "C2",
          "type": "text",
          "value": ""
        },
        {
          "name": "C3",
          "label": "C3",
          "type": "text",
          "value": ""
        },
        {
          "name": "C4",
          "label": "C4",
          "type": "text",
          "value": ""
        },
        {
          "name": "C5",
          "label": "C5",
          "type": "text",
          "value": ""
        },
        {
          "name": "Squareness_max_error",
          "label": "Squareness (Max Error Value)",
          "type": "text",
          "value": ""
        }
      ]
    ]
  },
  {
    "heading": "Squareness of Side II Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "C1",
          "label": "C1",
          "type": "text",
          "value": ""
        },
        {
          "name": "C2",
          "label": "C2",
          "type": "text",
          "value": ""
        },
        {
          "name": "C3",
          "label": "C3",
          "type": "text",
          "value": ""
        },
        {
          "name": "C4",
          "label": "C4",
          "type": "text",
          "value": ""
        },
        {
          "name": "C5",
          "label": "C5",
          "type": "text",
          "value": ""
        },
        {
          "name": "Squareness_max_error",
          "label": "Squareness (Max Error Value)",
          "type": "text",
          "value": ""
        }
      ],
      [
        {
          "name": "R2",
          "label": "R2",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "C1",
          "label": "C1",
          "type": "text",
          "value": ""
        },
        {
          "name": "C2",
          "label": "C2",
          "type": "text",
          "value": ""
        },
        {
          "name": "C3",
          "label": "C3",
          "type": "text",
          "value": ""
        },
        {
          "name": "C4",
          "label": "C4",
          "type": "text",
          "value": ""
        },
        {
          "name": "C5",
          "label": "C5",
          "type": "text",
          "value": ""
        },
        {
          "name": "Squareness_max_error",
          "label": "Squareness (Max Error Value)",
          "type": "text",
          "value": ""
        }
      ]
    ]
  },
  {
    "heading": "Parallelisum of Top Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "Starting_Point",
          "label": "Starting Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Ending_Point",
          "label": "Ending Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Maximum_Error",
          "label": "Maximum Error",
          "type": "text",
          "value": ""
        }
      ],
      [
        {
          "name": "R2",
          "label": "R2",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "Starting_Point",
          "label": "Starting Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Ending_Point",
          "label": "Ending Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Maximum_Error",
          "label": "Maximum Error",
          "type": "text",
          "value": ""
        }
      ]
    ]
  },
  {
    "heading": "Parallelisum of Side I Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "Starting_Point",
          "label": "Starting Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Ending_Point",
          "label": "Ending Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Maximum_Error",
          "label": "Maximum Error",
          "type": "text",
          "value": ""
        }
      ],
      [
        {
          "name": "R2",
          "label": "R2",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "Starting_Point",
          "label": "Starting Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Ending_Point",
          "label": "Ending Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Maximum_Error",
          "label": "Maximum Error",
          "type": "text",
          "value": ""
        }
      ]
    ]
  },
  {
    "heading": "Parallelisum of Big V Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "Starting_Point",
          "label": "Starting Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Ending_Point",
          "label": "Ending Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Maximum_Error",
          "label": "Maximum Error",
          "type": "text",
          "value": ""
        }
      ]
    ]
  },
  {
    "heading": "Parallelisum of Small V Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "Starting_Point",
          "label": "Starting Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Ending_Point",
          "label": "Ending Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Maximum_Error",
          "label": "Maximum Error",
          "type": "text",
          "value": ""
        }
      ]
    ]
  },
  {
    "heading": "Flatness of Top Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "Starting_Point",
          "label": "Starting Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Ending_Point",
          "label": "Ending Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Maximum_Error",
          "label": "Maximum Error",
          "type": "text",
          "value": ""
        }
      ]
    ]
  },
  {
    "heading": "Symmetricity of Top Face",
    "formstructure": [
      [
        {
          "name": "R1",
          "label": "R1",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "Starting_Point",
          "label": "Starting Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Ending_Point",
          "label": "Ending Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "R2",
          "label": "R2",
          "type": "Heading",
          "value": ""
        },
        {
          "name": "Starting_Point_2",
          "label": "Starting Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "Ending_Point_2",
          "label": "Ending Point",
          "type": "text",
          "value": ""
        },
        {
          "name": "max_error",
          "label": "Max Error",
          "type": "text",
          "value": ""
        }
      ]
    ]
  }
]
  