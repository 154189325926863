import React, { useState, useEffect } from "react";
import { Navbar, Button, Form } from "react-bootstrap";
import { useUucData } from "../Utils/UucDataContext";
import { Dialog } from "primereact/dialog";
import { ToastContainer, toast } from "react-toastify";

const ToolBar = () => {
    const { uucData } = useUucData();
    const [visible, setVisible] = useState(false);
    const [copied, setCopied] = useState(false);
    const defaultFontSize = 13;
    const [fontSize, setFontSize] = useState(() => {
        // Retrieve the font size from sessionStorage or set default to 13px
        return parseInt(sessionStorage.getItem("fontSize"), 10) || defaultFontSize;
    });

    const showSymbol = () => {
        setVisible(true);
    };

    const copyToClipboard = (symbol) => {
        navigator.clipboard.writeText(symbol).then(() => {
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
                setVisible(false);
            }, 1500); // Hide message and close dialog after 2 seconds
        });
    };

    const handleFontSizeChange = (event) => {
        const newSize = event.target.value;
        setFontSize(newSize);
        sessionStorage.setItem("fontSize", newSize);
    };

    const resetFontSize = () => {
        setFontSize(defaultFontSize);
        sessionStorage.setItem("fontSize", defaultFontSize);
    };

    useEffect(() => {
        // Update the document body font size
        document.body.style.fontSize = `${fontSize}px`;
    }, [fontSize]);

    return (
        <Navbar
            bg="dark"
            variant="dark"
            fixed="bottom"
            className="bg-dark"
            style={{ height: "4vh", fontSize: `${fontSize}px` }}
        >
            <div className="container-fluid text-white d-flex align-items-center justify-content-between ms-2">
                <div className="d-flex align-items-center">
                    <div className="me-3">{uucData?.srfItm_data?.job_no}</div>
                    <div className="me-3">{uucData?.instrument_name}</div>
                    <button
                        type="button"
                        onClick={showSymbol}
                        style={{
                            background: "none",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                            padding: 0,
                        }}
                    >
                        Symbols
                    </button>
                </div>
                <Dialog
                    visible={visible}
                    style={{ width: "50vw" }}
                    onHide={() => setVisible(false)}
                >
                    <div className="myBoxes-2" style={{ minHeight: "15vh" }}>
                        {copied ? (
                            <div className="text-center text-success">Copied!</div>
                        ) : (
                            [
                                "±",
                                "°C",
                                "μ",
                                "°F",
                                "Ω",
                                "²",
                                "³",
                                "°",
                                "Σ",
                                "Å",
                                "α",
                                "β",
                                "γ",
                                "δ",
                                "ε",
                                "ζ",
                                "η",
                                "θ",
                                "ι",
                                "κ",
                                "λ",
                                "ν",
                                "ξ",
                                "ο",
                                "π",
                                "ρ",
                                "σ",
                                "τ",
                                "υ",
                                "φ",
                                "χ",
                                "ψ",
                                "ω",
                            ].map((symbol) => (
                                <Button
                                    key={symbol}
                                    className="special_symbol_btn btn-link p-0 m-2"
                                    onClick={() => copyToClipboard(symbol)}
                                >
                                    {symbol}
                                </Button>
                            ))
                        )}
                    </div>
                </Dialog>
                <div className="d-flex align-items-center">
                    <Form.Range
                        min="12"
                        max="24"
                        value={fontSize}
                        onChange={handleFontSizeChange}
                        style={{ width: "100px" }}
                    />
                    <button
                        className="btn"
                        style={{ color: "white" }}
                        onClick={resetFontSize}
                    >
                        Reset
                    </button>
                </div>
            </div>
        </Navbar>
    );
};

export default ToolBar;
