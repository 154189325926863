export const DMMForm=[   //This form will be used for Pressure with dmm only for extra row with pressure reading written by shivam
          [
            {
              "name": "DMM Reading",
              "type": "Heading",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_std_value": true
            },
            {
              "name": "dmm_value_1",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "dmm_value_2",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "dmm_value_3",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "dmm_value_4",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "dmm_value_5",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "dmm_value_6",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "dmm_value_unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "data_attr": [
                "Pressure"
              ],
              "is_unit_select": true
            }
          ]
]