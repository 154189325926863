import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ConstructURL } from "../Url/BaseUrl";
import { useNavigate, useLocation } from "react-router-dom";
import { useUucData } from "../Utils/UucDataContext";
import { Spinner, SubmittingOverlay } from "../Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { schemaDecodeKey } from "../Common/hashingKeys";

function DucInfo() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('login');
  const refresh = queryParams.get('refresh');
  //Handle tokena and refresh
  localStorage.setItem("accessToke", token)
  localStorage.setItem("refresh", refresh)

  const id = queryParams.get('id');
  const userId = queryParams.get('userId');
  const hash_schema = queryParams.get('hash_schema');
  const [schema, setSchema] = useState("");
  const [url, setUrl] = useState("");
  const [haveUrl, setHaveUrl] = useState(false);
  const [haveSchema, sethaveSchema] = useState(false);
  const navigate = useNavigate();
  const { uucData, setUucData } = useUucData();
  const [formData, setFormData] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [haveResponse, setHaveResponse] = useState(false);
  const [uucOptions, setUucOptions] = useState(null);

  function decodeValue(encryptedText) {
    const key = schemaDecodeKey
    const paddedBase64 = encryptedText.replace(/0+$/, '');
    const base64Decoded = atob(paddedBase64);
    let decryptedText = '';
    for (let i = 0; i < base64Decoded.length; i++) {
      const keyC = key.charCodeAt(i % key.length);
      const decryptedC = String.fromCharCode(base64Decoded.charCodeAt(i) ^ keyC);
      decryptedText += decryptedC;
    }
    sethaveSchema(true);
    return decryptedText;
  }

  useEffect(() => {
    const encryptedValue = hash_schema;
    if (encryptedValue) {
      setSchema(decodeValue(encryptedValue));
    }
  }, []);

  const getUrl = useCallback(() => {
    const urlCacheKey = `url_${id}_${userId}_${schema}`;
    const urlCachedData = localStorage.getItem(urlCacheKey);
    if (haveSchema) {
      if (urlCachedData) {
        setUrl(ConstructURL(schema));
        localStorage.setItem(urlCacheKey, url);
        setHaveUrl(true);
      } else {
        setUrl(ConstructURL(schema));
        setHaveUrl(true);
        localStorage.setItem(urlCacheKey, url);
      }
    }
  }, [id, userId, schema]);

  useEffect(() => {
    getUrl();
  }, [getUrl]);

  const getUucData = useCallback(async () => {
    const cacheKey = `uucData_${id}_${userId}_${schema}`;
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const data = JSON.parse(cachedData);
      data["url"] = url;
      data["schema"] = schema;
      data["hash_schema"] = hash_schema;
      setUucData(data);
      setFormData(data.srfItm_data);
      setUucOptions(data.uuc_for_frontEnd);
      setHaveResponse(true);
      localStorage.setItem(cacheKey, JSON.stringify(data));
    } else {
      try {
        if (haveUrl) {
          try {

            const response = await axios.get(`${url}/get_duc_details/${id}/${userId}`, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }

            });

            if (response.status === 200) {
              const data = response.data;
              data["url"] = url;
              data["schema"] = schema;
              data["hash_schema"] = hash_schema;
              setUucData(data);
              setFormData(data.srfItm_data);
              setUucOptions(data.uuc_for_frontEnd);
              setHaveResponse(true);
              localStorage.setItem(cacheKey, JSON.stringify(data));
            }
          } catch (error) {
            toast.error('Unauthorized Request !')
            console.error("Error fetching data:", error);
          }
        }
      } catch (error) {
        toast.error("Something went wrong, maybe hit wrong url!");
        console.log(error, url, 'this is the error while calling api')
      }
    }
  }, [id, userId, url, setUucData]);

  useEffect(() => {
    getUucData();
    const handleBeforeUnload = (e) => {
      if (e.ctrlKey && e.shiftKey && e.keyCode === 82) {
        localStorage.removeItem(`uucData_${id}_${userId}_${schema}`);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [getUucData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await axios.post(`${url}/get_duc_details/${id}/${userId}`, formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }

        }
      );
      const updateUucData = {
        ...uucData,
        obsId: response.data.obsId
      }
      setFormData(updateUucData)
      localStorage.setItem(`uucData_${id}_${userId}_${schema}`, JSON.stringify(updateUucData))
      navigate("/calibration-procedure");
    } catch (error) {
      console.log(error);
      toast.error('Unauthorized Request !');
    } finally {
      setSubmitting(false);
    }
  };

  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //       caches.delete(name);
  //     });
  //   });
  //   localStorage.clear();
  //   setFormData(null);
  //   setUucOptions(null);
  //   setHaveResponse(false);
  //   window.location.reload();
  // };

  const renderField = (key, value) => {
    switch (key) {
      case "calibration_performed_at":
        return (
          <select
            className="form-control"
            id={key}
            name={key}
            value={value}
            onChange={handleChange}
          >
            <option value="LAB">LAB</option>
            <option value="SITE">SITE</option>
          </select>
        );
      case "expected_delivery":
        return (
          <input
            className="form-control"
            id={key}
            type="date"
            name={key}
            value={value}
            onChange={handleChange}
          />
        );
      default:
        return (
          <input
            className="form-control"
            id={key}
            type="text"
            name={key}
            value={value}
            onChange={handleChange}
          />
        );
    }
  };


  return (
    <div className="card " style={{ width: '90%', margin: 'Auto' }}>
      <div className="card-body">
        {isSubmitting ? <SubmittingOverlay /> : null}
        {haveResponse ? (
          <form onSubmit={handleSubmit}>
            <div className="card-body">
              <div className="form-area col-sm-12 row">
                {Object.keys(formData).map((key) => (
                  <div className="col-sm-3" key={key}>
                    <label htmlFor={key}>
                      {key
                        .replace("_", " ")
                        .replace(/^\w/, (c) => c.toUpperCase())}
                    </label>
                    {renderField(key, formData[key])}
                  </div>
                ))}
              </div>
              <div className="col-sm-12 text-right mt-3">
                <button
                  type="submit"
                  className="btn btn-success btn-lg"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Next"}
                </button>
              </div>
            </div>
          </form>
        ) : (
          <Spinner />
        )}
        <ToastContainer />
      </div>


    </div>
  );
}

export default DucInfo;
