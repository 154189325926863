export function isValidNumber(value) {
    // Check if the value is an empty string, a single dot, or a single hyphen
    if (value === '' || value === '.' || value === '-') {
        return true;
    }

    // Define a regular expression to match valid numbers
    const regex = /^-?\d*(\.\d*)?$/;

    // Test the value against the regex
    return regex.test(value);
}
