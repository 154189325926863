import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { url } from '../Url/BaseUrl';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Spinner} from "../Common/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function JsonEditor() {
    const [jsonStructure, setJsonStructure] = useState({
        material_type: '',
        formFields: [],
        ReadingStruct: {
            OtherValues: [],
            TableStructure: {
                headings: [],
                rows: [],
            },
        },
    });
    const [haveResponse, setHaveResponse] = useState(false);
    const [message, setMessage] = useState('');
    const [materialType, setMaterialType] = useState('Thermal')

    useEffect(() => {
        const fetchJsonStructure = async () => {
            setHaveResponse(false);
            try {
                const response = await axios.post(`${url}/get_form_structure`, {
                    material_type: materialType,
                });
                if (response.status === 200) {
                    const data = response.data;
                    data.jsonStruct.formFields = data.jsonStruct.formFields.map(field => ({ ...field, is_visible: field.is_visible ?? true }));
                    data.jsonStruct.ReadingStruct.OtherValues = data.jsonStruct.ReadingStruct.OtherValues.map(field => ({ ...field, is_visible: field.is_visible ?? true }));
                    setJsonStructure(data.jsonStruct);
                    setHaveResponse(true);
                }
            } catch (error) {
                toast.error(`${error}`)
                setHaveResponse(true);
            }
        };
        fetchJsonStructure();
    }, [materialType]);

    const handleFieldRename = (e, fieldIndex, fieldType) => {
        const { value } = e.target;
        const updatedFields = [...jsonStructure[fieldType]];
        const oldName = updatedFields[fieldIndex].name;
        updatedFields[fieldIndex].name = value;
        setJsonStructure({ ...jsonStructure, [fieldType]: updatedFields });
        updateJsonKeys(fieldType, oldName, value);
    };

    const updateJsonKeys = (fieldType, oldName, newName) => {
        const updatedStructure = { ...jsonStructure };

        if (fieldType === 'formFields') {
            updatedStructure.formFields = updatedStructure.formFields.map(field => {
                if (field.name === oldName) {
                    field.name = newName;
                }
                return field;
            });
        }

        if (fieldType === 'ReadingStruct.OtherValues') {
            updatedStructure.ReadingStruct.OtherValues = updatedStructure.ReadingStruct.OtherValues.map(field => {
                if (field.name === oldName) {
                    field.name = newName;
                }
                return field;
            });
        }

        setJsonStructure(updatedStructure);
    };

    const handleSave = async () => {
        try {
            await axios.post(`${url}/save_form_structure`,{"jsonStructure":jsonStructure, material_type: materialType});
            toast.success('JSON saved successfully!');
        } catch (error) {
            console.error(error);
            toast.error(`${error}`);
        }
    };

    const handleInputChange = (e, fieldIndex, fieldType) => {
        const { name, value } = e.target;
        if (name === 'material_type') {
            setMaterialType(value);
        }
        else {
            const updatedFields = [...jsonStructure[fieldType]];
            updatedFields[fieldIndex] = { ...updatedFields[fieldIndex], [name]: value };
            setJsonStructure({ ...jsonStructure, [fieldType]: updatedFields });
        }
    };

    const renderFormFields = (formFields) => {
        return formFields.map((field, index) => (

            <div key={index} className="form-row mb-3">
                <div className="form-group col-md-2">
                    <label>Label</label>
                    <input
                        type="text"
                        className="form-control"
                        name="label"
                        value={field.label}
                        onChange={(e) => handleInputChange(e, index, 'formFields')}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label>Type</label>
                    <select
                        className="form-control"
                        name="type"
                        value={field.type}
                        onChange={(e) => handleInputChange(e, index, 'formFields')}
                    >
                        <option value="text">Text</option>
                        <option value="select">Select</option>
                        {/* Add more types as needed */}
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <label>Name</label>
                    <input
                        type="text"
                        className="form-control"
                        value={field.name}
                        onChange={(e) => handleFieldRename(e, index, 'formFields')}
                    />
                </div>
                <div className="form-check form-switch col-md-2">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={field.is_visible}
                        onChange={(e) => {
                            const updatedFields = [...jsonStructure.formFields];
                            updatedFields[index].is_visible = e.target.checked;
                            setJsonStructure({ ...jsonStructure, formFields: updatedFields });
                        }}
                    />
                    <label className='form-check-label'>Visible</label>
                </div>
                <div className="form-group col-md-4">
                    {field.type === 'select' && (
                        <div>
                            <label>Options (comma-separated)</label>
                            <input
                                type="text"
                                className="form-control"
                                name="options"
                                value={field.options.join(',')}
                                onChange={(e) => {
                                    const options = e.target.value.split(',');
                                    const updatedFields = [...jsonStructure.formFields];
                                    updatedFields[index].options = options;
                                    setJsonStructure({ ...jsonStructure, formFields: updatedFields });
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        ));
    };

    const renderOtherValues = (otherValues) => {
        return otherValues.map((field, index) => (
            <div key={index} className="form-row mb-3">
                <div className="form-group col-md-2">
                    <label>Label</label>
                    <input
                        type="text"
                        className="form-control"
                        name="label"
                        value={field.label}
                        onChange={(e) => {
                            const updatedFields = [...jsonStructure.ReadingStruct.OtherValues];
                            updatedFields[index].label = e.target.value;
                            setJsonStructure({
                                ...jsonStructure,
                                ReadingStruct: { ...jsonStructure.ReadingStruct, OtherValues: updatedFields },
                            });
                        }}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label>Type</label>
                    <select
                        className="form-control"
                        name="type"
                        value={field.type}
                        onChange={(e) => handleInputChange(e, index, 'ReadingStruct.OtherValues')}
                    >
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                        <option value="select">Select</option>
                        {/* Add more types as needed */}
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <label>Name</label>
                    <input
                        type="text"
                        className="form-control"
                        value={field.name}
                        onChange={(e) => handleFieldRename(e, index, 'ReadingStruct.OtherValues')}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label>Value</label>
                    <input
                        type="text"
                        className="form-control"
                        name="value"
                        value={field.value}
                        onChange={(e) => {
                            const updatedFields = [...jsonStructure.ReadingStruct.OtherValues];
                            updatedFields[index].value = e.target.value;
                            setJsonStructure({
                                ...jsonStructure,
                                ReadingStruct: { ...jsonStructure.ReadingStruct, OtherValues: updatedFields },
                            });
                        }}
                    />
                </div>
                <div className="form-check form-switch col-md-2">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={field.is_visible}
                        onChange={(e) => {
                            const updatedFields = [...jsonStructure.ReadingStruct.OtherValues];
                            updatedFields[index].is_visible = e.target.checked;
                            setJsonStructure({
                                ...jsonStructure,
                                ReadingStruct: { ...jsonStructure.ReadingStruct, OtherValues: updatedFields },
                            });
                        }}
                    />
                    <label className='form-check-label'>Visible</label>
                </div>
                <div className="form-group col-md-4">
                    {field.type === 'select' && (
                        <div>
                            <label>Options (comma-separated)</label>
                            <input
                                type="text"
                                className="form-control"
                                name="options"
                                value={field.options ? field.options.join(',') : ''}
                                onChange={(e) => {
                                    const options = e.target.value.split(',');
                                    const updatedFields = [...jsonStructure.ReadingStruct.OtherValues];
                                    updatedFields[index].options = options;
                                    setJsonStructure({
                                        ...jsonStructure,
                                        ReadingStruct: { ...jsonStructure.ReadingStruct, OtherValues: updatedFields },
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        ));
    };

    return (
        <div>
            {haveResponse ? (
                <div className="container">
                    <h2 className='text-center'>Form Editor <span style={{ "color": "red" }}>{materialType}</span> </h2>
                    <div className="form-group">
                        <label htmlFor="material_type">Material Type</label>
                        <select onChange={handleInputChange} value={jsonStructure.material_type} className="form-control" id="material_type" name="material_type">
                            <option value="Thermal">Thermal</option>
                            <option value="ElectroTechnical">ElectroTechnical</option>
                            <option value="Speed">Speed</option>
                        </select>
                    </div>
                    <h3>Form Fields</h3>
                    {jsonStructure.formFields && renderFormFields(jsonStructure.formFields)}
                    <br />
                    <h3>Other Values</h3>
                    {jsonStructure.ReadingStruct.OtherValues && renderOtherValues(jsonStructure.ReadingStruct.OtherValues)}
                    <br />
                    <button className="btn btn-success mt-3" onClick={handleSave}>
                        Save JSON
                    </button>
                    {message && <p>{message}</p>}
                </div>
            ) : (<Spinner />)}
            <ToastContainer />
        </div>

    );
}

export default JsonEditor;
