import React, { useState, useMemo } from 'react';
import { useTable, useGlobalFilter } from 'react-table';
import matchSorter from 'match-sorter';

const SearchableTable = ({ formSections, formData, calculateAverage, makeDivId, handleTabClick, activeTab, FormStructure }) => {
    const data = useMemo(() => {
        let counter = 0;
        return formSections.flatMap((section, formSectionIndex) =>
            section.readings.map((_, sectionIndex) => {
                counter++;
                const tabId = `${formSectionIndex}_${sectionIndex}`;
                const parameter =
                    formData.observation_data[formSectionIndex]?.master_tbl?.type || "Default Parameter";
                const calculatedData = calculateAverage(
                    FormStructure,
                    formData,
                    formSectionIndex,
                    sectionIndex,
                    counter - 1
                );
                const readingsFields = calculatedData?.obsReadingData;
                const stdReadingData = calculatedData?.stdReadingData;
                return {
                    counter,
                    parameter,
                    nominalValue: stdReadingData ? stdReadingData.join(", ") : "",
                    // readings: readingsFields ? readingsFields.join(", ") : "",
                    readings: calculatedData?.avg_obs,
                    stdDev: isNaN(calculatedData?.stdDev) ? '-' : calculatedData?.stdDev,
                    tabId,
                    formSectionIndex,
                    sectionIndex,
                };
            })
        );
    }, [formSections, formData]);

    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "counter",
            },
            {
                Header: "Parameter",
                accessor: "parameter",
            },
            {
                Header: "STD",
                accessor: "nominalValue",
            },
            {
                Header: "DUC",
                accessor: "readings",
            },
            // {
            //     Header: "Avg",
            //     accessor: "avg",
            // },
            {
                Header: "Std Dev",
                accessor: "stdDev",
            },
            {
                Header: "Jump",
                Cell: ({ row }) => (
                    <a
                        href={makeDivId(row.original.formSectionIndex, row.original.sectionIndex)}
                        className={`nav-link ${activeTab === row.original.tabId ? "active" : ""}`}
                        onClick={(e) => {
                            e.preventDefault();
                            handleTabClick(row.original.tabId, row.original.formSectionIndex, row.original.sectionIndex);
                        }}
                    >
                        Jump
                    </a>
                ),
            },
        ],
        [activeTab, handleTabClick, makeDivId]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        setGlobalFilter,
        state: { globalFilter },
    } = useTable({ columns, data }, useGlobalFilter);

    return (
        <div className="card mt-4" style={{'width':'100%'}}>
            <input
                className="form-control col-md-12 sticky-top"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Search..."
                style={{ marginBottom: "10px" }}
            />
            <table
                id="readingsTable_preview"
                className="table "
                style={{ width: "100%", fontSize: "12px" }}
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default SearchableTable;
