import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export const Spinner = () => {
    return (
        <div className='row full-page-overlay' >
            <div className="skeleton-loader col-sm-3" style={{ border: "0px solid black" }}>
                <Skeleton height={35} width={`100%`} />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
            </div>
            <div className="skeleton-loader col-sm-3" style={{ border: "0px solid black" }}>
                <Skeleton height={35} width={`100%`} />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />

            </div>
            <div className="skeleton-loader col-sm-3" style={{ border: "0px solid black" }}>
                <Skeleton height={35} width={`100%`} />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
            </div>
            <div className="skeleton-loader col-sm-3" style={{ border: "0px solid black" }}>
                <Skeleton height={35} width={`100%`} />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />
                <Skeleton height={35} width={`100%`} className='mt-3' />

            </div>
        </div>

    );
};
export const SubmittingOverlay = () => {
    return (
      <div className="submitting-overlay">
        <div className="submitting-message">
          <div className="spinner"></div>

        </div>
        <div className='ms-2' style={{color:'white'}}>
            Saving...
        </div>
      </div>
    );
  };

export const SpinnerObReading = () => {
    return (
        <div className='row col-sm-12'>
            <div className="col-sm-3">
                <div className="skeleton-loader col-sm-12" style={{ height: "100%" }}>
                    <Skeleton height={35} width={`100%`} />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                    <Skeleton height={35} width={`100%`} className='mt-3' />
                </div>
            </div>
            <div className="col-sm-9 row">
                <div className="col-sm-12">
                    <div className="skeleton-loader">
                        <Skeleton height={35} width={`25%`} />
                        <Skeleton height={35} width={`25%`} />
                        <Skeleton height={35} width={`25%`} />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="skeleton-loader col-sm-12" >
                        <Skeleton height={35} width={`100%`} />
                        <Skeleton height={35} width={`100%`} className='mt-3' />
                        <Skeleton height={35} width={`100%`} className='mt-3' />
                        <Skeleton height={35} width={`100%`} className='mt-3' />
                        <Skeleton height={35} width={`100%`} className='mt-3' />
                        <Skeleton height={35} width={`100%`} className='mt-3' />
                    </div>
                </div>
            </div>

        </div>
    )
}
