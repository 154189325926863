const units = [] //This module is using for render different forms of observation form
const forms = {

  Pressure: {
    "formFields": [
      {
        "name": "atmPressure",
        "type": "text",
        "label": "ATM Pressure",
        "value": "",
        "data_attr": [
          "Pressure"
        ]
      },
      {
        "name": "atmPressure_unit",
        "type": "select",
        "label": "ATM Pressure Unit",
        "value": "",
        "options": [
          "Obs 1"
        ],
        "data_attr": [
          "Pressure"
        ],
        "is_unit_select": true
      },
      
      {
        "name": "gravityValue",
        "type": "text",
        "label": "Local Gravity",
        "value": "",
        "data_attr": [
          "Pressure"
        ],
        "is_visible": true
      },
      {
        "name": "gravityUnit",
        "type": "select",
        "label": "Local Gravity Unit",
        "value": "",
        "options": [
          "Select Unit",
          "V",
          "mV"
        ],
        "data_attr": [
          "Pressure"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "pressureType",
        "type": "select",
        "label": "Pressure Type",
        "value": "",
        "options": [
          "Obs 1"
        ],
        "data_attr": [
          "Pressure"
        ]
      },
      {
        "name": "masterLc",
        "type": "text",
        "label": "Master LC",
        "value": "",
        "data_attr": [
          "Pressure"
        ]
      },
      {
        "name": "master_lc_unit",
        "type": "select",
        "label": "Master LC Unit",
        "value": "",
        "options": [
            "Obs 1"
          ],
          "data_attr": [
            "Pressure"
          ],
          "is_unit_select": true
      },
      {
        "name": "ciValue",
        "type": "text",
        "label": "CI Value",
        "value": "",
        "data_attr": [
          "Pressure"
        ]
      }
    ],
    "ReadingStruct": {
      "OtherValues": [
        {
          "name": "zero_offset",
          "type": "text",
          "label": "Zero Offset",
          "value": "",
          "data_attr": [
            "Pressure"
          ]
        },
        {
          "name": "zero_offset_unit",
          "type": "select",
          "label": "Zero Offset Unit",
          "value": "",
          "options": [
            "Obs 1"
          ],
          "data_attr": [
            "Pressure"
          ],
          "is_unit_select": true
        },
        {
          "name": "hysteresis",
          "type": "text",
          "label": "Hysteresis",
          "value": "",
          "data_attr": [
            "Pressure"
          ]
        },
        {
          "name": "currentValue",
          "type": "text",
          "label": "Current Value",
          "value": "",
          "data_attr": [
            "Pressure"
          ]
        },
        {
          "name": "current_unit",
          "type": "select",
          "label": "Current Unit",
          "value": "",
          "options": [
            "Obs 1"
          ],
          "data_attr": [
            "Pressure"
          ],
          "is_unit_select": true
        },
        {
          "name": "voltage",
          "type": "text",
          "label": "Voltage",
          "value": "",
          "data_attr": [
            "Pressure"
          ]
        },
        {
          "name": "voltage_unit",
          "type": "select",
          "label": "Voltage Unit",
          "value": "",
          "options": [
            "Obs 1"
          ],
          "data_attr": [
            "Pressure"
          ],
          "is_unit_select": true
        },
      ],
      "TableStructure": {
        "rows": [
          [
            {
              "name": "nominal_value",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_std_value": true,
              "is_required": true
            },
            {
              "name": "nomUnit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "data_attr": [
                "Pressure"
              ],
              "is_unit_select": true,
              "is_required": true
            },
            {
              "name": "Observation_value_1",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true,
              "is_required": true
            },
            {
              "name": "Observation_value_2",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "Observation_value_3",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "Observation_value_4",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "Observation_value_5",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "Observation_value_6",
              "type": "text",
              "value": "",
              "data_attr": [
                "Pressure"
              ],
              "is_obs_reading": true
            },
            {
              "name": "Observation_unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "data_attr": [
                "Pressure"
              ],
              "is_unit_select": true,
              "is_required": true
            }
          ]
          
        ],
        
        "headings": [
          "Nominal Value",
          "Nominal Unit",
          "X1",
          "X2",
          "X3",
          "X4",
          "X5",
          "X6",
          "Observation Unit"
        ]
      }
    },
    "material_type": "Pressure"
  },
  CommonDiscipline: {
    formFields: [
      { label: 'Variation of Room Temp', type: 'text', name: 'variationOfRoomTemp', value: '' },
      { label: 'Limit of Room Temp', type: 'text', name: 'limitOfRoomTemp', value: '' },
      { label: 'Uncertainty of Slip Gauge', type: 'text', name: 'uncertaintyOfSlipGauge', value: '' },
      { label: 'Uncertainty of Comparator Stand', type: 'text', name: 'uncertaintyOfComparatorStand', value: '' },
      { label: 'Mode', type: 'select', name: 'mode', value: '', options: ['Source Mode', 'Measure Mode'] },
      { label: 'Uncertainty of Data Logger', type: 'text', name: 'uncertaintyOfDataLogger', value: '' },
      { label: 'Max Error of Temp Recorder', type: 'text', name: 'maxErrorOfTempRecorder', value: '' },
      { label: 'Uncertainty of Surface Plate', type: 'text', name: 'uncertaintyOfSurfacePlate', value: '' },
      { label: 'Parallelism Error', type: 'text', name: 'parallelismError', value: '' },
      { label: 'Parallelism Error Unit', type: 'select', options: ['Source Mode', 'Measure Mode'], is_unit_select: true, name: 'parallelismErrorUnit', value: '', },
      {
        label: 'Sub Parameter', type: 'select', options: [

          "Plunger / Lever Dial Gauge",
          "Caliper",
          "Depth Gauge/Depth Micrometer",
          "External Micrometer",
          "Surface Plate",
          "Angle Plate",
          "Height Gauge",
          "Feeler Gauge",
          "Bench Center",
          "Dial Gauge & Bore Gauge",
          "Bore Gauge",
          "Depth Micrometer",
          "Setting Rod",
          "V-block",
          "Snap Gauge",
          "Thickness Gauge",
          "Plain Plug Gauge",
          "Measuring Pin",
          "Steel Scale",
          "Measuring Scale & Tape",
          "Measuring Tape"
        ], name: 'sub_parameter', value: '',
      },
      { label: 'Flatness Evil', type: 'text', name: 'flatnessEvil', value: '' },
      { label: 'Is Dial Gauge Error Use', type: 'select', name: 'isDialGaugeErrorUse', value: '', options: ['Yes', 'No'] },
      { label: 'Assumed Diff of Temp', type: 'text', name: 'assumedDiffOfTemp', value: '' },
      { label: 'Assumed Diff in Linear TC', type: 'text', name: 'assumedDiffInLinearTc', value: '' },
      { label: 'Data Entry Type (Only fo Caliper)', type: 'select', name: 'caliper_dimension', value: [], options: ["Inside", "Outside", "Height"] },

    ],
    "ReadingStruct": {
      "OtherValues": [
        { label: 'Nominal Value', type: 'text', name: 'nominalValue', value: ''},
        { label: 'Nominal Value Unit', type: 'select', name: 'nominalValueUnit', value: '', options: ["Parallelism", "Flatness", "Squareness", "Symmetricity"] , is_unit_select:true},
        { label: 'Data Entry Type', type: 'select', name: 'dimension_data_entry_type', value: [], options: ["Parallelism", "Flatness", "Squareness", "Symmetricity"] },
        { label: 'Certificates', type: 'select', name: 'master_cert', value: [], options: ['Certificate 1', 'Certificate 2', 'Certificate 3'], multiple: true }
      ],
      "tableFields": [
        { type: 'Heading', name: 'X Dimension', value: '' },
        { type: 'select', name: 'x_unit', value: '', multiple: false, options: ['Select Load', 'Low', 'Medium', 'High'], is_unit_select: true },
        { type: 'text', name: 'x1_value', value: '' },
        { type: 'text', name: 'x2_value', value: '' },
        { type: 'text', name: 'x3_value', value: '' },
        { type: 'text', name: 'x4_value', value: '' },
        { type: 'text', name: 'x5_value', value: '' },
        { type: 'Heading', name: 'Y Dimension', value: '' },
        { type: 'select', name: 'y_unit', value: '', multiple: false, options: ['Select Load', 'Low', 'Medium', 'High'], is_unit_select: true },
        { type: 'text', name: 'y1_value', value: '' },
        { type: 'text', name: 'y2_value', value: '' },
        { type: 'text', name: 'y3_value', value: '' },
        { type: 'text', name: 'y4_value', value: '' },
        { type: 'text', name: 'y5_value', value: '' },
        { type: 'Heading', name: 'Z Dimension', value: '' },
        { type: 'select', name: 'z_unit', value: '', multiple: false, options: ['Select Load', 'Low', 'Medium', 'High'], is_unit_select: true },
        { type: 'text', name: 'z1_value', value: '' },
        { type: 'text', name: 'z2_value', value: '' },
        { type: 'text', name: 'z3_value', value: '' },
        { type: 'text', name: 'z4_value', value: '' },
        { type: 'text', name: 'z5_value', value: '' },
      ],
      "TableStructure": {
        "rows": [

          [
            {
              "name": "uuc_value_1",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_obs_reading": true
            },
            {
              "name": "uuc_value_2",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ]
              ,
              "is_obs_reading": true
            },
            {
              "name": "uuc_value_3",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_obs_reading": true

            },
            {
              "name": "uuc_value_4",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_obs_reading": true
            },
            {
              "name": "uuc_value_5",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_obs_reading": true
            },
            {
              "name": "uuc_value_unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_unit_select": true
            }
          ]
        ],
        "headings": [
          "Value 1",
          "Value 2",
          "Value 3",
          "Value 4",
          "Value 5",
          "Unit"
        ]
      }
    },
    "material_type": "CommonDiscipline"
  },
  Dimension: {
    "material_type": "Dimension",
    formFields: [
      { label: 'Variation of Room Temp', type: 'text', name: 'variationOfRoomTemp', value: '', "is_required": true },
      { label: 'Limit of Room Temp', type: 'text', name: 'limitOfRoomTemp', value: '', "is_required": true },
      { label: 'Uncertainty of Slip Gauge', type: 'text', name: 'uncertaintyOfSlipGauge', value: '' },
      { label: 'Uncertainty of Comparator Stand', type: 'text', name: 'uncertaintyOfComparatorStand', value: '' },
      { label: 'Uncertainty of Data Logger', type: 'text', name: 'uncertaintyOfDataLogger', value: '' },
      { label: 'Max Error of Temp Recorder', type: 'text', name: 'maxErrorOfTempRecorder', value: '' },
      { label: 'Uncertainty of Surface Plate', type: 'text', name: 'uncertaintyOfSurfacePlate', value: '' },
      { label: 'Parallelism Error', type: 'text', name: 'parallelismError', value: '' },
      { label: 'Parallelism Error Unit', type: 'select', options: ['Source Mode', 'Measure Mode'], is_unit_select: true, name: 'parallelismErrorUnit', value: '', },
      { label: 'Flatness Evil', type: 'text', name: 'flatnessEvil', value: '' },
      { label: 'Is Dial Gauge Error Use', type: 'select', name: 'isDialGaugeErrorUse', value: '', options: ['Yes', 'No'] },
      { label: 'Assumed Diff of Temp', type: 'text', name: 'assumedDiffOfTemp', value: '' },
      { label: 'Assumed Diff in Linear TC', type: 'text', name: 'assumedDiffInLinearTc', value: '' },
      { label: 'Mode', type: 'select', name: 'mode', value: '', options: ['Source Mode', 'Measure Mode'] , "is_required": true},

    ],
    ReadingStruct: {
      "OtherValues": [
        { label: 'Set Value', type: 'text', name: 'setValue', value: '', is_std_value: true, "is_required": true },
        { label: 'Set Value Unit', type: 'select', name: 'setValue_unit', value: [], options: ['Opt 1'], is_unit_select: true , "is_required": true},
        { label: 'Dimension Data Entry Type', type: 'select', name: 'dimension_data_entry_type', value: [], options: ['Setting Rod', 'Zero Point Calibration', 'Bore Gauge', 'Dial Gauge', 'Go Size', 'No Go Size'] },
        { label: 'Certificates', type: 'select', name: 'master_cert', value: [], options: ['Certificate 1', 'Certificate 2', 'Certificate 3'], multiple: true }
      ],

      "TableStructure": {
        "headings": ['Dimension', 'Unit', 'Value 1', 'Value 2', 'Value 3', 'Value 4', 'Value 5'],
        "rows": [

          [
            { type: 'Heading', name: 'X Dimension', value: '' },
            { type: 'select', name: 'x_unit', value: '', multiple: false, options: ['Select Load', 'Low', 'Medium', 'High'], is_unit_select: true },
            { type: 'text', name: 'x1_value', value: '', is_obs_reading: true },
            { type: 'text', name: 'x2_value', value: '', is_obs_reading: true },
            { type: 'text', name: 'x3_value', value: '', is_obs_reading: true },
            { type: 'text', name: 'x4_value', value: '', is_obs_reading: true },
            { type: 'text', name: 'x5_value', value: '', is_obs_reading: true },
            // { type: 'select', name: 'STD Value Unit', value: '', multiple:false, options: ['Select Load', 'Low', 'Medium', 'High'] },

          ],
          [
            { type: 'Heading', name: 'Y Dimension', value: '' },
            { type: 'select', name: 'y_unit', value: '', multiple: false, options: ['Select Load', 'Low', 'Medium', 'High'], is_unit_select: true },
            { type: 'text', name: 'y1_value', value: '' },
            { type: 'text', name: 'y2_value', value: '' },
            { type: 'text', name: 'y3_value', value: '' },
            { type: 'text', name: 'y4_value', value: '' },
            { type: 'text', name: 'y5_value', value: '' },

          ],
          [
            { type: 'Heading', name: 'Z Dimension', value: '' },
            { type: 'select', name: 'z_unit', value: '', multiple: false, options: ['Select Load', 'Low', 'Medium', 'High'], is_unit_select: true },
            { type: 'text', name: 'z1_value', value: '' },
            { type: 'text', name: 'z2_value', value: '' },
            { type: 'text', name: 'z3_value', value: '' },
            { type: 'text', name: 'z4_value', value: '' },
            { type: 'text', name: 'z5_value', value: '' },

          ],
        ]
      }
    }

  },

  Speed: {
    "material_type": "Speed",
    "formFields": [
      {
        "label": "Stability Of Source",
        "type": "text",
        "name": "stabilty_of_source",
        "value": "",
        "data_attr": [
          "Speed"
        ],
        "is_visible": true
      },
      {
        "label": "Stability Of Source Unit",
        "type": "text",
        "name": "stabilty_of_source_unit",
        "value": "",
        "data_attr": [
          "Speed"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "label": "Mode",
        "type": "select",
        "name": "mode",
        "value": "",
        "options": [
          "Source Mode",
          "Measure Mode"
        ],
        "data_attr": [
          "Speed"
        ],
        "is_visible": true
      }
    ],
    "ReadingStruct": {
      "OtherValues": [
        {
          "label": "Nominal Value",
          "type": "text",
          "name": "nominal_value",
          "value": "",
          "data_attr": [
            "Speed"
          ],
          "is_visible": true
        },
        {
          "label": "Nominal Value Unit",
          "type": "select",
          "name": "nominal_value_unit",
          "value": "",
          "options": [
            "Obs 1"
          ],
          "data_attr": [
            "Speed"
          ],
          "is_visible": true,
          "is_unit_select": true
        }
      ],
      "TableStructure": {
        "headings": [
          "Reading Type",
          "Value 1",
          "Value 2",
          "Value 3",
          "Value 4",
          "Value 5",
          "Unit"
        ],
        "rows": [
          [
            {
              "type": "Heading",
              "name": "STD Values",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "nom1",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "nom2",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "nom3",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "nom4",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "nom5",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            }
          ],
          [
            {
              "type": "Heading",
              "name": "UUC Values",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "observation_1",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "observation_2",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "observation_3",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "observation_4",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "text",
              "name": "observation_5",
              "value": "",
              "data_attr": [
                "Speed"
              ]
            },
            {
              "type": "select",
              "name": "observation_value_unit",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "data_attr": [
                "Speed"
              ],
              "is_unit_select": true
            }
          ]
        ]
      }
    }
  },

  ElectroTechnical: {
    "formFields": [
      {
        "name": "current_num",
        "type": "text",
        "label": "Current Num. Value",
        "value": "",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "current_denom",
        "type": "text",
        "label": "Current Denom. Value",
        "value": "",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "stability_of_source",
        "type": "text",
        "label": "Stability of Source",
        "value": "",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "stability_of_source_unit",
        "type": "select",
        "label": "Stability of Source Unit",
        "value": "",
        "options": [],
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "shunt_ratio",
        "type": "text",
        "label": "Shunt Ratio",
        "value": "",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "shunt_ratio_unit",
        "type": "select",
        "label": "Shunt Unit",
        "value": "",
        "options": [
          "Unit 1"
        ],
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "mode",
        "type": "select",
        "label": "Mode of Master",
        "value": "",
        "options": [
          "Source Mode",
          "Measure Mode"
        ],
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "lead_resistence",
        "type": "text",
        "label": "Lead resistence",
        "value": "",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "lead_resistence_unit",
        "type": "select",
        "label": "Lead resistence unit",
        "value": "",
        "options": [],
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "time_bias",
        "type": "text",
        "label": "Time bias",
        "value": "0.12",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "time_bias_unit",
        "type": "select",
        "label": "Time bias unit",
        "value": "",
        "options": [],
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "ci_value",
        "type": "text",
        "label": "Ci value",
        "value": "",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "time_std_dev",
        "type": "text",
        "label": "Time std dev",
        "value": "0.23",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "time_std_dev_unit",
        "type": "select",
        "label": "Time std dev unit",
        "value": "",
        "options": [],
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "master_lc",
        "type": "text",
        "label": "Master lc",
        "value": "",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      },
      {
        "name": "master_lc_unit",
        "type": "select",
        "label": "Master lc unit",
        "value": "",
        "options": [],
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "temp_channel",
        "type": "text",
        "label": "Temp channel",
        "value": "",
        "data_attr": [
          "ElectroTechnical"
        ],
        "is_visible": true
      }
    ],
    "ReadingStruct": {
      "OtherValues": [
        {
          "name": "rangeValue",
          "type": "text",
          "label": "Range Value",
          "value": "",
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true
        },
        {
          "name": "range_value_unit",
          "type": "select",
          "label": "Range Value unit",
          "value": "",
          "is_visible": true,
          "is_unit_select": true

        },
        {
          "name": "powerFactor",
          "type": "text",
          "label": "Power Factor",
          "value": "",
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true
        },
        {
          "name": "temperatureDeviation",
          "type": "text",
          "label": "Temperature Deviation",
          "value": "",
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true
        },
        {
          "name": "calibrationType",
          "type": "select",
          "label": "Calibration Type",
          "value": "",
          "options": [
            "J-Type",
            "K-Type",
            "R-Type",
            "S-Type",
            "N-Type",
            "B-Type",
            "E-Type",
            "T-Type",
            "RTD",
            "Lead",
            "Lag",
            "Unity",
            "Ind.",
            "Cap."
          ],
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true
        },
        {
          "name": "currentValue",
          "type": "text",
          "label": "Current Value",
          "value": "",
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true
        },
        {
          "name": "currentUnit",
          "type": "select",
          "label": "Current Unit",
          "value": "",
          "options": [
            "Select Unit",
            "A",
            "mA"
          ],
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true,
          "is_unit_select": true
        },
        {
          "name": "voltageValue",
          "type": "text",
          "label": "Voltage Value",
          "value": "",
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true
        },
        {
          "name": "voltageUnit",
          "type": "select",
          "label": "Voltage Unit",
          "value": "",
          "options": [
            "Select Unit",
            "V",
            "mV"
          ],
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true,
          "is_unit_select": true
        },
        
        {
          "name": "frequency",
          "type": "text",
          "label": "Frequency",
          "value": "",
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true
        },
        {
          "name": "frequencyUnit",
          "type": "select",
          "label": "Frequency Unit",
          "value": "",
          "options": [
            "Select Unit",
            "Hz",
            "kHz"
          ],
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true,
        },
        {
          "name": "energyLoad",
          "type": "select",
          "label": "Energy Load",
          "value": "",
          "options": [
            "Balanced Load",
            "Unbalanced Load"
          ],
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true
        },
        {
          "name": "energyMode",
          "type": "select",
          "label": "Energy Mode",
          "value": "",
          "options": [
            "Direct Mode",
            "CT Mode"
          ],
          "data_attr": [
            "ElectroTechnical"
          ],
          "is_visible": true
        }
      ],
      "TableStructure": {
        "rows": [
          [
            {
              "name": "STD Values",
              "type": "Heading",
              "data_attr": [
                "ElectroTechnical"
              ]
            },
            {
              "name": "std_value_1",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_std_value": true,
              "is_required":true
            },
            {
              "name": "std_value_2",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_std_value": true
            },
            {
              "name": "std_value_3",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_std_value": true
            },
            {
              "name": "std_value_4",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_std_value": true
            },
            {
              "name": "std_value_5",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_std_value": true
            },
            {
              "name": "std_value_unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_unit_select": true,
              "is_required":true
            }
          ],
          [
            {
              "name": "UUC Values",
              "type": "Heading",
              "data_attr": [
                "ElectroTechnical"
              ]

            },
            {
              "name": "uuc_value_1",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_obs_reading": true,
              "is_required":true
            },
            {
              "name": "uuc_value_2",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ]
              ,
              "is_obs_reading": true
            },
            {
              "name": "uuc_value_3",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_obs_reading": true

            },
            {
              "name": "uuc_value_4",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_obs_reading": true
            },
            {
              "name": "uuc_value_5",
              "type": "text",
              "value": "",
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_obs_reading": true
            },
            {
              "name": "uuc_value_unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "data_attr": [
                "ElectroTechnical"
              ],
              "is_unit_select": true,
              "is_required":true
            }
          ]
        ],
        "headings": [
          "Reading Type",
          "Value 1",
          "Value 2",
          "Value 3",
          "Value 4",
          "Value 5",
          "Unit"
        ]
      }
    },
    "material_type": "ElectroTechnical"
  },

  Thermal: {
    "formFields": [
      {
        "name": "roundingDigits",
        "type": "text",
        "label": "Rounding of Digits",
        "value": "",
        "is_visible": true
      },
      {
        "name": "roundingDigitsUnit",
        "type": "select",
        "label": "Rounding of Digits Unit",
        "value": "",
        "options": [
          "Select Unit"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "stabilityBath",
        "type": "text",
        "label": "Stability of Bath",
        "value": "",
        "is_visible": true
      },
      {
        "name": "stabilityBathUnit",
        "type": "select",
        "label": "Stability of Bath Unit",
        "value": "",
        "options": [
          "Select Unit"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "uniformityBath",
        "type": "text",
        "label": "Uniformity of Bath",
        "value": "",
        "is_visible": true
      },
      {
        "name": "uniformityBathUnit",
        "type": "select",
        "label": "Uniformity of Bath Unit",
        "value": "",
        "options": [
          "Select Unit"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "immersionDepthError",
        "type": "text",
        "label": "Immersion Depth Error",
        "value": "",
        "is_visible": true
      },
      {
        "name": "immersionDepthErrorUnit",
        "type": "select",
        "label": "Immersion Depth Error Unit",
        "value": "",
        "options": [
          "Select Unit"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "stabilityIcePointCell",
        "type": "text",
        "label": "Stability of Ice Point Cell",
        "value": "",
        "is_visible": true
      },
      {
        "name": "stabilityIcePointCellUnit",
        "type": "select",
        "label": "Stability of Ice Point Cell Unit",
        "value": "",
        "options": [
          "Select Unit"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "inhomogeneityTc",
        "type": "text",
        "label": "Inhomogeneity of T/C",
        "value": "",
        "is_visible": true
      },
      {
        "name": "inhomogeneityTcUnit",
        "type": "select",
        "label": "Inhomogeneity of T/C Unit",
        "value": "",
        "options": [
          "Select Unit"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "selfHeatingError",
        "type": "text",
        "label": "Self Heating Error",
        "value": "",
        "is_visible": true
      },
      {
        "name": "selfHeatingErrorUnit",
        "type": "select",
        "label": "Self Heating Error Unit",
        "value": "",
        "options": [
          "Select Unit"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "dryWetThermometer",
        "type": "text",
        "label": "Dry Wet Thermometer",
        "value": "",
        "is_visible": true
      },
      {
        "name": "dryThermometerUnit",
        "type": "select",
        "label": "Dry Thermometer Unit",
        "value": "",
        "options": [
          "Select Unit"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "channelJunction",
        "type": "text",
        "label": "Channel Junction",
        "value": "",
        "is_visible": true
      },
      {
        "name": "gradientFluctuation",
        "type": "text",
        "label": "Gradient Fluctuation",
        "value": "",
        "is_visible": true
      },
      {
        "name": "mode",
        "type": "select",
        "label": "Mode",
        "value": "",
        "options": [
          "Source Mode",
          "Measure Mode"
        ],
        "is_visible": true
      }
    ],
    "ReadingStruct": {
      "OtherValues": [
        {
          "name": "range_value",
          "type": "text",
          "label": "Range Value",
          "value": "",
          "is_visible": true
        },
        {
          "name": "range_value_unit",
          "type": "select",
          "label": "Range Value unit",
          "value": "",
          "is_visible": true,
          "is_unit_select": true

        },

        {
          "name": "temp_dev",
          "type": "text",
          "label": "Temp. Dev.",
          "value": "",
          "is_visible": true
        },
        {
          "name": "frequency",
          "type": "text",
          "label": "Frequency",
          "value": "",
          "is_visible": true
        },
        {
          "name": "frequency_unit",
          "type": "select",
          "label": "Frequency Unit",
          "value": "",
          "options": [
            "μHz",
            "Hz",
            "kHz",
            "MHz",
            "mHz",
            "GHz"
          ],
          "is_visible": true,
          "is_unit_select": false
        },
        {
          "name": "temp_calibration_type",
          "type": "select",
          "label": "Temp Calibration Type",
          "value": "",
          "options": [
            "J-Type",
            "K-Type",
            "R-Type",
            "S-Type",
            "N-Type",
            "B-Type",
            "E-Type",
            "T-Type",
            "RTD",
            "Lead",
            "Lag",
            "Unity",
            "Ind.",
            "Cap."
          ],
          "is_visible": true
        },
        {
          "name": "cold_junstion_temp",
          "type": "text",
          "label": "Cold Junction Temp",
          "value": "",
          "is_visible": true
        },
        {
          "name": "ci_value",
          "type": "text",
          "label": "ci_value",
          "value": "",
          "is_visible": true
        },
        {
          "name": "ci_value_uuc",
          "type": "text",
          "label": "ci_value_uuc",
          "value": "",
          "is_visible": true
        }
      ],
      "TableStructure": {
        "rows": [
          [
            {
              "name": "STD Value",
              "type": "Heading",
              "value": ""
            },
            {
              "name": "STD_Value_1",
              "type": "text",
              "value": "",
              "is_std_value": true,
              "is_required": true
            },
            {
              "name": "STD_Value_2",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "STD_Value_3",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "STD_Value_4",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "STD_Value_5",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "STD_Value_Unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "multiple": false,
              "is_unit_select": true,
              "is_required": true
            }
          ],
          [
            {
              "name": "",
              "type": "Heading",
              "value": ""
            },
            {
              "name": "STD_Value_6",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "STD_Value_7",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "STD_Value_8",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "STD_Value_9",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "STD_Value_10",
              "type": "text",
              "value": "",
              "is_std_value": true
            }
          ],
          [
            {
              "name": "UUC Value",
              "type": "Heading",
              "value": ""
            },
            {
              "name": "Obs_Value_1",
              "type": "text",
              "value": "",
              "is_obs_reading": true,
              "is_required": true
            },
            {
              "name": "Obs_Value_2",
              "type": "text",
              "value": "",
              "is_obs_reading": true
            },
            {
              "name": "Obs_Value_3",
              "type": "text",
              "value": "",
              "is_obs_reading": true
            },
            {
              "name": "Obs_Value_4",
              "type": "text",
              "value": "",
              "is_obs_reading": true
            },
            {
              "name": "Obs_Value_5",
              "type": "text",
              "value": "",
              "is_obs_reading": true
            },
            {
              "name": "obs_Value_Unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "multiple": false,
              "is_unit_select": true,
              "is_required": true
            }
          ],
          [
            {
              "name": "",
              "type": "Heading",
              "value": ""
            },
            {
              "name": "Obs_Value_6",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_7",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_8",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_9",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_10",
              "type": "text",
              "value": ""
            }
          ]
        ],
        "headings": [
          "Reading Type",
          "Value 1",
          "Value 2",
          "Value 3",
          "Value 4",
          "Value 5",
          "Unit",
        ]
      }
    },
    "material_type": "Thermal"
  }
  ,
  Weight : {
    "formFields": [
      {
        "name": "Va",
        "type": "text",
        "label": "Assumed Air Density",
        "value": "",
        "is_visible": true
      },
      {
        "name": "Vo",
        "type": "text",
        "label": "Refrence Air Density",
        "value": "",
        "is_visible": true,
      },
      {
        "name": "Vt",
        "type": "text",
        "label": "Density of Test Weight",
        "value": "",
        "is_visible": true
      },
      {
        "name": "Vr",
        "type": "text",
        "label": "Density of Reference Weight",
        "value": "",
        "is_visible": true,
      },
      {
        "name": "unit_of_vt",
        "type": "select",
        "label": "Densities Unit",
        "value": "",
        "options": [
          "Select Unit"
        ],
        "is_visible": true,
        "is_unit_select": true
      },
      {
        "name": "atm_pressure",
        "type": "text",
        "label": "Atmospheric Pressure",
        "value": "",
        "is_visible": true
      },
      {
        "name": "material_type",
        "type": "select",
        "label": "Material Type",
        "value": "",
        "options": [
          "Brass",
          "Cast Iron",
          "SS"
        ],
        "is_visible": true,
      }
    ],
    "ReadingStruct": {
      "OtherValues": [
        {
          "name": "nominal_value",
          "type": "text",
          "label": "Nominal Value",
          "value": "",
          "is_visible": true,
          "is_required": true
        },
        {
          "name": "nominal_value_unit",
          "type": "select",
          "label": "Nominal Value Unit",
          "value": "",
          "options": [
            "μHz",
            "Hz",
            "kHz",
            "MHz",
            "mHz",
            "GHz"
          ],
          "is_unit_select": true,
          "is_required": true
        },
        { label: 'Select Mass', type: 'select', name: 'master_cert', value: [], options: ['Certificate 1', 'Certificate 2', 'Certificate 3'], multiple: true },
        {
          "name": "item_id_value",
          "type": "text",
          "label": "Item Id",
          "value": "",
          "is_visible": true
        },
        {
          "name": "item_sr_no",
          "type": "text",
          "label": "Weight Sr no./Marking",
          "value": "",
          "is_visible": true,

        }

        
      ],
      "TableStructure": {
        "rows": [
          [
            {
              "name": " 1",
              "type": "Heading",
              "value": ""
            },
            
            {
              "name": "obs11",
              "type": "text",
              "value": "",
              "is_required": true
            },
            {
              "name": "obs12",
              "type": "text",
              "value": "",
              "is_required": true
            },
            {
              "name": "obs13",
              "type": "text",
              "value": "",
              "is_required": true
            },
            {
              "name": "obs14",
              "type": "text",
              "value": "",
              "is_required": true
            },
            {
              "name": "obsUnit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "multiple": false,
              "is_unit_select": true,
              "is_required": true
            }
          ],
          [
            {
              "name": " 2",
              "type": "Heading",
              "value": ""
            },
            
            {
              "name": "obs21",
              "type": "text",
              "value": "",
              "is_std_value": true,
              
            },
            {
              "name": "obs22",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "obs23",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "obs24",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            
            
          ],
          [
            {
              "name": " 3",
              "type": "Heading",
              "value": ""
            },
            
            {
              "name": "obs31",
              "type": "text",
              "value": "",
              "is_std_value": true,
              
            },
            {
              "name": "obs32",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "obs33",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "obs34",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            
            
          ],
          [
            {
              "name": " 4",
              "type": "Heading",
              "value": ""
            },
            
            {
              "name": "obs41",
              "type": "text",
              "value": "",
              "is_std_value": true,
              
            },
            {
              "name": "obs42",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "obs43",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "obs44",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            
            
          ],
          [
            {
              "name": " 5",
              "type": "Heading",
              "value": ""
            },
            
            {
              "name": "obs51",
              "type": "text",
              "value": "",
              "is_std_value": true,
              
            },
            {
              "name": "obs52",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "obs53",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            {
              "name": "obs54",
              "type": "text",
              "value": "",
              "is_std_value": true
            },
            
            
          ],
        ],
        "headings": [
          "Cycles",
          "S1",
          "U1",
          "U2",
          "S2",
          "Unit",
        ]
      }
    },
    "material_type": "Weight"
  },

  MedicalDevices: {
    "formFields": [
      {
        "name": "ParameterDisplayCatogry",
        "type": "select",
        "label": "ParameterDisplayCatogry",
        "value": "",
        "options": [
          "Obs 1"
        ],
        "data_attr": [
          "MedicalDevices"
        ]
      },
      {
        "name": "mode",
        "type": "select",
        "label": "Mode",
        "value": "",
        "options": [
          "Source Mode",
          "Measure Mode"
        ],
        "data_attr": [
          "MedicalDevices"
        ]
      }

    ],
    "ReadingStruct": {
      "OtherValues": [
        {
          "name": "Frequency",
          "type": "text",
          "label": "Frequency",
          "value": "",
          "data_attr": [
            "MedicalDevices"
          ]
        },
        {
          "name": "Frequency_unit",
          "type": "select",
          "label": "Frequency Unit",
          "value": "",
          "options": [
            "Obs 1"
          ],
          "data_attr": [
            "MedicalDevices"
          ],
          "is_unit_select": true
        }
      ],
      "TableStructure": {
        "rows": [
          [
            {
              "name": "STD Value",
              "type": "Heading",
              "value": ""
            },
            {
              "name": "STD_Value_1",
              "type": "text",
              "value": ""
            },
            {
              "name": "STD_Value_2",
              "type": "text",
              "value": ""
            },
            {
              "name": "STD_Value_3",
              "type": "text",
              "value": ""
            },
            {
              "name": "STD_Value_4",
              "type": "text",
              "value": ""
            },
            {
              "name": "STD_Value_5",
              "type": "text",
              "value": ""
            },
            {
              "name": "STD_Value_Unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "multiple": false,
              "is_unit_select": true
            }
          ],
          [
            {
              "name": "UUC Value",
              "type": "Heading",
              "value": ""
            },
            {
              "name": "Obs_Value_1",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_2",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_3",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_4",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_5",
              "type": "text",
              "value": ""
            },
            {
              "name": "obs_Value_Unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "multiple": false,
              "is_unit_select": true
            }
          ]
        ],
        "headings": [
          "Reading Type",
          "Value 1",
          "Value 2",
          "Value 3",
          "Value 4",
          "Value 5",
          "Unit",
        ]
      }
    },
    "material_type": "MedicalDevices",
    "ElectricalSafety": {
      "formFields": [
        {
          "name": "instu_used",
          "type": "select",
          "label": "Master Used",
          "value": "",
          "options": [
            "Obs 1"
          ],
          "multiple": true,
          "data_attr": [
            "MedicalDevices"
          ]
        },
      ],
      "TableStructure": {
        "rows": [
          [
            {
              "name": "parameter",
              "type": "select",
              "value": "",
              "options": ['Protective Earth Resistance (Ω)',
                'Earth Leakage Current NC (µA)', 'Earth Leakage Current SFC (µA)',
                'Enclosure Leakage Current NC (µA)', 'Enclosure Leakage Current SFC (µA)',
                'Patient Leakage Current NC (µA)', 'Patient Leakage Current SFC (µA)',
                'Insulation Resistance (MΩ)'],
              "multiple": false
            },
            {
              "name": "scope",
              "type": "select",
              "value": "",
              "options": [
                "Scope 1"
              ],
              "multiple": false
            },
            {
              "name": "least_count",
              "type": "text",
              "value": ""
            },
            {
              "name": "least_count_unit",
              "type": "select",
              "value": "",
              "options": [
                "Ω",
                "µA",
                "MΩ",
              ],
              "multiple": false,
              "is_unit_select": true
            },
            {
              "name": "Measured Value 1",
              "type": "text",
              "value": ""
            },
            {
              "name": "Measured Value 2",
              "type": "text",
              "value": ""
            },
            {
              "name": "Measured Value 3",
              "type": "text",
              "value": ""
            },
            {
              "name": "Measured Value 4",
              "type": "text",
              "value": ""
            },
            {
              "name": "Measured Value 5",
              "type": "text",
              "value": ""
            },
            {
              "name": "measured_Unit",
              "type": "select",
              "value": "",
              "options": [
                "Ω",
                "µA",
                "MΩ",
              ],
              "is_unit_select": true
            },
            {
              "name": "Remarks",
              "type": "text",
              "value": ""
            }
          ]
        ],
        "headings": [
          "Parameter",
          "Electrical Scope",
          "Least Count",
          "Least Count Unit",
          "Measured Value 1",
          "Measured Value 2",
          "Measured Value 3",
          "Measured Value 4",
          "Measured Value 5",
          "Unit",
          "Remarks",
        ]
      }
    }
  },

  WeighingBalance: {
    "formFields": [
     ],
    "ReadingStruct": {
      "OtherValues": [
        {
          "name": "nominalValue",
          "type": "text",
          "label": "Load Value",
          "value": "",
          "data_attr": [
            "WeighingBalance"
          ]
        },
        {
          "name": "nominalunit",
          "type": "select",
          "label": "Load Unit",
          "value": "",
          "options": [
            "Obs 1"
          ],
          "data_attr": [
            "WeighingBalance"
          ],
          "is_unit_select": true
        },
        { label: 'Select Mass', type: 'select', name: 'master_cert', value: [], options: ['Certificate 1', 'Certificate 2', 'Certificate 3'], multiple: true }
      ],
      "TableStructure": {
        "rows": [
          
          [
            {
              "name": "Observed Value",
              "type": "Heading",
              "value": ""
            },
            {
              "name": "no_load",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_1",
              "type": "text",
              "value": ""
            },
            {
              "name": "Obs_Value_2",
              "type": "text",
              "value": ""
            },
            {
              "name": "after_remove_load",
              "type": "text",
              "value": ""
            },
            {
              "name": "obs_Value_Unit",
              "type": "select",
              "value": "",
              "options": [
                "Select Load",
                "Low",
                "Medium",
                "High"
              ],
              "multiple": false,
              "is_unit_select": true
            }
          ]
        ],
        "headings": [
          "Reading Type",
          "No Load",
          "Value 1",
          "Value 2",
          "After Remove Load",
          "Unit",
        ]
      }
    },
    "material_type": "WeighingBalance",
    "ElectricalSafety": {
      "formFields": [
        {
          "name": "instu_used",
          "type": "select",
          "label": "Master Used",
          "value": "",
          "options": [
            "Obs 1"
          ],
          "multiple": true,
          "data_attr": [
            "MedicalDevices"
          ]
        },
      ],
      "TableStructure": {
        "rows": [
          [
            {
              "name": "parameter",
              "type": "select",
              "value": "",
              "options": ['Protective Earth Resistance (Ω)',
                'Earth Leakage Current NC (µA)', 'Earth Leakage Current SFC (µA)',
                'Enclosure Leakage Current NC (µA)', 'Enclosure Leakage Current SFC (µA)',
                'Patient Leakage Current NC (µA)', 'Patient Leakage Current SFC (µA)',
                'Insulation Resistance (MΩ)'],
              "multiple": false
            },
            {
              "name": "scope",
              "type": "select",
              "value": "",
              "options": [
                "Scope 1"
              ],
              "multiple": false
            },
            {
              "name": "least_count",
              "type": "text",
              "value": ""
            },
            {
              "name": "least_count_unit",
              "type": "select",
              "value": "",
              "options": [
                "Ω",
                "µA",
                "MΩ",
              ],
              "multiple": false,
              "is_unit_select": true
            },
            {
              "name": "Measured Value 1",
              "type": "text",
              "value": ""
            },
            {
              "name": "Measured Value 2",
              "type": "text",
              "value": ""
            },
            {
              "name": "Measured Value 3",
              "type": "text",
              "value": ""
            },
            {
              "name": "Measured Value 4",
              "type": "text",
              "value": ""
            },
            {
              "name": "Measured Value 5",
              "type": "text",
              "value": ""
            },
            {
              "name": "measured_Unit",
              "type": "select",
              "value": "",
              "options": [
                "Ω",
                "µA",
                "MΩ",
              ],
              "is_unit_select": true
            },
            {
              "name": "Remarks",
              "type": "text",
              "value": ""
            }
          ]
        ],
        "headings": [
          "Parameter",
          "Electrical Scope",
          "Least Count",
          "Least Count Unit",
          "Measured Value 1",
          "Measured Value 2",
          "Measured Value 3",
          "Measured Value 4",
          "Measured Value 5",
          "Unit",
          "Remarks",
        ]
      }
    }
  }
}
export const VisualForm = {
  material_type: "VisualInspection",
  formFields: [
    {
      label: "Visual Inspection",
      type: "text",
      name: "visual_inspection",
      value: "Physical Appearance of Device",
      data_attr: ["VisualInspection"],
    },
    {
      label: "Observation",
      type: "text",
      name: "observation",
      value: "OK",
      data_attr: ["VisualInspection"],
    },
    {
      label: "Acceptance",
      type: "text",
      name: "acceptance",
      value: "Pass",
      data_attr: ["VisualInspection"],
    },
    {
      label: "Remarks",
      type: "text",
      name: "remarks",
      value: "",
      data_attr: ["VisualInspection"],
    },
  ],
};
export const etsafetyParaUnits = {
  'Protective Earth Resistance (Ω)': 'Ω',
  'Earth Leakage Current NC (µA)': 'µA',
  'Earth Leakage Current SFC (µA)': 'µA',
  'Enclosure Leakage Current NC (µA)': 'µA',
  'Enclosure Leakage Current SFC (µA)': 'µA',
  'Patient Leakage Current NC (µA)': 'µA',
  'Patient Leakage Current SFC (µA)': 'µA',
  'Insulation Resistance (MΩ)': 'MΩ'
}
export const OtherDimensionFields = [
  { label: 'Partial S.C.E of external measuring jaws 1', type: 'text', name: 'external_measuring_jaws_1', value: '' },
  { label: 'Partial S.C.E of external measuring jaws 2', type: 'text', name: 'external_measuring_jaws_2', value: '' },
  { label: 'Partial S.C.E of external measuring jaws 3', type: 'text', name: 'external_measuring_jaws_3', value: '' },
  { label: 'Partial S.C.E of internal measuring jaws 1', type: 'text', name: 'internal_measuring_jaws_1', value: '' },
  { label: 'Partial S.C.E of internal measuring jaws 2', type: 'text', name: 'internal_measuring_jaws_2', value: '' },
  { label: 'Partial S.C.E of internal measuring jaws 3', type: 'text', name: 'internal_measuring_jaws_3', value: '' },
  { label: 'External Measuring Jaws Unit', type: 'select', name: 'external_measuring_jaws_unit', value: '', options: units, is_unit_select: true },
  { label: 'Internal Measuring Jaws Unit', type: 'select', name: 'internal_measuring_jaws_unit', value: '', options: units, is_unit_select: true },

  { label: 'SSE Depth Measuring Jaws 1', type: 'text', name: 'sse_depth_measuring_jaws_1', value: '' },
  { label: 'SSE Depth Measuring Jaws 2', type: 'text', name: 'sse_depth_measuring_jaws_2', value: '' },
  { label: 'SSE Depth Measuring Jaws 3', type: 'text', name: 'sse_depth_measuring_jaws_3', value: '' },
  { label: 'SSE Internal Measuring Jaws 1', type: 'text', name: 'sse_internal_measuring_jaws_1', value: '' },
  { label: 'SSE Internal Measuring Jaws 2', type: 'text', name: 'sse_internal_measuring_jaws_2', value: '' },
  { label: 'SSE Internal Measuring Jaws 3', type: 'text', name: 'sse_internal_measuring_jaws_3', value: '' },
  { label: 'SSE Depth Measuring Jaws Unit', type: 'select', name: 'sse_depth_measuring_jaws_unit', value: '', options: units, is_unit_select: true },
  { label: 'SSE Internal Measuring Jaws Unit', type: 'select', name: 'sse_internal_measuring_jaws_unit', value: '', options: units, is_unit_select: true },

  { label: 'LCE External Measuring Jaws 1', type: 'text', name: 'lce_external_measuring_jaws_1', value: '' },
  { label: 'LCE External Measuring Jaws 2', type: 'text', name: 'lce_external_measuring_jaws_2', value: '' },
  { label: 'LCE External Measuring Jaws 3', type: 'text', name: 'lce_external_measuring_jaws_3', value: '' },
  { label: 'LCE External Measuring Jaws Unit', type: 'select', name: 'lce_external_measuring_jaws_unit', value: '', options: units, is_unit_select: true },

  { label: 'Parallelism of Anvils', type: 'text', name: 'parallelism_of_anvils', value: '' },
  { label: 'Parallelism of Anvils Unit', type: 'select', name: 'parallelism_of_anvils_unit', value: '', options: units, is_unit_select: true },
  { label: 'Flatness of Anvils', type: 'text', name: 'flatness_of_anvils', value: '' },
  { label: 'Flatness of Anvils Unit', type: 'select', name: 'flatness_of_anvils_unit', value: '', options: units, is_unit_select: true },

  { label: 'Parallelism of Scriber CP 1', type: 'text', name: 'parallelism_of_scriber_CP_1', value: '' },
  { label: 'Parallelism of Scriber CP 2', type: 'text', name: 'parallelism_of_scriber_CP_2', value: '' },
  { label: 'Parallelism of Scriber CP 3', type: 'text', name: 'parallelism_of_scriber_CP_3', value: '' },
  { label: 'Parallelism of Scriber CP 4', type: 'text', name: 'parallelism_of_scriber_CP_4', value: '' },
  { label: 'Parallelism of Scriber Unit', type: 'select', name: 'parallelism_of_scriber_unit', value: '', options: units, is_unit_select: true },

  { label: 'Flatness 1', type: 'text', name: 'flatness_1', value: '' },
  { label: 'Flatness 2', type: 'text', name: 'flatness_2', value: '' },
  { label: 'Flatness 3', type: 'text', name: 'flatness_3', value: '' },

  { label: 'Parallelism 1', type: 'text', name: 'parallelism_1', value: '' },
  { label: 'Parallelism 2', type: 'text', name: 'parallelism_2', value: '' },
  { label: 'Parallelism 3', type: 'text', name: 'parallelism_3', value: '' },

  { label: 'Flatness Unit', type: 'select', name: 'flatness_unit', value: '', options: units, is_unit_select: true },
  { label: 'Parallelism Unit', type: 'select', name: 'parallelism_unit', value: '', options: units, is_unit_select: true },

  { label: 'Hysteresis', type: 'text', name: 'hystersis', value: '' },
  { label: 'Repeatability', type: 'text', name: 'repeatability', value: '' },
  { label: 'Hysteresis Unit', type: 'select', name: 'hystersis_unit', value: '', options: units, is_unit_select: true },
  { label: 'Repeatability Unit', type: 'select', name: 'repeatability_unit', value: '', options: units, is_unit_select: true },

  { label: 'Maximum Range', type: 'text', name: 'maximum_range', value: '' },
  { label: 'Maximum Range Unit', type: 'select', name: 'maximum_range_unit', value: '', options: units, is_unit_select: true },

  { label: 'Caliper remarks on certificate', type: 'text', name: 'caliper_remark', value: '' }
];

export function getDefaultForm(material_type) {
  const newStruct = {}
  newStruct["jsonStruct"] = forms[material_type]
  return newStruct
}

// Here we get the default field names for various material type
export function getRangField(material_type) {
  if (material_type === "Pressure" || material_type === "Speed") {
    return "nominal_value"
  }
  else if (material_type === "Dimension" || material_type === 'CommonDiscipline') {
    return "setValue"
  }

  else if (material_type === "ElectroTechnical") {
    return "rangeValue"
  }
  else if (material_type === "Thermal") {
    return "range_value"
  }
}