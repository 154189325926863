import { VBlockForm } from "../Common/VblockForm";


function roundHalfUp(valueToBeChanged, valueToBeCompared) {  //This function will return rounded half value +2 digit after lc value
    const decimalPlaces = (valueToBeCompared.toString().split('.')[1] || '').length;
    const plusTwoDigits = parseInt(decimalPlaces) + 2;
    const multiplier = Math.pow(10, plusTwoDigits);
    const roundedValue = Math.round(valueToBeChanged * multiplier) / multiplier;
    return roundedValue;
}

//Below Function will use for getting average of readings and std deviations , written by SHivam on 12/07/2024
export function calculateAverage(FormStructure, formData, formSectionIndex, sectionIndex, rowIndex) {
    const textFieldValues = [];
    const obsReadingData = []
    const stdReadingData = []
    const datadict = {}
    const row = FormStructure.ReadingStruct.TableStructure.rows[rowIndex];
    const least_count = formData.observation_data[formSectionIndex]?.master_tbl?.least_count
    if (row) {
        row.forEach(cell => {
            if (cell.type === 'text') {
                const value = parseFloat(formData.observation_data[formSectionIndex]?.readings_div?.[sectionIndex]?.[cell.name] || '');
                if (!isNaN(value)) {
                    textFieldValues.push(value);
                }
            }

        });
        // for every lines
        const sum = textFieldValues.reduce((acc, value) => acc + value, 0);
        const avg = textFieldValues.length > 0 ? sum / textFieldValues.length : 0;
        if (least_count && avg) {
            const roundedValue = roundHalfUp(avg, least_count);
            datadict["average"] = roundedValue
        }
        else {
            datadict["average"] = avg
        }
        const variance = textFieldValues.reduce((acc, value) => acc + Math.pow(value - avg, 2), 0) / textFieldValues.length;
        const stdDev = Math.sqrt(variance);
        datadict["standardDeviation"] = stdDev

    }
    FormStructure.ReadingStruct.TableStructure.rows.map(
        (row, rowIndex) => (
            row.map((cell, cellIndex) => {
                if (cell?.is_obs_reading) {
                    const value = parseFloat(formData.observation_data[formSectionIndex]?.readings_div?.[sectionIndex]?.[cell.name] || '');
                    if (!isNaN(value)) {
                        obsReadingData.push(value);
                    }
                }
                else if (cell?.is_std_value) {
                    const value = parseFloat(formData.observation_data[formSectionIndex]?.readings_div?.[sectionIndex]?.[cell.name] || '');
                    if (!isNaN(value)) {
                        stdReadingData.push(value);
                    }
                }
                return null; // Ensure to return something to avoid warnings in JSX
            })
        )
    )   
    const sum_obs = obsReadingData.reduce((acc, value) => acc + value, 0);
    const avg_obs = obsReadingData.length > 0 ? sum_obs / obsReadingData.length : 0;
    const variance_obs = obsReadingData.reduce((acc, value) => acc + Math.pow(value - avg_obs, 2), 0) / obsReadingData.length;
    const stdDev_obs = Math.sqrt(variance_obs);

    // for std readings only
    const sum_std = stdReadingData.reduce((acc, value) => acc + value, 0);
    const avg_std = stdReadingData.length > 0 ? sum_std / stdReadingData.length : 0;
    const variance_std = stdReadingData.reduce((acc, value) => acc + Math.pow(value - avg_std, 2), 0) / stdReadingData.length;
    const stdDev_std = Math.sqrt(variance_std);
    const updated_datadict = {
        ...datadict,
        obsReadingData: obsReadingData, avg_obs: avg_obs, stdDev_obs: stdDev_obs,
        stdReadingData: stdReadingData, avg_std: avg_std, stdDev_std: stdDev_std
    }
    return updated_datadict;
}


//Getting maximum value for V block

export function getMaximumValue(formData, recordIndex, index){
    let allFields_of_rows =   VBlockForm[recordIndex].formstructure[index]
    const lastField = allFields_of_rows[VBlockForm[recordIndex].formstructure[index].length-1]
    let array_of_row_readings = []
    let dataDict = {}
    const vBLockDataDict = formData.observation_data?.VBlockData?.[recordIndex]?.[index]
    if (vBLockDataDict){
        for (const [key, value] of Object.entries(vBLockDataDict)){
            if(!key.includes("max") && !key.includes("Maximum")){
                array_of_row_readings.push(value)
            }
        }
    }
    const max_value = Math.max(...array_of_row_readings)
    dataDict['max_value'] = max_value
    dataDict['max_field_name'] = lastField.name
    return dataDict
    
    
}



