import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Select from "react-select";
import { SpinnerObReading, SubmittingOverlay } from "../Common/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import { useUucData } from "../Utils/UucDataContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { json, useNavigate, useParams } from "react-router-dom";
import SearchablePaginatedTable from "../Common/SearchablePaginatedTable";
import GoToTopButton from "../Common/GoToTopButton";
import { Sidebar } from 'primereact/sidebar';
import Hamburger from 'hamburger-react'
import { isValidNumber } from "../Utils/checkValidNumber";
import { checkHaveMaster } from "../RequiredFields/ReqFields";
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
    VisualForm,
    getDefaultForm,
    etsafetyParaUnits,
    OtherDimensionFields,
} from "../Common/defaultForm";
import { DMMForm } from "../Common/dmmForm";
import { VBlockForm } from "../Common/VblockForm";

import { findCertificate, initialCertificatesOptionsDict } from "../Utils/getCertificate";

import { calculateAverage, getMaximumValue } from "../Utils/getMathData";

function ObsReading() {
    const token = localStorage.getItem("accessToke")
    const refresh = localStorage.getItem("refresh")
    const navigate = useNavigate();
    const { uucData, setUucData } = useUucData();
    const [material_type, setMaterialType] = useState(uucData.material_type);
    const srfId = uucData.srfId;
    const id = uucData.srfId;
    const userId = uucData.userId;
    const url = uucData.url;
    const [visible, setVisible] = useState(true);
    const [isDMMWithPressure, setDMMwithPressure] = useState(false)
    // start v block form
    const [isOpen, setIsOpen] = useState(false);
    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };
    //end v block form

    // Convert crud_fields string to an array
    const crudFields = uucData.crud_fields.split(",").map((field) => field.trim().replace(/['"]+/g, "")); //This fields exclud while rendering
    const [FormStructure, setFormStructure] = useState({});
    const [haveResponse, setHaveResponse] = useState(false);
    const [formSections, setFormSections] = useState([{ readings: [{ id: 0 }] }]);
    const [expandedFormIndex, setExpandedFormIndex] = useState(0);
    const [electricalSafetySections, setElectricalSafetySections] = useState([
        { readings: [{ id: 0 }] },
    ]);
    const [formData, setFormData] = useState({
        observation_data: {},
        other_info: {},
        evn_data: {},
    });
    const [visualInspectionRecords, setVisualInspectionRecords] = useState([
        { id: 0 },
    ]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const VisualInspectionForm = VisualForm;
    const [certItemsData, setCertItems] = useState({});
    const [haveInitalCertItem, sethaveInitalCertItem] = useState(false);
    const [initialCertiItems, setInitialCertItems] = useState({})

    // Cheking if cache is present for formData or not
    const cacheFormDataKey = `formData_${id}_${userId}_${material_type}_${uucData.schema}`;
    const getFormDataCache = useCallback(async () => {
        const cachedData = localStorage.getItem(cacheFormDataKey);
        if (cachedData) {
            const data = JSON.parse(cachedData);
            setFormData(data);
            if (data?.observation_data?.visualInspection) {
                setVisualInspectionRecords(data?.observation_data?.visualInspection)
            }
            if (data?.observation_data?.electricalSafety) {
                setElectricalSafetySections(
                    convertData(data?.observation_data?.electricalSafety)
                )
            }

            const obsData = JSON.parse(JSON.stringify(data.observation_data));
            delete obsData.electricalSafety;
            delete obsData.visualInspection;
            delete obsData.VBlockData;
            setFormSections(
                convertData(obsData)
            )

        }
    }, [material_type, srfId, userId]);

    useEffect(() => {
        getFormDataCache();
    }, [getFormDataCache, setFormData]);

    const cacheKey = `uucData_${id}_${userId}_${uucData.schema}`;
    const getUucData = useCallback(async () => {
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
            const data = JSON.parse(cachedData);
            setUucData(data);
            setMaterialType(data.material_type);
            if (material_type === 'Dimension' || material_type == 'Weight' || material_type == "CommonDiscipline" || material_type === 'WeighingBalance') {
                const initialCertData = initialCertificatesOptionsDict(uucData.master_instruments)
                setInitialCertItems(initialCertData)
                sethaveInitalCertItem(true)
            }
        }
    }, [material_type, srfId, userId, uucData.srfId, setUucData]);

    useEffect(() => {
        getUucData();
    }, [getUucData, setUucData]);

    const cacheFormStructureKey = `formStructure${material_type}_${userId}_${uucData.schema}`;
    const getFormStructure = useCallback(async () => {
        const cachedFormStructure = localStorage.getItem(cacheFormStructureKey);
        if (cachedFormStructure) {
            const formStructure = JSON.parse(cachedFormStructure);
            setFormStructure(formStructure.jsonStruct);
            setFormData((prevData) => ({
                ...prevData,
                other_info: { material_type, srfId: uucData.srfId },
            }));

            if (formStructure.previos_data) {
                const getcachedFormData = localStorage.getItem(cacheFormDataKey);
                if (getcachedFormData) {
                    const cacheFormData = JSON.parse(getcachedFormData);
                    setFormData(cacheFormData);
                    const obsData = JSON.parse(JSON.stringify(cacheFormData.observation_data));
                    delete obsData.electricalSafety;
                    delete obsData.visualInspection;
                    delete obsData.VBlockData;
                    setFormSections(convertData(obsData));
                } else {
                    setFormData(formStructure.previos_data);
                    const obsData = JSON.parse(JSON.stringify(formStructure.previos_data.observation_data));
                    delete obsData.electricalSafety;
                    delete obsData.visualInspection;
                    delete obsData.VBlockData;
                    setFormSections(
                        convertData(obsData)
                    );
                }
            }
            setHaveResponse(true);
        } else {
            try {
                let getResponse = await axios.post(`${url}/get_form_structure`, {
                    material_type: material_type,
                    srfId: uucData.srfId,
                },
                {
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                    }
          
                  });
                if (getResponse.status === 200) {
                    const { jsonStruct, previos_data } = getResponse.data;
                    setFormStructure(jsonStruct);
                    setFormData((prevData) => ({
                        ...prevData,
                        other_info: { material_type, srfId: uucData.srfId },
                    }));

                    if (previos_data) {
                        setFormData(previos_data);
                        if (previos_data?.observation_data?.visualInspection) {
                            setVisualInspectionRecords(previos_data?.observation_data?.visualInspection)
                        }
                        if (previos_data?.observation_data?.electricalSafety) {
                            setElectricalSafetySections(
                                convertData(previos_data?.observation_data?.electricalSafety)
                            )
                        }
                        const obsData = JSON.parse(JSON.stringify(previos_data.observation_data));
                        delete obsData.electricalSafety;
                        delete obsData.VBlockData;
                        delete obsData.visualInspection;
                        setFormSections(
                            convertData(obsData)
                        )
                        localStorage.setItem(
                            cacheFormDataKey,
                            JSON.stringify(previos_data)
                        );
                    }
                    setHaveResponse(true);
                    localStorage.setItem(
                        cacheFormStructureKey,
                        JSON.stringify(getResponse.data)
                    );
                } else if (getResponse.status === 206) {
                    const previos_data = getResponse.data.previos_data;
                    setFormData((prevData) => ({
                        ...prevData,
                        other_info: { material_type, srfId: uucData.srfId },
                    }));

                    if (previos_data) {
                        const defaultForm = getDefaultForm(material_type);
                        defaultForm["previos_data"] = previos_data;
                        setFormStructure(defaultForm.jsonStruct);
                        localStorage.setItem(
                            cacheFormStructureKey,
                            JSON.stringify(defaultForm)
                        );

                        setFormData(previos_data);

                        if (previos_data?.observation_data?.visualInspection) {
                            setVisualInspectionRecords(previos_data?.observation_data?.visualInspection)
                        }
                        if (previos_data?.observation_data?.electricalSafety) {
                            setElectricalSafetySections(
                                convertData(previos_data?.observation_data?.electricalSafety)
                            )
                        }
                        const obsData = JSON.parse(JSON.stringify(previos_data.observation_data));
                        delete obsData.electricalSafety;
                        delete obsData.visualInspection;
                        delete obsData.VBlockData;

                        setFormSections(
                            convertData(obsData)
                        )
                        localStorage.setItem(
                            cacheFormDataKey,
                            JSON.stringify(previos_data)
                        );
                    }
                    setHaveResponse(true);
                }
            } catch (error) {
                const defaultForm = getDefaultForm(material_type);
                setFormStructure(defaultForm.jsonStruct);
                setFormData((prevData) => ({
                    ...prevData,
                    other_info: { material_type, srfId: uucData.srfId },
                }));
                localStorage.setItem(
                    cacheFormStructureKey,
                    JSON.stringify(defaultForm)
                );
                setHaveResponse(true);
            }
        }
    }, [material_type, uucData.srfId, url]);

    useEffect(() => {
        getFormStructure();
    }, [material_type, srfId, userId]);

    function getCetItemsArray(index) { //This function only for weight and dimension
        let data = certItemsData[index];
        if (data) {
            return data;
        } else {
            return [];
        }
    }

    function handleTabClick(tabId, formSectionIndex, sectionIndex) {
        setActiveTab(tabId);
        const elementId = makeDivId(formSectionIndex, sectionIndex).substring(1);
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ block: "center", behavior: "smooth" });
        }
    }

    function makeDivId(formSectionIndex, sectionIndex) {
        return `#reading_div_${formSectionIndex}_${sectionIndex}`;
    }

    const handleAddRecord = (formIndex) => {
        setFormSections((prevSections) =>
            prevSections.map((section, index) => {
                if (index === formIndex) {
                    return {
                        ...section,
                        readings: [
                            ...(section.readings || [{ id: 0 }]),
                            { id: section.readings ? section.readings.length : 0 },
                        ],
                    };
                }
                return section;
            })
        );
    };

    const handleAddElectricalSafetyRecord = (formIndex) => {
        setElectricalSafetySections((prevSections) =>
            prevSections.map((section, index) => {
                if (index === formIndex) {
                    return {
                        ...section,
                        readings: [
                            ...(section.readings || []),
                            { id: section.readings ? section.readings.length : 0 },
                        ],
                    };
                }
                return section;
            })
        );
    };

    const handleAddVisualInspectionRecord = () => {
        setVisualInspectionRecords((prevRecords) => [
            ...prevRecords,
            { id: prevRecords.length },
        ]);
    };

    const handleAddRecordWithNewMaster = () => {
        setFormSections([...formSections, { readings: [{ id: 0 }] }]);
        setExpandedFormIndex(formSections.length);
    };

    const handleInputFocus = (formIndex, sectionIndex) => {
        setFormSections((prevSections) =>
            prevSections.map((section, index) => {
                if (index === formIndex) {
                    return {
                        ...section,
                        expandedReadingIndex: sectionIndex,
                    };
                }
                return {
                    ...section,
                    expandedReadingIndex: null,
                };
            })
        );
    };

    const handleChange = (e, formIndex, sectionIndex) => {
        const { name, value } = e.target;
        if (e.target.type === 'text') {
            if (!isValidNumber(value) && name != "channelJunction") {
                return
            }
        }
        if (material_type === 'Pressure') {
            if (name === 'currentValue' || name === 'voltage') {
                if (value) {
                    setDMMwithPressure(true)
                }
                else {
                    setDMMwithPressure(false)
                }
            }
        }
        if (name === 'least_count_unit') {
            handleLeastCountUnitChange(e, formIndex)
        }
        setFormData((prevData) => {
            const updatedData = { ...prevData };
            if (!updatedData.observation_data[formIndex]) {
                updatedData.observation_data[formIndex] = {
                    master_tbl: {},
                    readings_div: [],
                };
            }
            if (sectionIndex === undefined) {
                updatedData.observation_data[formIndex].master_tbl[name] = value;
            } else {
                if (
                    !updatedData.observation_data[formIndex].readings_div[sectionIndex]
                ) {
                    updatedData.observation_data[formIndex].readings_div[sectionIndex] =
                        {};
                }
                updatedData.observation_data[formIndex].readings_div[sectionIndex][
                    name
                ] = value;
            }
            console.log(uucData, 'this is updated data')
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };
    const handleLeastCountUnitChange = (e, formSectionIndex) => {
        const value = e.target.value;
        const updatedFormData = { ...formData };
        const currentSection = updatedFormData.observation_data[formSectionIndex];


        // Update all is_unit_select fields in the current form section
        FormStructure.formFields.forEach((field) => {
            if (field.is_unit_select && currentSection) {
                currentSection.master_tbl[field.name] = value;
            }
        });
        // if(!currentSection.readings_div[0]){
        //     currentSection.readings_div[0] = []
        // }
        currentSection?.readings_div?.forEach((reading, sectionIndex) => {
            FormStructure.ReadingStruct.OtherValues.forEach((field) => {
                if (field.is_unit_select) {
                    reading[field.name] = value;
                }
            });
            FormStructure.ReadingStruct.TableStructure.rows.forEach((row) => {
                row.forEach((cell) => {
                    if (cell.is_unit_select) {
                        reading[cell.name] = value;
                    }
                });
            });
        });

        setFormData(updatedFormData);
    };

    const handleSelectChangeSingle = (selectedOption, formIndex, name) => {
        const value = selectedOption ? selectedOption.value : null;
        setFormData((prevData) => {
            const updatedData = { ...prevData };
            if (!updatedData.observation_data[formIndex]) {
                updatedData.observation_data[formIndex] = {
                    master_tbl: {},
                    readings_div: [],
                };
            }
            updatedData.observation_data[formIndex].master_tbl[name] = value;
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };

    const handleSelectChange = (
        selectedOptions,
        formIndex,
        sectionIndex,
        name
    ) => {
        const value = selectedOptions
            ? selectedOptions.map((option) => option.value)
            : [];
        setFormData((prevData) => {
            const updatedData = { ...prevData };
            if (!updatedData.observation_data[formIndex]) {
                updatedData.observation_data[formIndex] = {
                    master_tbl: {},
                    readings_div: [],
                };
            }
            if (!updatedData.observation_data[formIndex].readings_div[sectionIndex]) {
                updatedData.observation_data[formIndex].readings_div[sectionIndex] = {};
            }
            updatedData.observation_data[formIndex].readings_div[sectionIndex][name] =
                value;
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };

    const handleElectricalSafetyChange = (e, formIndex, sectionIndex) => {
        const { name, value } = e.target;

        setFormData((prevData) => {
            const updatedData = { ...prevData };

            // Ensure the structure exists
            if (!updatedData.observation_data.electricalSafety) {
                updatedData.observation_data.electricalSafety = [];
            }
            if (!updatedData.observation_data.electricalSafety[formIndex]) {
                updatedData.observation_data.electricalSafety[formIndex] = {
                    master_tbl: {},
                    readings_div: [],
                };
            }

            if (sectionIndex === "master_change") {
                updatedData.observation_data.electricalSafety[formIndex].master_tbl[
                    name
                ] = value;
            } else {
                if (
                    !updatedData.observation_data.electricalSafety[formIndex]
                        .readings_div[sectionIndex]
                ) {
                    updatedData.observation_data.electricalSafety[formIndex].readings_div[
                        sectionIndex
                    ] = {};
                }
                updatedData.observation_data.electricalSafety[formIndex].readings_div[
                    sectionIndex
                ][name] = value;
                if (name === 'parameter') {
                    const currentSection = updatedData.observation_data.electricalSafety[formIndex].readings_div[sectionIndex];
                    FormStructure.ElectricalSafety.TableStructure.rows.forEach((row) => {
                        row.forEach((cell) => {
                            if (cell.is_unit_select) {
                                currentSection[cell.name] = etsafetyParaUnits[value];
                            }
                        });
                    });
                }
            }
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };
    const handleVisualInspectionChange = (e, recordIndex) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const updatedData = { ...prevData }
            if (!updatedData.observation_data.visualInspection) {
                updatedData.observation_data.electricalSafety = [];
            }
            if (!updatedData.observation_data.visualInspection) {
                updatedData.observation_data.visualInspection = [];
            }

            if (!updatedData.observation_data.visualInspection[recordIndex]) {
                updatedData.observation_data.visualInspection[recordIndex] = {};
            }
            updatedData.observation_data.visualInspection[recordIndex][name] = value

            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };
    const handleRepeatabilityTest = (e, recordIndex) => {
        const { name, value } = e.target;
        if (e.target.type === 'text') {
            if (!isValidNumber(value)) {
                return
            }
        }
        const readingType = e.target.getAttribute('data-type');
        setFormData((prevData) => {
            const updatedData = { ...prevData }
            if (!updatedData.repeatablity_data) {
                updatedData.repeatablity_data = {};
            }
            if (!updatedData.repeatablity_data[readingType]) {
                updatedData.repeatablity_data[readingType] = {};
            }
            if (!updatedData.repeatablity_data[readingType][recordIndex]) {
                updatedData.repeatablity_data[readingType][recordIndex] = {};
            }
            updatedData.repeatablity_data[readingType][recordIndex][name] = value

            // localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };
    const handleOffCentricTest = (e, recordIndex) => {
        const { name, value } = e.target;
        if (e.target.type === 'text') {
            if (!isValidNumber(value)) {
                return
            }
        }
        setFormData((prevData) => {
            const updatedData = { ...prevData }
            if (!updatedData.offcentrictest) {
                updatedData.offcentrictest = {};
            }
            if (!updatedData.offcentrictest[recordIndex]) {
                updatedData.offcentrictest[recordIndex] = {};
            }
            updatedData.offcentrictest[recordIndex][name] = value
            // localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };
    const handleVblockChange = (e, recordIndex, rowindex) => {
        const { name, value } = e.target;
        if (e.target.type === 'text') {
            if (!isValidNumber(value)) {
                return
            }
        }
        setFormData((prevData) => {
            const updatedData = { ...prevData }
            if (!updatedData.observation_data.VBlockData) {
                updatedData.observation_data.VBlockData = [];
            }
            if (!updatedData.observation_data.VBlockData) {
                updatedData.observation_data.VBlockData = [];
            }

            if (!updatedData.observation_data.VBlockData[recordIndex]) {
                updatedData.observation_data.VBlockData[recordIndex] = [];
            }
            if (!updatedData.observation_data.VBlockData[recordIndex][rowindex]) {
                updatedData.observation_data.VBlockData[recordIndex][rowindex] = {};
            }
            updatedData.observation_data.VBlockData[recordIndex][rowindex][name] = value
            const errorData = getMaximumValue(formData, recordIndex, rowindex)
            updatedData.observation_data.VBlockData[recordIndex][rowindex][errorData["max_field_name"]] = errorData["max_value"]
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));

            return updatedData;
        });
    };

    const handleDeleteRecord = (formIndex, sectionIndex) => {
        setFormSections((prevSections) =>
            prevSections.map((section, index) => {
                if (index === formIndex) {
                    const updatedReadings = section.readings.filter(
                        (_, i) => i !== sectionIndex
                    );
                    return {
                        ...section,
                        readings: updatedReadings,
                    };
                }
                return section;
            })
        );

        setFormData((prevData) => {
            const updatedData = { ...prevData };
            if (updatedData.observation_data[formIndex]) {
                updatedData.observation_data[formIndex].readings_div.splice(
                    sectionIndex,
                    1
                );
            }
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };

    const handleDeleteElectricalSafetyRecord = (formIndex, sectionIndex) => {
        setElectricalSafetySections((prevSections) =>
            prevSections.map((section, index) => {
                if (index === formIndex) {
                    const updatedReadings = section.readings.filter(
                        (_, i) => i !== sectionIndex
                    );
                    return {
                        ...section,
                        readings: updatedReadings,
                    };
                }
                return section;
            })
        );

        setFormData((prevData) => {
            const updatedData = { ...prevData };
            if (
                updatedData.observation_data.electricalSafety &&
                updatedData.observation_data.electricalSafety[formIndex]
            ) {
                updatedData.observation_data.electricalSafety[
                    formIndex
                ].readings_div.splice(sectionIndex, 1);
            }
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };

    const handleDeleteVisualInspectionRecord = (recordIndex) => {
        setVisualInspectionRecords((prevRecords) =>
            prevRecords.filter((_, index) => index !== recordIndex)
        );

        setFormData((prevData) => {
            const updatedData = { ...prevData };
            updatedData.observation_data.visualInspection.splice(recordIndex, 1);
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };
    const instrumentMasterOptions =
        Array.isArray(uucData.masters_used) && uucData.masters_used.length > 0
            ? uucData.masters_used.map((master) => ({
                value: master.id,
                label: `${master.display_name}`,
            }))
            : uucData.master_instruments.map((instrument) => ({
                value: instrument.id,
                label: `${instrument.display_name} - ${instrument.cert_no}`,
            }));

    const initialCertiItemsOption = haveInitalCertItem
        ? initialCertiItems.map((master) => ({
            value: master.id,
            label: `${master.value_std} ${master.value_std_unit}`,
        }))
        : [];

    const accesoriesMasterOptions = uucData.accessoriesMasters
        ? uucData.accessoriesMasters.map((master) => ({
            value: master.id,
            label: `${master.id} - ${master.instrument_name}`,
        }))
        : [];

    const electricalSafetyMastersOptions = uucData.electricalSafetyMasters
        ? uucData.electricalSafetyMasters.map((master) => ({
            value: master.id,
            label: `${master.id} - ${master.display_name}`,
        }))
        : [];
    const parameterdisplaycatogaryOption = uucData.parameterdisplaycatogary_objs
        ? uucData.parameterdisplaycatogary_objs.map((master) => ({
            value: master.id,
            label: `${master.id} - ${master.value}`,
        }))
        : [];
    const medicalEtScopeOptions = uucData.medicalEtScope
        ? uucData.medicalEtScope.map((master) => ({
            value: master.id,
            label: `${master.s_no} - ${master.discipline}- ${master.measurement_range}`,
        }))
        : [];

    const scopeOptions = uucData.scope_attached
        ? uucData.scope_attached.map((scope) => ({
            value: scope.id,
            label: `${scope.s_no}- ${scope.discipline} - ${scope.measurement_range}  `,
        }))
        : [];
    const modifiedScopeOptions = [
        { value: null, label: "Select" },
        ...scopeOptions,
    ];

    const typeOptions = uucData.measure_parameters
        ? uucData.measure_parameters.map((scope) => ({
            value: scope.parameter_name,
            label: `${scope.parameter_name}`,
        }))
        : [];

    const measureUnitOptions = uucData.measure_units
        ? uucData.measure_units.map((unit) => ({
            value: unit.unit_name,
            label: unit.unit_name,
        }))
        : [];
    const handleInstrumentMasterChange = (selectedOptions, formIndex) => {
        const values = selectedOptions
            ? selectedOptions.map((option) => option.value)
            : [];


        if (material_type === "Dimension" || material_type === "CommonDiscipline" || material_type === "WeighingBalance" || material_type === "Weight") {
            const allCertData = [];
            for (let i = 0; i < values.length; i++) {
                const certData = findCertificate(uucData.master_instruments, values[i]);
                for (let j = 0; j < certData.length; j++) {
                    allCertData.push(certData[j]);
                }
            }
            setCertItems({
                ...certItemsData,
                [formIndex]: allCertData,
            });
            sethaveInitalCertItem(false)
        }

        setFormData((prevData) => {
            const updatedData = { ...prevData };
            if (!updatedData.observation_data[formIndex]) {
                updatedData.observation_data[formIndex] = {
                    master_tbl: {},
                    readings_div: [],
                };
            }
            updatedData.observation_data[formIndex].master_tbl["instrument_master"] =
                values;
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };

    const handleMeasureUnitChange = (
        selectedOption,
        formIndex,
        sectionIndex,
        fieldName
    ) => {
        const value = selectedOption ? selectedOption.value : "";
        setFormData((prevData) => {
            const updatedData = { ...prevData };
            if (!updatedData.observation_data[formIndex]) {
                updatedData.observation_data[formIndex] = {
                    master_tbl: {},
                    readings_div: [],
                };
            }
            if (sectionIndex === undefined) {
                updatedData.observation_data[formIndex].master_tbl[fieldName] = value;
            } else {
                if (
                    !updatedData.observation_data[formIndex].readings_div[sectionIndex]
                ) {
                    updatedData.observation_data[formIndex].readings_div[sectionIndex] =
                        {};
                }
                updatedData.observation_data[formIndex].readings_div[sectionIndex][
                    fieldName
                ] = value;
            }
            localStorage.setItem(cacheFormDataKey, JSON.stringify(updatedData));
            return updatedData;
        });
    };

    const handleEnvironmentFormChange = (e) => {
        const { name, value } = e.target;
        if (e.target.type === 'text') {
            if (!isValidNumber(value)) {
                return
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            evn_data: {
                ...prevData.evn_data,
                [name]: value,
            },
        }));
        localStorage.setItem(cacheFormDataKey, JSON.stringify(formData));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        let updatedFormData = {
            ...formData,
            userId: uucData.userId, // Ensure userId is added here
            procedures_used: uucData.procedures_used,

        };
        const masterSelectedStatus = checkHaveMaster(updatedFormData)
        if (!masterSelectedStatus.status) {
            const jumpIndex = masterSelectedStatus.index;
            const element = document.getElementById(`master_id_${jumpIndex}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                element.querySelector(".select__control").style.border = "1px solid red"
                return null
            }
        }
        setIsSubmitting(true);
        const submitUrl = `${url}/SaveFormData?material_type=${material_type}`;

        try {
            const response = await axios.post(submitUrl, updatedFormData, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                }
      
              });
            if (response.status === 200) {
                toast.success("Form submitted successfully!");
                uucData["obsId"] = response.data.obsId;
                uucData["material_type"] = material_type;
                localStorage.setItem(cacheKey, JSON.stringify(uucData));
                navigate("/calibration-sheet");
            }
        } catch (error) {
            toast.error("Error submitting form.");
            console.error("Error submitting form:", error);
        } finally {
            setIsSubmitting(false);
        }
    };
    const convertData = (val) => {
        const newData = [];
        for (const [key, value] of Object.entries(val)) {
            const dataDict = {};
            const divCounterArray = [];
            let counter = 0;
            value?.readings_div?.forEach(() => {
                if (value.readings_div) {
                    const idDict = {};
                    idDict["id"] = counter;
                    divCounterArray.push(idDict);
                    counter += 1;
                }
            });
            dataDict["readings"] = divCounterArray;
            newData.push(dataDict);
        }
        return newData;
    };

    const handleFocus = (e) => {
        const tdElement = e.target.closest('td');
        if (tdElement) {
            tdElement.style.width = '20%';
        }
    };

    const handleBlur = (e) => {
        const tdElement = e.target.closest('td');
        if (tdElement) {
            tdElement.style.width = 'auto';
        }
    };


    const [activeTab, setActiveTab] = useState(null);

    return (
        <div>
            {isSubmitting && <SubmittingOverlay />} {/* Show spinner while submitting */}
            {haveResponse ? (
                <form onSubmit={handleSubmit} className="row">
                    <div className="col-sm-1 ms-3 mt-2  fixed-top" style={{ zIndex: '9999' }}>
                        <Hamburger toggled={visible} toggle={setVisible} />
                    </div>

                    <div className="col-sm-12 row ">
                        <div className="col-sm-3">
                            <Sidebar closeIcon='false' className="col-sm-3 scrollable-div-sidebar" visible={visible} onHide={() => setVisible(false)}>

                                <SearchablePaginatedTable
                                    formSections={formSections}
                                    formData={formData}
                                    calculateAverage={calculateAverage}
                                    makeDivId={makeDivId}
                                    handleTabClick={handleTabClick}
                                    activeTab={activeTab}
                                    FormStructure={FormStructure}
                                />
                            </Sidebar>
                        </div>
                        <div className={`transition-width ${visible ? 'col-sm-9' : 'col-sm-12'} scrollable-div myBoxes`} >

                            {/* environment form  */}
                            <div className="row card-body me-2 ms-3">

                                <div className={material_type === 'Thermal' || material_type === 'Dimension' || material_type === 'Weight' ? "col-md-8 mb-5 mt-3" : "col-md-12 mb-5 mt-3"} >
                                    <div className="card p-3">
                                        <h5 className="card-title font-weight-normal mt-3 mb-3">
                                            Temperature & Humidity
                                        </h5>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div className="form-group">
                                                    <div
                                                        id="div_id_temp1"
                                                        className="form-group bmd-form-group"
                                                    >
                                                        <label htmlFor="id_temp1" className="bmd-label-static">
                                                            Temperature Start <span style={{ "color": 'red' }}>**</span>
                                                        </label>
                                                        <div>
                                                            <input
                                                                required
                                                                type="text"
                                                                name="temp1"
                                                                className="only_num_class textinput form-control"
                                                                maxLength="600"
                                                                value={formData?.evn_data?.temp1}
                                                                id="id_temp1"
                                                                onChange={handleEnvironmentFormChange}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group">
                                                    <div
                                                        id="div_id_temp2"
                                                        className="form-group bmd-form-group"
                                                    >
                                                        <label htmlFor="id_temp2" className="bmd-label-static">
                                                            Temperature Middle
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="temp2"
                                                                maxLength="600"
                                                                value={formData?.evn_data?.temp2}
                                                                className="textinput form-control"
                                                                id="id_temp2"
                                                                onChange={handleEnvironmentFormChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group">
                                                    <div
                                                        id="div_id_temp3"
                                                        className="form-group bmd-form-group"
                                                    >
                                                        <label htmlFor="id_temp3" className="bmd-label-static">
                                                            Temperature End <span style={{ "color": 'red' }}>**</span>
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="temp3"
                                                                required
                                                                className="only_num_class textinput form-control"
                                                                maxLength="600"
                                                                id="id_temp3"
                                                                value={formData?.evn_data?.temp3}
                                                                onChange={handleEnvironmentFormChange}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group">
                                                    <div
                                                        id="div_id_rh1"
                                                        className='form-group bmd-form-group'
                                                    >
                                                        <label htmlFor="id_rh1" className="bmd-label-static">
                                                            Humidity Start <span style={{ "color": 'red' }}>**</span>
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                required
                                                                name="rh1"
                                                                className="only_num_class textinput form-control"
                                                                maxLength="600"
                                                                id="id_rh1"
                                                                value={formData?.evn_data?.rh1}
                                                                onChange={handleEnvironmentFormChange}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group">
                                                    <div
                                                        id="div_id_rh2"
                                                        className="form-group bmd-form-group"
                                                    >
                                                        <label htmlFor="id_rh2" className="bmd-label-static">
                                                            Rh Middle
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                name="rh2"
                                                                maxLength="600"
                                                                value={formData?.evn_data?.rh2}
                                                                className="textinput form-control"
                                                                id="id_rh2"
                                                                onChange={handleEnvironmentFormChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group">
                                                    <div
                                                        id="div_id_rh3"
                                                        className="form-group bmd-form-group"
                                                    >
                                                        <label htmlFor="id_rh3" className="bmd-label-static">
                                                            Humidity End <span style={{ "color": 'red' }}>**</span>
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                required
                                                                name="rh3"
                                                                className="only_num_class textinput form-control"
                                                                maxLength="600"
                                                                id="id_rh3"
                                                                value={formData?.evn_data?.rh3}
                                                                onChange={handleEnvironmentFormChange}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <div id="Remark" className="form-group bmd-form-group">
                                                        <label htmlFor="Remark" className="bmd-label-static">
                                                            Remark
                                                        </label>
                                                        <div>
                                                            <textarea
                                                                rows="2"
                                                                cols="50"
                                                                type="text"
                                                                name="remark"
                                                                className="only_num_class textinput form-control"
                                                                maxLength="2000"
                                                                id="remark"
                                                                value={formData?.evn_data?.remark}
                                                                onChange={handleEnvironmentFormChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {material_type === 'Thermal' || material_type === 'Dimension' || material_type ==="Weight" ? (
                                    <div className="col-sm-4  mb-5 mt-3">
                                        <div className="card p-3">
                                            <h5 className="card-title font-weight-normal mt-3 mb-3">
                                                Other Info
                                            </h5>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    {material_type === "Thermal" ? (
                                                        <div className="row">
                                                            <div
                                                                id="div_id_immertion"
                                                                className="form-group bmd-form-group col-sm-6"
                                                            >
                                                                <label
                                                                    htmlFor="id_immertion"
                                                                    className="bmd-label-static"
                                                                >
                                                                    Immertion
                                                                </label>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        name="immertion"
                                                                        className="only_num_class textinput form-control"
                                                                        maxLength="600"
                                                                        id="id_immertion"
                                                                        value={formData?.evn_data?.immertion}
                                                                        onChange={handleEnvironmentFormChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ) : material_type === 'Dimension' ? (
                                                        <div className="row">
                                                            <div
                                                                id="div_id_immertion"
                                                                className="form-group bmd-form-group col-sm-6"
                                                            >
                                                                <label
                                                                    htmlFor="id_immertion"
                                                                    className="bmd-label-static"
                                                                >
                                                                    Maximum Range
                                                                </label>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        name="maximum_range"
                                                                        className="only_num_class textinput form-control"
                                                                        maxLength="600"
                                                                        id="id_maximum_range"
                                                                        value={formData?.evn_data?.maximum_range}
                                                                        onChange={handleEnvironmentFormChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="div_id_immertion"
                                                                className="form-group bmd-form-group col-sm-6"
                                                            >
                                                                <label
                                                                    htmlFor="id_maximum_range_unit"
                                                                    className="bmd-label-static"
                                                                >
                                                                    Maximum Range Unit
                                                                </label>
                                                                <div>
                                                                    <select
                                                                        type="text"
                                                                        name="maximum_range_unit"
                                                                        className="only_num_class textinput form-control"
                                                                        maxLength="600"
                                                                        id="id_maximum_range_unit"
                                                                        value={formData?.evn_data?.maximum_range_unit}
                                                                        onChange={handleEnvironmentFormChange}
                                                                    >
                                                                        <option value="">Select Unit</option>
                                                                        {measureUnitOptions.map((option, i) => (
                                                                            <option key={i} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ) : material_type === "Weight"?(
                                                        <div className="row">
                                                        <div
                                                            id="div_id_immertion"
                                                            className="form-group bmd-form-group col-sm-6"
                                                        >
                                                            <label
                                                                htmlFor="id_immertion"
                                                                className="bmd-label-static"
                                                            >
                                                                Type/Shape
                                                            </label>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    name="type"
                                                                    className="only_num_class textinput form-control"
                                                                    maxLength="600"
                                                                    id="id_type"
                                                                    value={formData?.evn_data?.type}
                                                                    onChange={handleEnvironmentFormChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            id="div_id_immertion"
                                                            className="form-group bmd-form-group col-sm-6"
                                                        >
                                                            <label
                                                                htmlFor="id_immertion"
                                                                className="bmd-label-static"
                                                            >
                                                                Assumed Density
                                                            </label>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    name="assumed_density"
                                                                    className="only_num_class textinput form-control"
                                                                    maxLength="600"
                                                                    id="id_assumed_density"
                                                                    value={formData?.evn_data?.assumed_density}
                                                                    onChange={handleEnvironmentFormChange}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>  
                                                    ):null}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ) : null}

                            </div>

                            {material_type === "MedicalDevices" ? (
                                <div className="card col-sm-12 row m-auto">
                                    <h3>1.0 Visual Inspection of DUC</h3>
                                    <div className="col-sm-12">
                                        <table
                                            className="table parent_tbl table-bordered visual123"
                                            id="visual"
                                        >
                                            <thead className="text-center">
                                                <tr>
                                                    <th>S.No.</th>
                                                    <th>Visual Inspection of DUC:</th>
                                                    <th>Observation</th>
                                                    <th>Acceptance</th>
                                                    <th>Remarks</th>
                                                    <th>Action</th> {/* Add a column for the delete button */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {visualInspectionRecords.map((record, recordIndex) => (
                                                    <tr key={recordIndex} className="tbl_tr">
                                                        <td className="sno">
                                                            <span className="sn">{recordIndex + 1}</span>
                                                        </td>
                                                        {VisualInspectionForm.formFields.map((field, index) => (
                                                            <td key={index} className="bmd-form-group is-filled">
                                                                <div className="form-group">
                                                                    <label htmlFor={field.name}>{field.label}</label>
                                                                    <input
                                                                        type="text"
                                                                        name={field.name}
                                                                        value={formData?.observation_data?.visualInspection?.[recordIndex]?.[field.name] || field.value}

                                                                        onChange={(e) =>
                                                                            handleVisualInspectionChange(e, recordIndex)
                                                                        }
                                                                        className="textinput form-control"
                                                                        id={field.name}
                                                                    />
                                                                </div>
                                                            </td>
                                                        ))}
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() =>
                                                                    handleDeleteVisualInspectionRecord(recordIndex)
                                                                }
                                                            >
                                                                Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="col-sm-12 text-right">
                                            <button
                                                type="button"
                                                className="btn btn-success btn_1 text-white"
                                                onClick={handleAddVisualInspectionRecord}
                                            >
                                                Add This
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : uucData?.observation_item_type === "V-block" ? (
                                <div className="card col-sm-11 row m-auto mb-4">
                                    <div className="col-sm-12 text-center">
                                        <h5> V-Block Datasheet</h5>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className={`collapse-content ${isOpen ? 'open' : ''}`}>
                                            {VBlockForm.map((record, recordIndex) => (
                                                <table
                                                    className="table parent_tbl table-bordered"
                                                    style={{ width: '100%' }}
                                                    key={recordIndex}
                                                >
                                                    <tbody>
                                                        <div>
                                                            <h4>{record.heading}</h4>
                                                            {record.formstructure.map((fieldArray, index) => (
                                                                <tr key={index}>
                                                                    {fieldArray.map((field, fieldIndex) => (
                                                                        <td
                                                                            style={{ width: 'auto' }}
                                                                            key={fieldIndex}
                                                                            className="bmd-form-group is-filled"
                                                                        >
                                                                            <div className="form-group">
                                                                                <label htmlFor={field.name}>{field.label}</label>
                                                                                {field.type !== 'Heading' && !field.name.includes("max") && !field.name.includes("Maximum") ? (
                                                                                    <input
                                                                                        type="text"
                                                                                        name={field.name}
                                                                                        value={formData.observation_data?.VBlockData?.[recordIndex]?.[index]?.[field.name]}
                                                                                        onChange={(e) =>
                                                                                            handleVblockChange(e, recordIndex, index)
                                                                                        }
                                                                                        className="textinput form-control"
                                                                                        id={field.name}
                                                                                    />
                                                                                ) : (field.type !== 'Heading' && (field.name.includes("max") || field.name.includes("Maximum"))) ? (
                                                                                    <input
                                                                                        type="text"
                                                                                        name={field.name}
                                                                                        value={formData.observation_data?.VBlockData?.[recordIndex]?.[index]?.[field.name]}
                                                                                        onChange={(e) =>
                                                                                            handleVblockChange(e, recordIndex, index)
                                                                                        }
                                                                                        className="textinput form-control"
                                                                                        id={field.name}
                                                                                    />
                                                                                ) : null}
                                                                            </div>
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </div>
                                                    </tbody>
                                                </table>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 text-center">
                                        <button type="button" onClick={toggleCollapse} className="btn  btn-primary mb-3">
                                            {isOpen ? 'Collapse' : 'Show'}
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                            <div className="col-sm-12">
                                {material_type === 'WeighingBalance' ? (
                                    <h4>Linearity Test</h4>
                                ) :
                                    null}
                            </div>
                            {formSections.map((section, formSectionIndex) => (
                                <div key={formSectionIndex} className="col-sm-12 cycle_tbl">
                                    <div className="card">
                                        <div className="root_tbl">
                                            <div className="card-body cycle_tbl myBoxes">
                                                {/* Upper Form Area */}
                                                <div className="master_tbl">
                                                    <div className="row">
                                                        <div className="col-sm-12 row">
                                                            <div className="col-sm-6" id={`master_id_${formSectionIndex}`}>
                                                                <label
                                                                    htmlFor="instrument_master"
                                                                    className="bmd-label-static"
                                                                >
                                                                    Master Equipment Used <span style={{ 'color': 'red' }}>**</span>
                                                                </label>
                                                                <div>
                                                                    <Select
                                                                        name="instrument_master"
                                                                        isMulti
                                                                        options={instrumentMasterOptions}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        onChange={(selectedOptions) =>
                                                                            handleInstrumentMasterChange(
                                                                                selectedOptions,
                                                                                formSectionIndex
                                                                            )
                                                                        }
                                                                        value={instrumentMasterOptions.filter(
                                                                            (option) =>
                                                                                formData.observation_data[
                                                                                    formSectionIndex
                                                                                ]?.master_tbl?.instrument_master?.includes(
                                                                                    option.value
                                                                                )
                                                                        )}
                                                                    />
                                                                </div>

                                                            </div>
                                                            {material_type === 'ElectroTechnical' ? (<div className="col-sm-6">
                                                                <div>
                                                                    <label
                                                                        htmlFor="accessories_master"
                                                                        className=""
                                                                    >
                                                                        Accessories Masters
                                                                    </label>
                                                                    <select
                                                                        name="accessories_master"
                                                                        className="form-control"
                                                                        id="accessories_master"
                                                                        value={
                                                                            formData.observation_data[formSectionIndex]
                                                                                ?.master_tbl?.accessories_master || ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleChange(e, formSectionIndex)
                                                                        }
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {accesoriesMasterOptions.map((option, i) => (
                                                                            <option key={i} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>) : null}

                                                            <div className="col-sm-6 mt-2">
                                                                <label htmlFor="scope">Select Scope </label>
                                                                <Select
                                                                    name="scope"
                                                                    id="scope"
                                                                    // className="form-control"
                                                                    value={
                                                                        scopeOptions.find(
                                                                            (option) =>
                                                                                option.value ===
                                                                                formData.observation_data[
                                                                                    formSectionIndex
                                                                                ]?.master_tbl?.scope
                                                                        ) || ""
                                                                    }
                                                                    onChange={(selectedOption) =>
                                                                        handleSelectChangeSingle(
                                                                            selectedOption,
                                                                            formSectionIndex,
                                                                            "scope"
                                                                        )
                                                                    }
                                                                    options={modifiedScopeOptions}
                                                                />
                                                            </div>
                                                            {material_type !== 'Dimension' ? (
                                                                <div className="col-sm-6 mt-2">
                                                                    <div id="type" className="form-group">
                                                                        <label htmlFor="type" className="">
                                                                            {material_type === "MedicalDevices"
                                                                                ? "Parameter Range"
                                                                                : "Type"}
                                                                        </label>
                                                                        <div>
                                                                            <select
                                                                                name="type"
                                                                                className="select form-control"
                                                                                id="type"
                                                                                value={
                                                                                    formData.observation_data[
                                                                                        formSectionIndex
                                                                                    ]?.master_tbl?.type || ""
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleChange(e, formSectionIndex)
                                                                                }
                                                                            >
                                                                                <option style={{ maxWidth: 300 }} value="">
                                                                                    Select
                                                                                </option>
                                                                                {typeOptions.map((option, i) => (
                                                                                    <option key={i} value={option.value}>
                                                                                        {option.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : null}

                                                            <div className="col-sm-6">
                                                                <div
                                                                    id="div_id_least_count"
                                                                    className="form-group bmd-form-group"
                                                                >
                                                                    <label
                                                                        htmlFor="least_count"
                                                                        className="bmd-label-static"
                                                                    >
                                                                        Least count <span style={{ color: "red" }}>**</span>
                                                                    </label>
                                                                    <div className="form-group bmd-form-group">
                                                                        <input
                                                                            type="text"
                                                                            required
                                                                            name="least_count"
                                                                            className="only_num_class textinput form-control"
                                                                            maxLength={30}
                                                                            id="least_count"
                                                                            value={
                                                                                formData.observation_data[
                                                                                    formSectionIndex
                                                                                ]?.master_tbl?.least_count || ""
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleChange(e, formSectionIndex)
                                                                            }
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 least_count_unit_class">
                                                                <div
                                                                    id="div_id_least_count_unit"
                                                                    className="form-group bmd-form-group"
                                                                >
                                                                    <label htmlFor="least_count_unit" className="">
                                                                        Least count unit <span style={{ color: "red" }}>**</span>
                                                                    </label>
                                                                    <div >
                                                                        <select
                                                                            required
                                                                            name="least_count_unit"
                                                                            className="form-control"
                                                                            id="least_count_unit"
                                                                            value={
                                                                                formData.observation_data[
                                                                                    formSectionIndex
                                                                                ]?.master_tbl?.least_count_unit || ""
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleChange(e, formSectionIndex)
                                                                            }
                                                                        >
                                                                            <option value="">Select Unit</option>
                                                                            {measureUnitOptions.map((option, i) => (
                                                                                <option key={i} value={option.value}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            {FormStructure.formFields.map(
                                                                (field, index) =>
                                                                    !crudFields.includes(field.name) && (
                                                                        <div
                                                                            className={field?.is_required ? "requiredClass form-group col-md-2" : "form-group col-md-2"}
                                                                            key={index}
                                                                        >
                                                                            <label htmlFor={field.name}>
                                                                                {field.label}
                                                                            </label>
                                                                            {field.type === 'select' && field.name === 'ParameterDisplayCatogry' ? (
                                                                                <select
                                                                                    className="form-control"
                                                                                    required={field?.is_required ? true : false}
                                                                                    id={field.name}
                                                                                    name={field.name}
                                                                                    value={
                                                                                        formData.observation_data[
                                                                                            formSectionIndex
                                                                                        ]?.master_tbl?.[field.name] || ""
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleChange(e, formSectionIndex)
                                                                                    }
                                                                                    data-attr={field.data_attr}
                                                                                >
                                                                                    <option value="">Select Option</option>
                                                                                    {parameterdisplaycatogaryOption.map((option, i) => (
                                                                                        <option key={i} value={option.value}>
                                                                                            {option.label}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>

                                                                            ) : field.type === "text" ? (<input
                                                                                type="text"
                                                                                required={field?.is_required ? true : false}
                                                                                className="form-control"
                                                                                id={field.name}
                                                                                name={field.name}
                                                                                value={
                                                                                    formData.observation_data[
                                                                                        formSectionIndex
                                                                                    ]?.master_tbl?.[field.name] || ""
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleChange(e, formSectionIndex)
                                                                                }
                                                                                data-attr={field.data_attr}
                                                                            />
                                                                            ) : field.is_unit_select ? (
                                                                                <select
                                                                                    required={field?.is_required ? true : false}
                                                                                    className="form-control"
                                                                                    id={field.name}
                                                                                    name={field.name}
                                                                                    value={
                                                                                        formData.observation_data[
                                                                                            formSectionIndex
                                                                                        ]?.master_tbl?.[field.name] || ""
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleChange(e, formSectionIndex)
                                                                                    }
                                                                                    data-attr={field.data_attr}
                                                                                >
                                                                                    <option value="">Select Unit</option>
                                                                                    {measureUnitOptions.map((option, i) => (
                                                                                        <option key={i} value={option.value}>
                                                                                            {option.label}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            ) : (
                                                                                <select
                                                                                    className="form-control"
                                                                                    required={field?.is_required ? true : false}
                                                                                    id={field.name}
                                                                                    name={field.name}
                                                                                    value={
                                                                                        formData.observation_data[
                                                                                            formSectionIndex
                                                                                        ]?.master_tbl?.[field.name] || ""
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        handleChange(e, formSectionIndex)
                                                                                    }
                                                                                    data-attr={field.data_attr}
                                                                                >
                                                                                    <option value="">Select Option</option>
                                                                                    {field.options.map((option, i) => (
                                                                                        <option key={i} value={option}>
                                                                                            {option}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            )}
                                                                        </div>
                                                                    )
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                {/* Readings Area */}
                                                <div className="col-md-12 row">
                                                    <div className="col-md-4 text-center">
                                                        <h4>Other Factors</h4>
                                                    </div>
                                                    <div className="col-sm-8 text-center">
                                                        <h4>Readings</h4>
                                                    </div>
                                                </div>
                                                {section.readings.map((_, sectionIndex) => (
                                                    <div
                                                        key={sectionIndex}
                                                        id={`reading_div_${formSectionIndex}_${sectionIndex}`}
                                                        className="mt-2 col-md-12 readings_div"
                                                    >
                                                        {section.expandedReadingIndex === sectionIndex ? (
                                                            <div className="col-md-12 row div_focus">
                                                                <div className="col-md-4 row">
                                                                    {FormStructure.ReadingStruct.OtherValues.map(
                                                                        (field, index) =>
                                                                            !crudFields.includes(field.name) && (
                                                                                <div
                                                                                    className={field?.is_required ? "requiredClass form-group col-md-6" : "form-group col-md-6"}
                                                                                    key={index}
                                                                                >
                                                                                    <label htmlFor={field.name}>
                                                                                        {field.label}
                                                                                    </label>
                                                                                    {field.type === "text" ? (
                                                                                        <input
                                                                                            type="text"
                                                                                            required={field?.is_required ? true : false}
                                                                                            className="form-control"
                                                                                            id={field.name}
                                                                                            name={field.name}
                                                                                            data-type = {field?.entryType}
                                                                                            value={
                                                                                                formData.observation_data[
                                                                                                    formSectionIndex
                                                                                                ]?.readings_div?.[sectionIndex]?.[
                                                                                                field.name
                                                                                                ] || ""
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleChange(
                                                                                                    e,
                                                                                                    formSectionIndex,
                                                                                                    sectionIndex
                                                                                                )
                                                                                            }
                                                                                            data-attr={field.data_attr}
                                                                                        />
                                                                                    ) : field.is_unit_select ? (
                                                                                        <select
                                                                                            className="form-control"
                                                                                            required={field?.is_required ? true : false}
                                                                                            id={field.name}
                                                                                            name={field.name}
                                                                                            value={
                                                                                                formData.observation_data[
                                                                                                    formSectionIndex
                                                                                                ]?.readings_div?.[sectionIndex]?.[
                                                                                                field.name
                                                                                                ] || ""
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleChange(
                                                                                                    e,
                                                                                                    formSectionIndex,
                                                                                                    sectionIndex
                                                                                                )
                                                                                            }
                                                                                            data-attr={field.data_attr}
                                                                                        >
                                                                                            <option value="">
                                                                                                Select Unit
                                                                                            </option>
                                                                                            {measureUnitOptions.map(
                                                                                                (option, i) => (
                                                                                                    <option
                                                                                                        key={i}
                                                                                                        value={option.value}
                                                                                                    >
                                                                                                        {option.label}
                                                                                                    </option>
                                                                                                )
                                                                                            )}
                                                                                        </select>
                                                                                    ) : field.multiple &&
                                                                                        material_type !== "Dimension" &&
                                                                                        field.name !== "master_cert" ? (
                                                                                        <Select
                                                                                            isMulti
                                                                                            name={field.name}
                                                                                            options={field.options.map(
                                                                                                (option) => ({
                                                                                                    value: option,
                                                                                                    label: option,
                                                                                                })
                                                                                            )}
                                                                                            className="basic-multi-select"
                                                                                            classNamePrefix="select"
                                                                                            value={
                                                                                                formData.observation_data[
                                                                                                    formSectionIndex
                                                                                                ]?.readings_div?.[sectionIndex]?.[
                                                                                                    field.name
                                                                                                ]?.map((option) => ({
                                                                                                    value: option,
                                                                                                    label: option,
                                                                                                })) || []
                                                                                            }
                                                                                            onChange={(selectedOptions) =>
                                                                                                handleSelectChange(
                                                                                                    selectedOptions,
                                                                                                    formSectionIndex,
                                                                                                    sectionIndex,
                                                                                                    field.name
                                                                                                )
                                                                                            }
                                                                                            data-attr={field.data_attr}
                                                                                        />
                                                                                    ) : field.multiple &&
                                                                                        field.name === "master_cert" &&
                                                                                        (material_type === "Dimension" || material_type === "CommonDiscipline" || material_type === "WeighingBalance" || material_type === "Weight")
                                                                                        ? (
                                                                                            <Select
                                                                                                isMulti
                                                                                                name={field.name}
                                                                                                options={haveInitalCertItem ? (initialCertiItemsOption) : getCetItemsArray(
                                                                                                    formSectionIndex
                                                                                                ).map((cert) => ({
                                                                                                    value: cert.id,
                                                                                                    label: `${cert.value_std} ${cert.value_std_unit} - ${cert.caliper_details}`,
                                                                                                }))

                                                                                                }
                                                                                                className="basic-multi-select"
                                                                                                classNamePrefix="select"
                                                                                                value={haveInitalCertItem ? (initialCertiItems.filter((cert) =>
                                                                                                    formData.observation_data[
                                                                                                        formSectionIndex
                                                                                                    ]?.readings_div[
                                                                                                        sectionIndex
                                                                                                    ]?.master_cert?.includes(
                                                                                                        cert.id
                                                                                                    )
                                                                                                ).map((cert) => ({
                                                                                                    value: cert.id,
                                                                                                    label: `${cert.value_std} ${cert.value_std_unit} - ${cert.caliper_details}`,
                                                                                                }))) : getCetItemsArray(
                                                                                                    formSectionIndex
                                                                                                )
                                                                                                    .filter((cert) =>
                                                                                                        formData.observation_data[
                                                                                                            formSectionIndex
                                                                                                        ]?.readings_div[
                                                                                                            sectionIndex
                                                                                                        ]?.master_cert?.includes(
                                                                                                            cert.id
                                                                                                        )
                                                                                                    )
                                                                                                    .map((cert) => ({
                                                                                                        value: cert.id,
                                                                                                        label: `${cert.value_std} ${cert.value_std_unit} - ${cert.caliper_details}`,
                                                                                                    }))}
                                                                                                onChange={(selectedOptions) =>
                                                                                                    handleSelectChange(
                                                                                                        selectedOptions,
                                                                                                        formSectionIndex,
                                                                                                        sectionIndex,
                                                                                                        field.name
                                                                                                    )
                                                                                                }
                                                                                                data-attr={field.data_attr}
                                                                                            />
                                                                                        ) : (
                                                                                            <select
                                                                                                className="form-control"
                                                                                                required={field?.is_required ? true : false}
                                                                                                id={field.name}
                                                                                                name={field.name}
                                                                                                value={
                                                                                                    formData.observation_data[
                                                                                                        formSectionIndex
                                                                                                    ]?.readings_div?.[sectionIndex]?.[
                                                                                                    field.name
                                                                                                    ] || ""
                                                                                                }
                                                                                                onChange={(e) =>
                                                                                                    handleChange(
                                                                                                        e,
                                                                                                        formSectionIndex,
                                                                                                        sectionIndex
                                                                                                    )
                                                                                                }
                                                                                                data-attr={field.data_attr}
                                                                                            >
                                                                                                <option value="">Select</option>
                                                                                                {field.options.map((option, i) => (
                                                                                                    <option key={i} value={option}>
                                                                                                        {option}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        )}
                                                                                </div>
                                                                            )
                                                                    )}
                                                                </div>

                                                                {/* Reading structure */}
                                                                <div className="col-md-8 myBoxes row">
                                                                    <div className="col-sm-12 text-center">
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    {FormStructure.ReadingStruct?.TableStructure.headings?.map(
                                                                                        (heading, index) => (

                                                                                            <th
                                                                                                key={index}
                                                                                                style={{
                                                                                                    width:
                                                                                                        heading === "Unit"
                                                                                                            ? "15%"
                                                                                                            : "auto",
                                                                                                }}
                                                                                            >
                                                                                                {heading}
                                                                                            </th>

                                                                                        )
                                                                                    )}
                                                                                    <th>Avg</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {FormStructure.ReadingStruct.TableStructure.rows.map(
                                                                                    (row, rowIndex) => (
                                                                                        <tr key={rowIndex}>
                                                                                            {row.map(
                                                                                                (cell, cellIndex) =>
                                                                                                    (!crudFields.includes(cell.name)) && (
                                                                                                        <td
                                                                                                            className={cell?.is_required ? "requiredClass" : ""}
                                                                                                            key={cellIndex}
                                                                                                            style={{
                                                                                                                width:
                                                                                                                    cell.type === "Heading"
                                                                                                                        ? "25%"
                                                                                                                        : "auto",
                                                                                                            }}
                                                                                                        >
                                                                                                            {cell.type === "Heading" ? (
                                                                                                                <b>{cell.name}</b>
                                                                                                            ) : cell.type === "text" ? (
                                                                                                                <input
                                                                                                                    required={cell?.is_required ? true : false}
                                                                                                                    type="text"
                                                                                                                    className="form-control"
                                                                                                                    name={cell.name}
                                                                                                                    value={
                                                                                                                        formData
                                                                                                                            .observation_data[
                                                                                                                            formSectionIndex
                                                                                                                        ]?.readings_div?.[
                                                                                                                        sectionIndex
                                                                                                                        ]?.[cell.name] || ""
                                                                                                                    }
                                                                                                                    onChange={(e) =>
                                                                                                                        handleChange(
                                                                                                                            e,
                                                                                                                            formSectionIndex,
                                                                                                                            sectionIndex
                                                                                                                        )
                                                                                                                    }
                                                                                                                    data-attr={
                                                                                                                        cell.data_attr
                                                                                                                    }
                                                                                                                    onFocus={handleFocus}
                                                                                                                    onBlur={handleBlur}
                                                                                                                />
                                                                                                            ) : cell.is_unit_select ? (
                                                                                                                <select
                                                                                                                    required={cell?.is_required ? true : false}
                                                                                                                    className="form-control"
                                                                                                                    id={cell.name}
                                                                                                                    name={cell.name}
                                                                                                                    value={
                                                                                                                        formData
                                                                                                                            .observation_data[
                                                                                                                            formSectionIndex
                                                                                                                        ]?.readings_div?.[
                                                                                                                        sectionIndex
                                                                                                                        ]?.[cell.name] || ""
                                                                                                                    }
                                                                                                                    onChange={(e) =>
                                                                                                                        handleMeasureUnitChange(
                                                                                                                            {
                                                                                                                                value:
                                                                                                                                    e.target.value,
                                                                                                                                label:
                                                                                                                                    e.target
                                                                                                                                        .selectedOptions[0]
                                                                                                                                        .textContent,
                                                                                                                            },
                                                                                                                            formSectionIndex,
                                                                                                                            sectionIndex,
                                                                                                                            cell.name
                                                                                                                        )
                                                                                                                    }
                                                                                                                    data-attr={
                                                                                                                        cell.data_attr
                                                                                                                    }
                                                                                                                    onFocus={handleFocus}
                                                                                                                    onBlur={handleBlur}
                                                                                                                >
                                                                                                                    <option value="">
                                                                                                                        Select Unit
                                                                                                                    </option>
                                                                                                                    {measureUnitOptions.map(
                                                                                                                        (option, i) => (
                                                                                                                            <option
                                                                                                                                key={i}
                                                                                                                                value={
                                                                                                                                    option.value
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {option.label}
                                                                                                                            </option>
                                                                                                                        )
                                                                                                                    )}
                                                                                                                </select>
                                                                                                            ) : (
                                                                                                                <select
                                                                                                                    required={cell?.is_required ? true : false}
                                                                                                                    className="form-control"
                                                                                                                    id={cell.name}
                                                                                                                    name={cell.name}
                                                                                                                    value={
                                                                                                                        formData
                                                                                                                            .observation_data[
                                                                                                                            formSectionIndex
                                                                                                                        ]?.readings_div?.[
                                                                                                                        sectionIndex
                                                                                                                        ]?.[cell.name] || ""
                                                                                                                    }
                                                                                                                    onChange={(e) =>
                                                                                                                        handleMeasureUnitChange(
                                                                                                                            {
                                                                                                                                value:
                                                                                                                                    e.target.value,
                                                                                                                                label:
                                                                                                                                    e.target
                                                                                                                                        .selectedOptions[0]
                                                                                                                                        .textContent,
                                                                                                                            },
                                                                                                                            formSectionIndex,
                                                                                                                            sectionIndex,
                                                                                                                            cell.name
                                                                                                                        )
                                                                                                                    }
                                                                                                                    data-attr={
                                                                                                                        cell.data_attr
                                                                                                                    }
                                                                                                                    onFocus={handleFocus}
                                                                                                                    onBlur={handleBlur}
                                                                                                                >
                                                                                                                    <option value="">
                                                                                                                        Select
                                                                                                                    </option>
                                                                                                                    {cell.options.map(
                                                                                                                        (option, i) => (
                                                                                                                            <option
                                                                                                                                key={i}
                                                                                                                                value={option}
                                                                                                                            >
                                                                                                                                {option}
                                                                                                                            </option>
                                                                                                                        )
                                                                                                                    )}
                                                                                                                </select>
                                                                                                            )}
                                                                                                        </td>
                                                                                                    )
                                                                                            )}
                                                                                            <td>
                                                                                                {
                                                                                                    calculateAverage(
                                                                                                        FormStructure,
                                                                                                        formData,
                                                                                                        formSectionIndex,
                                                                                                        sectionIndex,
                                                                                                        rowIndex
                                                                                                    ).average
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                )}
                                                                                {(material_type === "Pressure" & isDMMWithPressure) && (
                                                                                    DMMForm.map(
                                                                                        (row, rowIndex) => (
                                                                                            <tr key={rowIndex}>
                                                                                                {row.map(
                                                                                                    (cell, cellIndex) =>
                                                                                                    (
                                                                                                        <td
                                                                                                            className={cell?.is_required ? "requiredClass" : ""}
                                                                                                            colSpan={cell.type === "Heading" && (2)}
                                                                                                            key={cellIndex}
                                                                                                            style={{
                                                                                                                width:
                                                                                                                    cell.type === "Heading"
                                                                                                                        ? "25%"
                                                                                                                        : "auto",
                                                                                                            }}
                                                                                                        >
                                                                                                            {cell.type === "Heading" ? (
                                                                                                                <b>{cell.name}</b>
                                                                                                            ) : cell.type === "text" ? (
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    required={cell?.is_required ? true : false}
                                                                                                                    className="form-control"
                                                                                                                    name={cell.name}
                                                                                                                    value={
                                                                                                                        formData
                                                                                                                            .observation_data[
                                                                                                                            formSectionIndex
                                                                                                                        ]?.readings_div?.[
                                                                                                                        sectionIndex
                                                                                                                        ]?.[cell.name] || ""
                                                                                                                    }
                                                                                                                    onChange={(e) =>
                                                                                                                        handleChange(
                                                                                                                            e,
                                                                                                                            formSectionIndex,
                                                                                                                            sectionIndex
                                                                                                                        )
                                                                                                                    }
                                                                                                                    data-attr={
                                                                                                                        cell.data_attr
                                                                                                                    }
                                                                                                                    onFocus={handleFocus}
                                                                                                                    onBlur={handleBlur}
                                                                                                                />
                                                                                                            ) : cell.is_unit_select ? (
                                                                                                                <select
                                                                                                                    required={cell?.is_required ? true : false}
                                                                                                                    className="form-control"
                                                                                                                    id={cell.name}
                                                                                                                    name={cell.name}
                                                                                                                    value={
                                                                                                                        formData
                                                                                                                            .observation_data[
                                                                                                                            formSectionIndex
                                                                                                                        ]?.readings_div?.[
                                                                                                                        sectionIndex
                                                                                                                        ]?.[cell.name] || ""
                                                                                                                    }
                                                                                                                    onChange={(e) =>
                                                                                                                        handleMeasureUnitChange(
                                                                                                                            {
                                                                                                                                value:
                                                                                                                                    e.target.value,
                                                                                                                                label:
                                                                                                                                    e.target
                                                                                                                                        .selectedOptions[0]
                                                                                                                                        .textContent,
                                                                                                                            },
                                                                                                                            formSectionIndex,
                                                                                                                            sectionIndex,
                                                                                                                            cell.name
                                                                                                                        )
                                                                                                                    }
                                                                                                                    data-attr={
                                                                                                                        cell.data_attr
                                                                                                                    }
                                                                                                                    onFocus={handleFocus}
                                                                                                                    onBlur={handleBlur}
                                                                                                                >
                                                                                                                    <option value="">
                                                                                                                        Select Unit
                                                                                                                    </option>
                                                                                                                    {measureUnitOptions.map(
                                                                                                                        (option, i) => (
                                                                                                                            <option
                                                                                                                                key={i}
                                                                                                                                value={
                                                                                                                                    option.value
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {option.label}
                                                                                                                            </option>
                                                                                                                        )
                                                                                                                    )}
                                                                                                                </select>
                                                                                                            ) : (
                                                                                                                <select
                                                                                                                    required={cell?.is_required ? true : false}
                                                                                                                    className="form-control"
                                                                                                                    id={cell.name}
                                                                                                                    name={cell.name}
                                                                                                                    value={
                                                                                                                        formData
                                                                                                                            .observation_data[
                                                                                                                            formSectionIndex
                                                                                                                        ]?.readings_div?.[
                                                                                                                        sectionIndex
                                                                                                                        ]?.[cell.name] || ""
                                                                                                                    }
                                                                                                                    onChange={(e) =>
                                                                                                                        handleMeasureUnitChange(
                                                                                                                            {
                                                                                                                                value:
                                                                                                                                    e.target.value,
                                                                                                                                label:
                                                                                                                                    e.target
                                                                                                                                        .selectedOptions[0]
                                                                                                                                        .textContent,
                                                                                                                            },
                                                                                                                            formSectionIndex,
                                                                                                                            sectionIndex,
                                                                                                                            cell.name
                                                                                                                        )
                                                                                                                    }
                                                                                                                    data-attr={
                                                                                                                        cell.data_attr
                                                                                                                    }
                                                                                                                    onFocus={handleFocus}
                                                                                                                    onBlur={handleBlur}
                                                                                                                >
                                                                                                                    <option value="">
                                                                                                                        Select
                                                                                                                    </option>
                                                                                                                    {cell.options.map(
                                                                                                                        (option, i) => (
                                                                                                                            <option
                                                                                                                                key={i}
                                                                                                                                value={option}
                                                                                                                            >
                                                                                                                                {option}
                                                                                                                            </option>
                                                                                                                        )
                                                                                                                    )}
                                                                                                                </select>
                                                                                                            )}
                                                                                                        </td>
                                                                                                    )
                                                                                                )}
                                                                                                <td>
                                                                                                    {
                                                                                                        calculateAverage(
                                                                                                            FormStructure,
                                                                                                            formData,
                                                                                                            formSectionIndex,
                                                                                                            sectionIndex,
                                                                                                            rowIndex
                                                                                                        ).average
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    )
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 text-right">
                                                                    
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-dark btn-sm"
                                                                        onClick={() => handleDeleteRecord(formSectionIndex, sectionIndex)}
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="collapsed-section">
                                                                <div className="col-md-12 row">
                                                                    <div className="col-md-4 row">
                                                                        <div className="form-group col-md-6">
                                                                            <label
                                                                                htmlFor={
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[0].name
                                                                                }
                                                                            >
                                                                                {
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[0].label
                                                                                }
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                onFocus={() => handleInputFocus(formSectionIndex, sectionIndex)}
                                                                                id={
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[0].name
                                                                                }
                                                                                name={
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[0].name
                                                                                }
                                                                                value={
                                                                                    formData.observation_data[
                                                                                        formSectionIndex
                                                                                    ]?.readings_div?.[sectionIndex]?.[
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[0].name
                                                                                    ] || ""
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleChange(
                                                                                        e,
                                                                                        formSectionIndex,
                                                                                        sectionIndex
                                                                                    )
                                                                                }
                                                                                data-attr={
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[0].data_attr
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label
                                                                                htmlFor={
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[1].name
                                                                                }
                                                                            >
                                                                                {
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[1].label
                                                                                }
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                onFocus={() => handleInputFocus(formSectionIndex, sectionIndex)}
                                                                                id={
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[1].name
                                                                                }
                                                                                name={
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[1].name
                                                                                }
                                                                                value={
                                                                                    formData.observation_data[
                                                                                        formSectionIndex
                                                                                    ]?.readings_div?.[sectionIndex]?.[
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[1].name
                                                                                    ] || ""
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleChange(
                                                                                        e,
                                                                                        formSectionIndex,
                                                                                        sectionIndex
                                                                                    )
                                                                                }
                                                                                data-attr={
                                                                                    FormStructure.ReadingStruct
                                                                                        .OtherValues[1].data_attr
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-8 myBoxes row">
                                                                        <div className="col-sm-12 text-center">
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        {FormStructure.ReadingStruct?.TableStructure?.headings?.map(
                                                                                            (heading, index) =>
                                                                                                !crudFields.includes(heading.name) && ((
                                                                                                    <th
                                                                                                        key={index}
                                                                                                        style={{
                                                                                                            width:
                                                                                                                heading === "Unit"
                                                                                                                    ? "15%"
                                                                                                                    : "auto",
                                                                                                        }}
                                                                                                    >
                                                                                                        {heading}
                                                                                                    </th>
                                                                                                )
                                                                                                )
                                                                                        )}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        {FormStructure.ReadingStruct.TableStructure.rows[0].map(
                                                                                            (cell, cellIndex) =>
                                                                                                !crudFields.includes(cell.name) && ((
                                                                                                    <td
                                                                                                        key={cellIndex}
                                                                                                        style={{
                                                                                                            width:
                                                                                                                cell.type === "Heading"
                                                                                                                    ? "25%"
                                                                                                                    : "auto",
                                                                                                        }}
                                                                                                    >
                                                                                                        {cell.type === "Heading" ? (
                                                                                                            <b>{cell.name}</b>
                                                                                                        ) : cell.type === "text" ? (
                                                                                                            <input
                                                                                                                required={cell?.is_required ? true : false}
                                                                                                                type="text"
                                                                                                                className="form-control"
                                                                                                                name={cell.name}
                                                                                                                value={
                                                                                                                    formData.observation_data[
                                                                                                                        formSectionIndex
                                                                                                                    ]?.readings_div?.[
                                                                                                                    sectionIndex
                                                                                                                    ]?.[cell.name] || ""
                                                                                                                }
                                                                                                                onFocus={() => handleInputFocus(formSectionIndex, sectionIndex)}
                                                                                                                onChange={(e) =>
                                                                                                                    handleChange(
                                                                                                                        e,
                                                                                                                        formSectionIndex,
                                                                                                                        sectionIndex
                                                                                                                    )
                                                                                                                }

                                                                                                                data-attr={cell.data_attr}
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <select
                                                                                                                required={cell?.is_required ? true : false}
                                                                                                                onFocus={() => handleInputFocus(formSectionIndex, sectionIndex)}
                                                                                                                className="form-control"
                                                                                                                id={cell.name}
                                                                                                                name={cell.name}
                                                                                                                value={
                                                                                                                    formData.observation_data[
                                                                                                                        formSectionIndex
                                                                                                                    ]?.readings_div?.[
                                                                                                                    sectionIndex
                                                                                                                    ]?.[cell.name] || ""
                                                                                                                }
                                                                                                                onChange={(e) =>
                                                                                                                    handleMeasureUnitChange(
                                                                                                                        {
                                                                                                                            value: e.target.value,
                                                                                                                            label:
                                                                                                                                e.target
                                                                                                                                    .selectedOptions[0]
                                                                                                                                    .textContent,
                                                                                                                        },
                                                                                                                        formSectionIndex,
                                                                                                                        sectionIndex,
                                                                                                                        cell.name
                                                                                                                    )
                                                                                                                }
                                                                                                                data-attr={cell.data_attr}
                                                                                                            >
                                                                                                                <option value="">
                                                                                                                    Select Unit
                                                                                                                </option>
                                                                                                                {measureUnitOptions.map(
                                                                                                                    (option, i) => (
                                                                                                                        <option
                                                                                                                            key={i}
                                                                                                                            value={option.value}
                                                                                                                        >
                                                                                                                            {option.label}
                                                                                                                        </option>
                                                                                                                    )
                                                                                                                )}
                                                                                                            </select>
                                                                                                        )}
                                                                                                    </td>

                                                                                                )
                                                                                                ))}
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 text-right">
                                                                   
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-dark btn-sm"
                                                                        onClick={() => handleDeleteRecord(formSectionIndex, sectionIndex)}
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-sm-12 text-right">
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark btn-sm  mr-2"
                                                onClick={handleAddRecordWithNewMaster}
                                            >
                                                ADD RECORD WITH NEW MASTER
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-dark btn-sm add_record_btn"
                                                onClick={() => handleAddRecord(formSectionIndex)}
                                            >
                                                ADD RECORD
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            ))}

                            {material_type === "Dimension" ? (
                                <div className="col-md-12 mb-5 mt-3">
                                    <div className="card p-3">
                                        <div className="row">
                                            {OtherDimensionFields.map(
                                                (field, index) =>
                                                    !crudFields.includes(field.name) && (
                                                        <div className="form-group col-md-3" key={index}>
                                                            <label htmlFor={field.name}>{field.label}</label>
                                                            {field.type === "text" ? (
                                                                <input
                                                                    type="text"
                                                                    required={field?.is_required ? true : false}
                                                                    className="form-control"
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    value={formData?.evn_data?.[field.name] || ""}
                                                                    onChange={handleEnvironmentFormChange}
                                                                    data-attr={field.data_attr}
                                                                />
                                                            ) : field.is_unit_select ? (
                                                                <select
                                                                    required={field?.is_required ? true : false}
                                                                    className="form-control"
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    value={formData?.evn_data?.[field.name]}
                                                                    onChange={handleEnvironmentFormChange}
                                                                    data-attr={field.data_attr}
                                                                >
                                                                    <option value="">Select Unit</option>
                                                                    {measureUnitOptions.map((option, i) => (
                                                                        <option key={i} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <select
                                                                    required={field?.is_required ? true : false}
                                                                    className="form-control"
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    value={formData?.evn_data?.[field.name]}
                                                                    onChange={handleEnvironmentFormChange}
                                                                    data-attr={field.data_attr}
                                                                >
                                                                    <option value="">Select Option</option>
                                                                    {field.options.map((option, i) => (
                                                                        <option key={i} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {material_type === "MedicalDevices" ? (
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className=" card-body">
                                            <div className="col-md-12">
                                                <h4>3.0 Electrical Safety</h4>
                                            </div>
                                            <div className="form-row">
                                                {electricalSafetySections.map(
                                                    (section, formSectionIndex) => (
                                                        <div key={formSectionIndex}>
                                                            <div className="col-md-4 row mb-4">
                                                                <label
                                                                    htmlFor="instu_used"
                                                                    className="bmd-label-static"
                                                                >
                                                                    Master Equipment Used <span style={{ 'color': 'red' }}>**</span>
                                                                </label>
                                                                <Select
                                                                    isMulti
                                                                    id="instu_used"
                                                                    name="instu_used"
                                                                    value={electricalSafetyMastersOptions.filter(
                                                                        (option) =>
                                                                            formData.observation_data.electricalSafety?.[
                                                                                formSectionIndex
                                                                            ]?.master_tbl?.instu_used?.includes(
                                                                                option.value
                                                                            )
                                                                    )}
                                                                    onChange={(selectedOptions) =>
                                                                        handleElectricalSafetyChange(
                                                                            {
                                                                                target: {
                                                                                    name: "instu_used",
                                                                                    value: selectedOptions.map(
                                                                                        (option) => option.value
                                                                                    ),
                                                                                },
                                                                            },
                                                                            formSectionIndex,
                                                                            "master_change"
                                                                        )
                                                                    }
                                                                    options={electricalSafetyMastersOptions.map(
                                                                        (option) => ({
                                                                            value: option.value,
                                                                            label: option.label,
                                                                        })
                                                                    )}
                                                                    data-attr="MedicalDevices"
                                                                />
                                                            </div>
                                                            {section.readings.map((_, sectionIndex) => (
                                                                <div key={sectionIndex} className="col-md-12 row">
                                                                    <table className="table text-center">
                                                                        <thead>
                                                                            <tr>
                                                                                {FormStructure.ElectricalSafety.TableStructure.headings.map(
                                                                                    (heading, index) => (
                                                                                        <th
                                                                                            key={index}
                                                                                        >
                                                                                            {heading}
                                                                                        </th>
                                                                                    )
                                                                                )}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {FormStructure.ElectricalSafety.TableStructure.rows.map(
                                                                                (row, rowIndex) => (
                                                                                    <tr key={rowIndex}>
                                                                                        {row.map((cell, cellIndex) => (
                                                                                            <td
                                                                                                key={cellIndex}

                                                                                            >
                                                                                                {cell.type === "Heading" ? (
                                                                                                    <b>{cell.name}</b>
                                                                                                ) : cell.type === "text" ? (
                                                                                                    <input
                                                                                                        onFocus={handleFocus}
                                                                                                        onBlur={handleBlur}
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        name={cell.name}
                                                                                                        value={
                                                                                                            formData.observation_data
                                                                                                                .electricalSafety?.[
                                                                                                                formSectionIndex
                                                                                                            ]?.readings_div?.[
                                                                                                            sectionIndex
                                                                                                            ]?.[cell.name] || ""
                                                                                                        }
                                                                                                        onChange={(e) =>
                                                                                                            handleElectricalSafetyChange(
                                                                                                                e,
                                                                                                                formSectionIndex,
                                                                                                                sectionIndex
                                                                                                            )
                                                                                                        }
                                                                                                        data-attr={cell.data_attr}
                                                                                                    />
                                                                                                ) : cell.name === "scope" ? (
                                                                                                    <Select
                                                                                                        id={cell.name}
                                                                                                        onFocus={handleFocus}
                                                                                                        onBlur={handleBlur}
                                                                                                        name={cell.name}
                                                                                                        value={
                                                                                                            medicalEtScopeOptions.find(
                                                                                                                (option) =>
                                                                                                                    option.value ===
                                                                                                                    formData
                                                                                                                        .observation_data
                                                                                                                        .electricalSafety?.[
                                                                                                                        formSectionIndex
                                                                                                                    ]?.readings_div?.[
                                                                                                                        sectionIndex
                                                                                                                    ]?.scope
                                                                                                            ) || ""
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            selectedOption
                                                                                                        ) => {
                                                                                                            handleElectricalSafetyChange(
                                                                                                                {
                                                                                                                    target: {
                                                                                                                        name: cell.name,
                                                                                                                        value:
                                                                                                                            selectedOption
                                                                                                                                ? selectedOption.value
                                                                                                                                : "",
                                                                                                                    },
                                                                                                                },
                                                                                                                formSectionIndex,
                                                                                                                sectionIndex
                                                                                                            );
                                                                                                        }}
                                                                                                        options={medicalEtScopeOptions.map(
                                                                                                            (option) => ({
                                                                                                                value: option.value,
                                                                                                                label: option.label,
                                                                                                            })
                                                                                                        )}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <select
                                                                                                        id={cell.name}
                                                                                                        name={cell.name}
                                                                                                        className=" form-control"
                                                                                                        onFocus={handleFocus}
                                                                                                        onBlur={handleBlur}
                                                                                                        onChange={(e) =>
                                                                                                            handleElectricalSafetyChange(
                                                                                                                e,
                                                                                                                formSectionIndex,
                                                                                                                sectionIndex
                                                                                                            )
                                                                                                        }
                                                                                                        value={formData.observation_data.electricalSafety?.[formSectionIndex]?.readings_div?.[sectionIndex]?.[cell.name]}
                                                                                                    >
                                                                                                        <option>Please choose one option</option>
                                                                                                        {cell.options.map((option, index) => {
                                                                                                            return (
                                                                                                                <option key={index} value={option}>
                                                                                                                    {option}
                                                                                                                </option>
                                                                                                            );
                                                                                                        })}
                                                                                                    </select>

                                                                                                )}
                                                                                            </td>
                                                                                        ))}
                                                                                        <td>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-dark btn-sm"
                                                                                                onClick={() =>
                                                                                                    handleDeleteElectricalSafetyRecord(
                                                                                                        formSectionIndex,
                                                                                                        sectionIndex
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Delete
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            ))}
                                                            <div className="row">
                                                                <div className="col-sm-12 text-right">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-dark btn-sm mr-2"
                                                                        onClick={() =>
                                                                            handleAddElectricalSafetyRecord(
                                                                                formSectionIndex
                                                                            )
                                                                        }
                                                                    >
                                                                        ADD RECORD
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {material_type === "WeighingBalance" ? (
                                <div>
                                    <div className="col-md-12 row">
                                        <div className="col-sm-12">
                                            <h4>Reapeatablity Of Balance</h4>
                                        </div>
                                        <div className="col-sm-6 text-center">
                                            <h5>Full Load</h5>
                                            <div className="col-sm-12 row mb-3">
                                                <div className="col-sm-1">
                                                    <b>Load</b>
                                                </div>
                                                <div className="col-sm-2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="full_load_value"
                                                        data-type="full_load"
                                                        onChange={(e) =>
                                                            handleRepeatabilityTest(e, "full_load_value")
                                                        }

                                                        value={
                                                            formData.repeatablity_data?.full_load?.["full_load_value"]?.full_load_value || ""
                                                        }
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <select
                                                        name="full_load_unit"
                                                        className="form-control"
                                                        id="full_load_unit"
                                                        data-type="full_load"
                                                        onChange={(e) =>
                                                            handleRepeatabilityTest(e, "full_load_value")
                                                        }

                                                        value={
                                                            formData.repeatablity_data?.full_load?.["full_load_value"]?.full_load_unit || ""
                                                        }
                                                    >
                                                        <option value="">Select Unit</option>
                                                        {measureUnitOptions.map((option, i) => (
                                                            <option key={i} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <table>
                                                <thead>
                                                    <th>
                                                        Sr. No.
                                                    </th>
                                                    <th>
                                                        Load On Pan (0)
                                                    </th>
                                                    <th>
                                                        Reading
                                                    </th>
                                                    <th>
                                                        Load On Pan (O)
                                                    </th>
                                                </thead>
                                                <tbody>

                                                    {
                                                        Array.from({ length: 10 }, (_, i) => (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="load_on_pan_inital_value"
                                                                        data-type="full_load"
                                                                        onChange={(e) =>
                                                                            handleRepeatabilityTest(e, i)
                                                                        }

                                                                        value={
                                                                            formData.repeatablity_data?.full_load?.[i]?.load_on_pan_inital_value || ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="reading"
                                                                        data-type="full_load"
                                                                        onChange={(e) =>
                                                                            handleRepeatabilityTest(e, i)
                                                                        }
                                                                        value={
                                                                            formData.repeatablity_data?.full_load?.[i]?.reading || ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        data-type="full_load"
                                                                        className="form-control"
                                                                        name="load_on_pan_after_value"
                                                                        onChange={(e) =>
                                                                            handleRepeatabilityTest(e, i)
                                                                        }
                                                                        value={
                                                                            formData.repeatablity_data?.full_load?.[i]?.load_on_pan_after_value || ""
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>


                                            </table>
                                        </div>
                                        <div className="col-sm-6 text-center">
                                            <h5>Half Load</h5>
                                            <div className="col-sm-12 row mb-3">
                                                <div className="col-sm-1">
                                                    <b>Load</b>
                                                </div>
                                                <div className="col-sm-2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="half_load_value"
                                                        data-type="half_load"
                                                        onChange={(e) =>
                                                            handleRepeatabilityTest(e, "half_load_value")
                                                        }

                                                        value={
                                                            formData.repeatablity_data?.half_load?.["half_load_value"]?.half_load_value || ""
                                                        }
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <select
                                                        name="half_load_unit"
                                                        className="form-control"
                                                        id="half_load_unit"
                                                        data-type="half_load"
                                                        onChange={(e) =>
                                                            handleRepeatabilityTest(e, "half_load_value")
                                                        }

                                                        value={
                                                            formData.repeatablity_data?.half_load?.["half_load_value"]?.half_load_unit || ""
                                                        }
                                                    >
                                                        <option value="">Select Unit</option>
                                                        {measureUnitOptions.map((option, i) => (
                                                            <option key={i} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <table>
                                                <thead>
                                                    <th>
                                                        Sr. No.
                                                    </th>
                                                    <th>
                                                        Load On Pan (0)
                                                    </th>
                                                    <th>
                                                        Reading
                                                    </th>
                                                    <th>
                                                        Load On Pan (O)
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Array.from({ length: 10 }, (_, i) => (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="load_on_pan_inital_value"
                                                                        data-type="half_load"
                                                                        onChange={(e) =>
                                                                            handleRepeatabilityTest(e, i)
                                                                        }

                                                                        value={
                                                                            formData.repeatablity_data?.half_load?.[i]?.load_on_pan_inital_value || ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="reading"
                                                                        data-type="half_load"
                                                                        onChange={(e) =>
                                                                            handleRepeatabilityTest(e, i)
                                                                        }
                                                                        value={
                                                                            formData.repeatablity_data?.half_load?.[i]?.reading || ""
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        data-type="half_load"
                                                                        className="form-control"
                                                                        name="load_on_pan_after_value"
                                                                        onChange={(e) =>
                                                                            handleRepeatabilityTest(e, i)
                                                                        }
                                                                        value={
                                                                            formData.repeatablity_data?.half_load?.[i]?.load_on_pan_after_value || ""
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                </tbody>


                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 row mb-5">
                                        <div className="col-sm-12 mt-4">
                                            <h4>Off-Centric Loading</h4>
                                        </div>
                                        <div className="col-sm-2 text-center mt-4">
                                            <table>
                                                <thead>
                                                    <th>
                                                        Load
                                                    </th>
                                                    <th>
                                                        Unit
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <tr key={0}>
                                                        <td >
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="eccentricy_load"
                                                                onChange={(e) =>
                                                                    handleOffCentricTest(e, 0)
                                                                }

                                                                value={
                                                                    formData.offcentrictest?.[0]?.eccentricy_load || ""
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <select
                                                                name="eccentricy_load_unit"
                                                                className="form-control"
                                                                id="eccentricy_load_unit"
                                                                onChange={(e) =>
                                                                    handleOffCentricTest(e, 0)
                                                                }

                                                                value={
                                                                    formData.offcentrictest?.[0]?.eccentricy_load_unit || ""
                                                                }
                                                            >
                                                                <option value="">Select Unit</option>
                                                                {measureUnitOptions.map((option, i) => (
                                                                    <option key={i} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-sm-10 text-center mt-4">
                                            <table>
                                                <thead>
                                                    <th colSpan={6} className="text-center">
                                                        Readings
                                                    </th>
                                                </thead>
                                                <tr key={1}>
                                                    <td>
                                                        <input
                                                            placeholder="Position 0"
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_0"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 1)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[1]?.off_centric_0 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_1"
                                                            placeholder="Position 1"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 1)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[1]?.off_centric_1 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_2"
                                                            placeholder="Position 2"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 1)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[1]?.off_centric_2 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_3"
                                                            placeholder="Position 3"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 1)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[1]?.off_centric_3 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_4"
                                                            placeholder="Position 4"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 1)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[1]?.off_centric_4 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_0_origin"
                                                            placeholder="Position 0"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 1)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[1]?.off_centric_0_origin || ""
                                                            }

                                                        />
                                                    </td>
                                                </tr>
                                                <tr key={2}>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_4"
                                                            placeholder="Position 4"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 2)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[2]?.off_centric_4 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_3"
                                                            placeholder="Position 3"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 2)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[2]?.off_centric_3 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_2"
                                                            placeholder="Position 2"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 2)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[2]?.off_centric_2 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_1"
                                                            placeholder="Position 1"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 2)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[2]?.off_centric_1 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text "
                                                            className="form-control"
                                                            name="off_centric_0"
                                                            placeholder="Position 0"
                                                            onChange={(e) =>
                                                                handleOffCentricTest(e, 2)
                                                            }

                                                            value={
                                                                formData.offcentrictest?.[2]?.off_centric_0 || ""
                                                            }

                                                        />
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td>

                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            ) : null}


                            <div className="col-sm-7 text-right mb-2 sticky-bottom">
                                <button type="submit" className="btn btn-success btn">
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </div>


                </form>
            ) : (
                <SpinnerObReading />
            )}
            <ToastContainer />
            <GoToTopButton />
        </div>
    );
}

export default ObsReading;
