
// import './App.css';
import DucInfo from './Componants/OneObservation/DucInfo';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import Header from './Componants/Layouts/Header';
import CalibraionProcedure from './Componants/OneObservation/CalibrationProcedure';
import ObsReading from './Componants/OneObservation/ObsReading';
import Certification from './Componants/OneObservation/Certification';
import JsonEditor from './Componants/Utils/EditJson';
import { UucDataProvider } from './Componants/Utils/UucDataContext';
import FieldVisibilityForm from './Componants/Utils/EditCrudFields';
import ToolBar from './Componants/Layouts/ToolBar';
import ObservationSheet from './Componants/OneObservation/ObservationSheet';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      
        <UucDataProvider>
          <Header />
          <Routes>
            {/* <Route path="/:id/:userId/:hash_schema" element={<DucInfo />}/> */}
            <Route path="/" element={<DucInfo />} />
            <Route path="calibration-procedure" element={<CalibraionProcedure />}/>
            <Route path="calibration-readings" element={<ObsReading />}/>
            <Route path="calibration-sheet" element={<ObservationSheet />}/>
            <Route path="calibration-certifications" element={<Certification />}/>
            <Route path="edit-form-json" element={<JsonEditor/>}/>
            <Route path="edit-crud-json" element={<FieldVisibilityForm/>}/>
          </Routes>
          <ToolBar/>
        </UucDataProvider>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
