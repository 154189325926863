
import { Domain, url } from "../Url/BaseUrl";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUucData } from "../Utils/UucDataContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Certification() {
    const token = localStorage.getItem("accessToke")
    const refresh = localStorage.getItem("refresh")
    const [formData, setFormData] = useState({
        ulr_number: '',
        leastcount: '',
        material_type: '',
        condition_of_uuc: '',
        uuc_location: '',
        calibrated_on: '',
        valid_upto: '',
        certificate_no: '',
        created_on: '',
        procedure_no: '',
        ref_std: '',
        temperature: '',
        operating_range: '',
        humidity: '',
        weight_density: '',
        accuracy: '',
        total_pages_on_obs: '',
        error_digits: '',
        uuc_received: '',
        received_on: '',
        cert_issue_date: '',
        range: '',
        make_model: '',
        serial_no: '',
        party_id: '',
        location: '',
        bar_code: '',
        uncertaintiy_digits: '',
        after_conversion_digits: '',
        calibration_engineer_sign: '',
        calibration_technical_manager: '',
        approved_by: '',
        uuc_image: null,
        all_users: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const [certGenerated, setCertGenerated] = useState(false);
    const { uucData } = useUucData();
    const [obsUrl, setObsUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [errorDetails, setErrorDetails] = useState(null);
    const [errorHtml, setErrorHtml] = useState(null);
    const [submitting, setSubmitting] = useState(false); // Add a state for form submission
    const [havetemplate, setHavetemplate] = useState(true);
    const [legacyUrl, setLegacyUrl] = useState(null);
    const id = uucData.obsId;
    const url = uucData.url;
    useEffect(() => {
        const fetchObsData = async () => {
            try {
                const response = await axios.get(`${url}/GenerateCertificate/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }


                });
                setFormData(response.data);
            } catch (error) {
                console.error('Error fetching observation data:', error);
            }
        };
        fetchObsData();
    }, [id, uucData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const submitType = e.nativeEvent.submitter.name;
        setSubmitting(true); // Set submitting to true when form is submitted

        const form = new FormData();
        for (const [key, value] of Object.entries(formData)) {
            form.append(key, value);
        }
        form.append('submitType', submitType);

        try {
            const response = await axios.post(`${url}/GenerateCertificate/${id}`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                }

            });
            setCertGenerated(true);
            if (!response.data.haveTemplate) {
                setHavetemplate(false)
                setLegacyUrl(`${Domain(uucData.schema)}${response.data.legacyUrl}`)
                setObsUrl(null);
                setError(null); // Clear any previous errors
                setErrorDetails(null); // Clear any previous error details
                setErrorHtml(null); // Clear any previous error HTML
            }
            else {
                const url_blob = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                setObsUrl(url_blob);
            }
            toast.success('Success!');
        } catch (error) {
            toast.error('Something went wrong!');
        } finally {
            setSubmitting(false);

        }
    };
    const usersOptions = formData.all_users
        ? formData.all_users.map((user) => ({
            value: user.id,
            label: `${user.name_on_print_docs} `,
        }))
        : [];

    useEffect(() => {
        const fetchPdf = async () => {
            try {

                const response = await axios.post(`${url}/GenerateCertificate/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }

                });
                if (!response.data.haveTemplate) {
                    setHavetemplate(false)
                    setLegacyUrl(`${Domain(uucData.schema)}${response.data.legacyUrl}`)
                    setObsUrl(null);
                }
                else {

                    const url_blob = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                    setObsUrl(url_blob);
                    setError(null); // Clear any previous errors
                    setErrorDetails(null); // Clear any previous error details
                    setErrorHtml(null); // Clear any previous error HTML
                }

            } catch (error) {
                setError('Error fetching PDF from draft_api');
                if (error.response) {
                    const details = {
                        status: error.response.status,
                        statusText: error.response.statusText,
                        headers: error.response.headers,
                        data: error.response.data
                    };
                    setErrorDetails(details);

                    if (typeof details.data === 'string' && details.data.startsWith('<')) {
                        setErrorHtml(details.data); // Assume it's HTML if it starts with a '<'
                    }
                } else {
                    console.error('Error fetching PDF:', error);
                    setErrorDetails('An unknown error occurred.');
                }
                console.error('Error fetching PDF:', error);
            }
            setLoading(false);
        };

        fetchPdf();
    }, [id, uucData]);

    return (
        <div className="col-sm-12 row ">
            <div className="pdf-container col-sm-6 card">
                {loading || submitting ? (
                    <div className="spinner-container">
                        <div className="spinner-border"></div>
                        <div> &nbsp; loading sheet..</div>

                    </div>
                ) : error ? (
                    <div className=" col-sm-12" style={{'maxHeight':'100vh'}}>
                        {errorDetails && (
                            <div style={{ whiteSpace: 'pre-wrap', backgroundColor: '#f0f0f0', padding: '10px', marginTop: '10px' }}>
                                <h4>Error Details:</h4>
                                <p><strong>Status:</strong> {errorDetails.status} {errorDetails.statusText}</p>
                                <p><strong>Headers:</strong> {JSON.stringify(errorDetails.headers, null, 2)}</p>
                            </div>
                        )}
                        {errorHtml && (
                            <div style={{ border: '1px solid #ccc', marginTop: '10px', padding: '10px', maxHeight: "720px"}}>
                                <h4>Error Preview:</h4>
                                <div dangerouslySetInnerHTML={{ __html: errorHtml }} />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="pdf-preview card-body">
                        {obsUrl ? (
                            <iframe src={obsUrl} width="100%" height="600px" title="Observation PDF Preview" />
                        ) : legacyUrl ? (
                            <div className="col-sm-12 text-center">
                                <a target="__blank" href={legacyUrl}>View Certifcate</a>
                            </div>
                        ) :
                            (
                                <p>No Observation PDF available</p>
                            )}
                    </div>
                )}
            </div>
            <div className="col-sm-6 card">
                <h3 className="mt-3 ml-2"><strong>Generate Certificate</strong></h3>
                <div className="card-body">
                    <form onSubmit={handleSubmit} className="row col-sm-12">
                        <div className="form-group col-sm-6">
                            <label htmlFor="ulr_number">ULR No</label>
                            <input type="text" className="form-control" id="ulr_number" onChange={handleChange} value={formData?.ulr_number} name="ulr_number" />
                        </div>
                        <div className="form-group col-sm-6">
                            <label htmlFor="leastcount">Least Count</label>
                            <input type="text" className="form-control" id="leastcount" onChange={handleChange} value={formData?.leastcount} name="leastcount" />
                        </div>
                        <div className="form-group col-sm-6">
                            <label htmlFor="calibrated_on">Calibrated on</label>
                            <input type="date" className="form-control" id="calibrated_on" onChange={handleChange} value={formData?.calibrated_on} name="calibrated_on" placeholder="Calibrated on" />
                        </div>
                        <div className="form-group col-sm-6">
                            <label htmlFor="valid_upto">Valid up to</label>
                            <input type="date" className="form-control" id="valid_upto" onChange={handleChange} value={formData?.valid_upto} name="valid_upto" placeholder="Valid up to" />
                        </div>
                        <div className="form-group col-sm-6">
                            <label htmlFor="cert_issue_date">Certificate issue Date</label>
                            <input type="date" className="form-control" id="cert_issue_date" onChange={handleChange} value={formData?.cert_issue_date} name="cert_issue_date" placeholder="Certificate Issue Date" />
                        </div>
                        <div className="form-group col-sm-6">
                            <label htmlFor="calibration_engineer_sign">Calibration Engineer Sign</label>
                            <select className="form-control" name="calibration_engineer_sign" id="calibration_engineer_sign" onChange={handleChange} value={formData?.calibration_engineer_sign}>
                                <option value="">--select--</option>
                                {usersOptions.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {formData?.calibration_engineer_sign ? ("") : (
                                <span style={{ "color": "red" }}>*Required value</span>
                            )}
                        </div>
                        <div className="form-group col-sm-6">
                            <label htmlFor="calibration_technical_manager">Technical Manager Sign</label>
                            <select className="form-control" name="calibration_technical_manager" id="calibration_technical_manager" onChange={handleChange} value={formData?.calibration_technical_manager}>
                                <option value="">--select--</option>
                                {usersOptions.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {formData?.calibration_technical_manager ? ("") : (
                                <span style={{ "color": "red" }}>*Required value</span>
                            )}
                        </div>
                        <div className="form-group col-sm-6">
                            <label htmlFor="approved_by">Quality Manager Sign</label>
                            <select className="form-control" name="approved_by" id="approved_by" onChange={handleChange} value={formData?.approved_by}>
                                <option value="">--select--</option>
                                {usersOptions.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {formData?.approved_by ? ("") : (
                                <span style={{ "color": "red" }}>*Required value</span>
                            )}

                        </div>
                        <div className="col-12 mt-3 text-right">
                            {submitting ? (
                                <button type="button" className="btn btn-success" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Generating Certificate...
                                </button>
                            ) : (
                                formData.calibration_engineer_sign && formData.calibration_technical_manager && formData.approved_by ? (
                                    <button type="submit" name="submitBtn" className="btn btn-success">
                                        {certGenerated ? "Edit Certificate" : "Generate Certificate"}
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-success" disabled>Please Fill Required Vlaue</button>
                                )
                            )}
                        </div>

                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Certification;
